import {
	CaretCircleLeft,
	CaretDown,
	CaretUp,
	CreditCard,
	Trash,
} from '@phosphor-icons/react';
import Heading from '../../components/atoms/Heading';
import ROUTES from '../../shared/constants/routes';
import { useNavigate } from 'react-router-dom';
import Text from '../../components/atoms/Text';
import { Card } from '../../@types/Checkout.type';

import { CircularProgress, useMediaQuery } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import {
	createNewCustomer,
	deletePaymentCard,
	getClientCustomer,
	getPaymentCards,
	postDefaultCard,
} from '../../services/payments.service';

import Divider from '../../components/atoms/Divider';
import Button from '../../components/atoms/Button';
import { ALERT_TYPE } from '../../components/molecules/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { showAlert } from '../../redux/slices/alert.slice';
import { ANALYTICS_EVENTS } from '../../shared/constants/events';
import useCleverTapEvent from '../../hooks/useClevertapEvent';
import { RootState } from '../../redux/store';
import CardMock from './components/organisms/CardMock';
import { getCardType } from '../../shared/utils/payments';
import CreateCard from './components/organisms/CreateCard';

const PaymentMethods = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const logClevertapEvent = useCleverTapEvent();

	const isMobile = useMediaQuery('(max-width: 768px)');
	const { tokenSession } = useSelector((state: RootState) => state.auth);

	const [step, setStep] = useState(0);
	const [customerStripeId, setCustomerStripeId] = useState('');
	const [defaultCard, setDefaultCard] = useState<Card[]>([]);
	const [allPaymentsCards, setAllPaymentsCards] = useState<Card[]>([]);
	const [openCards, setOpenCards] = useState<{ [key: string]: boolean }>({});

	const [isLoadingAllCards, setIsLoadingAllCards] = useState(false);
	const [isLoadingDeleteCard, setIsLoadingDeleteCard] = useState(false);
	const [isLoadingDefaultCard, setIsLoadingDefaultCard] = useState(false);

	const getCardList = useCallback(async () => {
		try {
			setIsLoadingAllCards(true);
			const resp: Card[] = await getPaymentCards(tokenSession);
			setDefaultCard(resp.filter((card) => card?.is_default));
			setAllPaymentsCards(resp.filter((card) => !card?.is_default));
		} catch (error) {
		} finally {
			setIsLoadingAllCards(false);
		}
	}, []);

	const deleteCard = useCallback(
		async (tokenId: string) => {
			try {
				setIsLoadingDeleteCard(true);
				const resp = await deletePaymentCard(tokenId, tokenSession);
				if (resp?.status === 200) {
					const alert = {
						type: ALERT_TYPE.SUCCESS,
						title: 'Aviso',
						description: 'Tarjeta eliminada con éxito',
					};
					dispatch(showAlert(alert));
					const deletedCard = allPaymentsCards.find(
						(card) => card.id === tokenId,
					);
					if (deletedCard?.is_default) {
						const lastAddedCard = allPaymentsCards
							.filter((card) => !card.is_default)
							.reduce((prev, current) => {
								return new Date(prev.created_at) > new Date(current.created_at)
									? prev
									: current;
							});
						await post_defaultCard(lastAddedCard?.platform_token);
					} else {
						getCardList();
					}
				}
			} catch (error) {
			} finally {
				setIsLoadingDeleteCard(false);
			}
		},
		[allPaymentsCards],
	);

	const post_defaultCard = useCallback(async (pmId: string) => {
		try {
			setIsLoadingDefaultCard(true);
			const resp = await postDefaultCard(pmId, tokenSession);
			if (resp) {
				const alert = {
					type: ALERT_TYPE.SUCCESS,
					title: 'Aviso',
					description: 'Se cambió la tarjeta predeterminada por defecto',
				};
				dispatch(showAlert(alert));
				getCardList();
			}
		} catch (error) {
		} finally {
			setIsLoadingDefaultCard(false);
		}
	}, []);

	const getCustomer = useCallback(async () => {
		try {
			const response = await getClientCustomer(tokenSession);
			if (response) {
				setCustomerStripeId(response[0].id ?? '');
			}
		} catch (error: any) {
			if (error?.response?.status === 404) {
				const response = await createNewCustomer(tokenSession);
				if (response) {
					setCustomerStripeId(response.id ?? '');
				}
				return;
			}
		}
	}, []);

	const handleSuccessCreation = () => {
		getCardList();
		setStep(0);
	};

	const toggleCardOpen = (cardId: string) => {
		setOpenCards((prev) => ({
			...prev,
			[cardId]: !prev[cardId],
		}));
	};

	useEffect(() => {
		getCardList();
		logClevertapEvent(ANALYTICS_EVENTS.PAGE_VIEWED, {
			name: ROUTES.PAYMENT_METHODS,
		});
	}, []);

	useEffect(() => {
		if (step === 1) {
			getCustomer();
		}
	}, [step]);

	return (
		<div className='p-5 md:p-10'>
			<div className='flex items-center gap-3 mb-6'>
				<CaretCircleLeft
					weight='fill'
					color='var(--h-primary)'
					width={32}
					height={32}
					className='cursor-pointer hidden lg:block'
					onClick={() => navigate(ROUTES.USER_PROFILE)}
				/>
				<CaretCircleLeft
					weight='fill'
					color='var(--h-primary)'
					width={24}
					height={24}
					className='cursor-pointer block lg:hidden'
					onClick={() => navigate(ROUTES.USER_PROFILE)}
				/>
				<Heading
					sx='hidden lg:block'
					size='s'
					weight='bold'
				>
					Mis métodos de pago
				</Heading>
				<Text
					sx='block lg:hidden'
					size='body-2'
					weight='bold'
				>
					Mis métodos de pago
				</Text>
			</div>
			<div className='grid grid-cols-1 gap-x-4 gap-y-8 place-items-center'>
				<div className='flex flex-col p-4 md:p-6 mb-4 rounded-md shadow-sm w-full lg:w-[650px] bg-white'>
					<Text
						size='body-2'
						sx='mb-4'
						color='medium'
						weight='bold'
					>
						Tarjeta predeterminada
					</Text>
					<Divider />

					{isLoadingAllCards ? (
						<div className='flex items-center justify-center mt-4'>
							<CircularProgress size={22} />
						</div>
					) : (
						<>
							{defaultCard.length === 0 ? (
								<Text
									size='m'
									color='gray'
									weight='light'
								>
									No tienes ninguna tarjeta predeterminada
								</Text>
							) : (
								defaultCard.map((card) => (
									<div className='flex flex-col md:flex-row'>
										<Text sx='flex md:hidden mb-3'>
											Tarjeta de {getCardType(card?.details?.card_type)}
										</Text>
										<CardMock
											brand={card?.details?.brand}
											lastFour={card?.details?.last_four}
											expires={card?.details?.expires}
										/>

										<div className='flex flex-col md:ml-6 justify-between mt-4 md:mt-0'>
											<Text sx='hidden md:flex'>
												Tarjeta de {getCardType(card?.details?.card_type)}
											</Text>
											<Button
												beforeIcon={
													<Trash
														size={24}
														weight='light'
													/>
												}
												loading={isLoadingDeleteCard}
												sx='w-full lg:w-[250px] bg-h-primary-90 !text-h-primary-30 hover:bg-h-primary-90 order-2'
												label='Eliminar tarjeta'
												onClick={() => deleteCard(card?.id)}
											/>
										</div>
									</div>
								))
							)}
						</>
					)}
				</div>

				<div className='flex flex-col p-6 mb-4 rounded-md shadow-sm w-full lg:w-[650px] bg-white'>
					<Text
						size='body-2'
						sx='mb-4'
						color='medium'
						weight='bold'
					>
						Otras tarjetas
					</Text>
					<Divider />

					{isLoadingAllCards ? (
						<div className='flex items-center justify-center mt-4'>
							<CircularProgress size={22} />
						</div>
					) : (
						<>
							{allPaymentsCards?.length === 0 ? (
								<Text
								size='m'
								color='gray'
								weight='light'
								sx='mb-4'
								>No tienes tarjetas guardadas</Text>
							) : (
								allPaymentsCards?.map((card) => (
									<div key={card?.id}>
										{openCards[card?.id] ? (
											<div className='bg-white shadow-md rounded-lg mb-8 p-5'>
												<div className='flex flex-col md:flex-row gap-4'>
													{isMobile && (
														<button
															onClick={() => toggleCardOpen(card?.id)}
															className='flex justify-between md:mb-4'
														>
															<Text>
																Tarjeta de{' '}
																{getCardType(card?.details?.card_type)}
															</Text>
															<div className='ml-10 flex items-end justify-end'>
																<CaretUp size={isMobile ? 16 : 22} />
															</div>
														</button>
													)}

													<CardMock
														isDefault={false}
														brand={card?.details?.brand}
														lastFour={card?.details?.last_four}
														expires={card?.details?.expires}
													/>
													<div className='flex flex-col justify-between w-full'>
														{!isMobile && (
															<button
																onClick={() => toggleCardOpen(card?.id)}
																className='hidden md:flex md:items-start md:justify-between'
															>
																<Text>
																	Tarjeta de{' '}
																	{getCardType(card?.details?.card_type)}
																</Text>
																<div className='flex items-end justify-end'>
																	<CaretUp size={isMobile ? 16 : 22} />
																</div>
															</button>
														)}

														<div className='flex flex-col gap-3 mt-4 md:mt-0'>
															<Button
																loading={isLoadingDefaultCard}
																variant='outline'
																label='Usar como predeterminada'
																onClick={() =>
																	post_defaultCard(card?.platform_token)
																}
															/>
															<Button
																beforeIcon={
																	<Trash
																		size={24}
																		weight='light'
																	/>
																}
																loading={isLoadingDeleteCard}
																sx='w-full bg-h-primary-90 !text-h-primary-30 hover:bg-h-primary-90'
																label='Eliminar tarjeta'
																onClick={() => deleteCard(card?.id)}
															/>
														</div>
													</div>
												</div>
											</div>
										) : (
											<div className='bg-white shadow-md rounded-lg mb-8 px-5 pt-5'>
												<div className='flex flex-col-reverse md:flex-row gap-4'>
													<CardMock
														isDefault={false}
														isHalf
														brand={card?.details?.brand}
														lastFour={card?.details?.last_four}
														expires={card?.details?.expires}
													/>
													<button
														onClick={() => toggleCardOpen(card?.id)}
														className='flex items-start w-full justify-between'
													>
														<Text>
															Tarjeta de {getCardType(card?.details?.card_type)}
														</Text>
														<div className='ml-10 flex items-end justify-end'>
															<CaretDown size={isMobile ? 16 : 22} />
														</div>
													</button>
												</div>
											</div>
										)}
									</div>
								))
							)}

							{step === 0 && (
								<div className='flex items-center justify-center w-full'>
									<Button
										beforeIcon={
											<CreditCard
												size={24}
												weight='light'
											/>
										}
										sx='w-full w-full bg-h-primary-90 !text-h-primary-30 hover:bg-h-primary-90 order-2'
										label={
											isMobile
												? 'Agregar tarjeta'
												: 'Agregar tarjeta débito o crédito'
										}
										onClick={() => {
											setStep(1);
										}}
									/>
								</div>
							)}
							{step === 1 && (
								<CreateCard
									customerStripeId={customerStripeId}
									onPaymentMethodSuccess={handleSuccessCreation}
								/>
							)}
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export default PaymentMethods;
