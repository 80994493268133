export const ANALYTICS_EVENTS = {
	HOME_LINK: 'home_event',
	LOGIN: 'login',
	SIGNUP: 'sign_up',
	ONBOARDING: 'onboarding_event',
	ADD_RFC: 'add_rfc',
	ADD_TO_CART: 'add_to_cart',
	PURCHASE: 'purchase',
	PLANS: 'plans_event',
	CHARGED: 'Charged',
	CHECKOUT: 'checkout_event',
	INVOICE_HOME_INVOICES: 'home_invoices',
	INVOICE_FISRT_STEP_DATA: 'fisrt_step_data',
	INVOICE_SECOND_STEP_PRODUCT: 'second_step_product',
	INVOICE_THIRD_STEP_VALIDATION: 'third_step_validation',
	INVOICE_CONFIRMATION_STEP: 'confirmation_step',
	INVOICE_INVOICE_DETAILS: 'Invoice_details',
	TAX_DASHBOARD: 'tax_dashboard_event',
	FAIL_CHARGED: 'fail_charged',
	TAXBOARD_DASHBOARD: 'tax_dashboard_event',
	PAGE_VIEWED: 'Page_Viewed',
	FISCAL_DOCUMENTS: 'fiscal_documents_event',
	RAPPI_CERTIFICATES: 'Rapp_certifications_event',
};

export const EVENT_TYPES = {
	// Home Page
	HOME_MAIN_SCREEN_VIEWED: 'main_screen_viewed',
	HOME_LINK_ATTEMP: 'link_attempt',
	HOME_LINK_INTERACTION: 'link_interaction',
	HOME_TAX_PROFILE_SHARED: 'tax_profile_shared',
	HOME_TAX_DOCUMENTS_SHARED: 'tax_documents_shared',
	HOME_TAX_DOCUMENTS_DOWNLOADED: 'tax_documents_downloaded',
	HOME_TAX_STATUS_INTERACTION: 'tax_status_interaction',
	HOME_PURCHASE_BANNER_INTERACTION: 'purchase_banner_interaction',
	HOME_PENDING_PURCHASE_INTERACTION: 'pending_purchase_interaction',
	HOME_FISCAL_OBLIGATIONS_FILTER: 'fiscal_obligations_filter',
	HOME_VIEW_ALL_SERVICE_STATUS: 'view_all_service_status',
	HOME_SERVICE_STATUS_CTA: 'service_status_CTA',
	// Login Page
	LOGIN: 'login',
	SIGNUP: 'sign_up',
	// Onboarding
	ONBOARDING_FIRST_STEP: 'first_step',
	ONBOARDING_WELCOME: 'welcome_step',
	ONBOARDING_RECOMMENDATION_STEP: 'recommendation_step',
	ONBOARDING_ADD_PRODUCT: 'add_product',
	ONBOARDING_SKIP_ADD_PRODUCT: 'skip_add_product',
	ONBOARDING_EXIT_ADD_PRODUCT: 'exit_add_product',
	ONBOARDING_RESUME_ADD_PRODUCT: 'resume_add_product',
	ONBOARDING_LINK_CREDENTIALS: 'link_credentials',
	ONBOARDING_SKIP_LINK_CREDENTIALS: 'skip_link_credentials',
	ONBOARDING_EXIT_LINK_CREDENTIALS: 'exit_link_credentials',
	ONBOARDING_RESUME_LINK_CREDENTIALS: 'resume_link_credentials',
	ONBOARDING_HELP_LINK_CREDENTIALS: 'help_link_credentials',
	ONBOARDING_HELP_LINK_CREDENTIALS_ASAT: 'help_link_credentials_asat',
	ONBOARDING_HELP_LINK_CREDENTIALS_CREATE_PASSWORD:
		'help_link_credentials_create_password',
	ONBOARDING_HELP_LINK_CREDENTIALS_RECOVER_PASSWORD:
		'help_link_credentials_recover_password',
	// Plans
	PLANS_TAB: 'plans_tab',
	PLANS_SELECT: 'plans_select',
	PLANS_ADD_TO_CART: 'add_to_cart',
	PLANS_CHARGED: 'charged',
	CHECKOUT_COUPON_VALIDATE: 'coupon_validate',
	CHECKOUT_ADD_PAYMENT_CARD: 'add_payment_card',
	CHECKOUT_SELECT_PAYMENT_METHOD: 'select_paymenth_method',
	CHECKOUT_ADD_EXTRA_PRODUCT: 'add_extra_product',
	// Invoicing
	INVOICE_GENERATE_NEW_INVOICE: 'generate_new_invoice',
	INVOICE_VIEW_DETAIL: 'view_detail',
	INVOICE_ASAT_PRODUCT_SHOPPING_CAR: 'asat_product_shopping_car',
	INVOICE_SAVE_STAMP: 'save_stamp',
	INVOICE_GO_BACK_STAMP: 'go_back_stamp',
	INVOICE_LINK_STAMP: 'link_stamp',
	INVOICE_USERS_INFORMATION: 'users_information',
	INVOICE_PRODUCT_INFORMATION: 'product_information',
	INVOICE_ADD_PRODUCT: 'add_product',
	INVOICE_DELETE_PRODUCT: 'delete_product',
	INVOICE_CONFIRM_DELETE_PRODUCT: 'confirm_delete_product',
	INVOICE_BACK_DELETE_PRODUCT: 'back_delete_product',
	INVOICE_EDIT_PRODUCT: 'edit_product',
	INVOICE_EDIT_RECEIVER: 'edit_receiver',
	INVOICE_PREVIEW: 'invoice_preview',
	INVOICE_REVIEW_COMPLETED: 'review_completed',
	INVOICE_ADD_EMAIL: 'add_email',
	INVOICE_SEND_CONFIRMATION: 'send_confirmation',
	INVOICE_DOWNLOAD_PDF: 'download_pdf',
	INVOICE_DOWNLOAD_XML: 'download_xml',
	INVOICE_RESEND: 'resend',
	INVOICE_CANCEL: 'cancel',
	INVOICE_CONFIRM_CANCELLATION: 'confirm_cancellation',
	// Tax Dashboard
	TAXBOARD_VIEW_DASHBOARD: 'view_dashboard',
	TAXBOARD_SELECT_DASHBOARD: 'select_dashboard',
	TAXBOARD_MAIN_CTA_SELECTED: 'main_cta_selected',
	TAXBOARD_GROUP_TAX_SELECTED: 'group_tax_selected',
	TAXBOARD_TAX_CTA_SELECTED: 'tax_cta_selected',
	TAXBOARD_DETAIL_SELECTED: 'tax_detail_selected',
	TAXBOARD_SELECT_PERIODICITY: 'select_periodicity',
	TAXBOARD_SELECT_FILTER: 'select_filter',
	TAXBOARD_TAX_DETAIL_SELECTED: 'tax_detail_selected',
	TAXBOARD_TAX_RECEIPT_DOWNLOADED: 'tax_detail_receipt_downloaded',
	TAXBOARD_PERIOD_DETAIL_VIEWED: 'period_detail_viewed',
	TAXBOARD_ACQUIRE_PERIOD: 'acquire_period',
	TAXBOARD_VIEW_PERIOD_FISCAL_DOCUMENTS: 'view_period_fiscal_documents',
	TAXBOARD_DOCUMENT_DETAIL_OPENED: 'document_detail_opened',
	TAXBOARD_DOCUMENT_DOWNLOADED: 'document_downloaded',
	TAXBOARD_BANNER_SELECTED: 'banner_selected',
	// Fiscal Documents
	FISCAL_DOCUMENTS_VIEW_FISCAL_DOCUMENTS: 'view_fiscal_documents',
	FISCAL_DOCUMENTS_SELECT_FILTER: 'select_filter',
	FISCAL_DOCUMENTS_TAB: 'fiscal_document_tab',
	FISCAL_DOCUMENTS_DETAIL: 'detail',
	FISCAL_DOCUMENTS_DETAIL_ACTION: 'detail_action',
	// Rappi Certificates
	RAPPI_CERTIFICATES_VIEW: 'view_rappi_certifications',
	RAPPI_CERTIFICATES_DOWNLOAD_PDF: 'download_pdf',
	RAPPI_CERTIFICATES_DOWNLOAD_XML: 'download_xml',
};
