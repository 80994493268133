import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { CheckCircle } from '@phosphor-icons/react';
import { CaretLeft } from '@phosphor-icons/react/dist/ssr';

//? Types
import { SubscriptionType } from '../../../../@types/Subscription';

//? Components
import Input from '../../../../components/atoms/Input';
import Text from '../../../../components/atoms/Text';
import Button from '../../../../components/atoms/Button';
import Modal from '../../../../components/molecules/Modal/Modal';
import Heading from '../../../../components/atoms/Heading';
import { ALERT_TYPE } from '../../../../components/molecules/Alert';

//? Utils
import LITERALS from '../../../../shared/constants/literals';

//? Services
import { cancelSubscription } from '../../../../services/suscription.service';
import { sendUserFeedback } from '../../../../services/user-interaction.service';

//? Redux
import { RootState } from '../../../../redux/store';
import { hideAlert, showAlert } from '../../../../redux/slices/alert.slice';
import { getFormatDate } from '../../../../shared/utils/dates.util';
interface Props {
	showModal: {
		churn: boolean;
		history: boolean;
	};
	setShowModal: Dispatch<SetStateAction<{ churn: boolean; history: boolean }>>;
	activeSubscription: SubscriptionType;
	fetchUserSubscriptions: () => void;
}

type FormBody = {
	userMessage: string;
};

const ModalChurn: React.FC<Props> = ({
	showModal,
	setShowModal,
	activeSubscription,
	fetchUserSubscriptions,
}) => {
	const [isLoading, setIsLoading] = useState({
		sendFeedback: false,
		cancelSubscription: false,
	});
	const { control, handleSubmit, reset, watch } = useForm<FormBody>();
	const [step, setStep] = useState(1);
	const [errors, setErrors] = useState({
		userMessage: false,
	});
	const userMessage = watch('userMessage');

	const alert = useSelector((state: RootState) => state.alert);
	const { tokenSession } = useSelector((state: RootState) => state.auth);

	const dispatch = useDispatch();
	const userProfile = useSelector((state: RootState) => state.profile.user);

	const getDate = () => {
		return getFormatDate(
			activeSubscription?.current_period_end || '',
			'DD MMM YYYY',
			false,
			true,
		);
	};

	const handleNextStep = () => {
		if (step === 1) {
			setStep(step + 1);
		}
		if (step === 2) {
			onSubmit({ userMessage: userMessage });
			return;
		}
	};

	const onSubmit = async (data: { userMessage: string }) => {
		if (!data.userMessage) {
			setErrors((prev) => ({ ...prev, userMessage: true }));
			return;
		}

		try {
			setIsLoading((prev) => ({ ...prev, sendFeedback: true }));
			await sendUserFeedback(userProfile?.id!, data.userMessage, tokenSession);
			handleCancelSubscription();
		} catch (error) {
		} finally {
			setIsLoading((prev) => ({ ...prev, sendFeedback: false }));
		}
	};

	const handleCancelSubscription = async () => {
		try {
			setIsLoading((prev) => ({ ...prev, cancelSubscription: true }));
			const resp = await cancelSubscription(
				activeSubscription?.id,
				tokenSession,
			);
			if (resp?.data.resource.active) {
				fetchUserSubscriptions();
				setStep(3);
				reset();
			}
		} catch (error) {
			const alert = {
				type: ALERT_TYPE.ERROR,
				title: 'Ocurrio un error',
				description: 'Intente de nuevo más tarde.',
			};
			dispatch(showAlert(alert));
		} finally {
			setIsLoading((prev) => ({ ...prev, cancelSubscription: false }));
		}
	};

	const handleCloseModal = () => {
		setShowModal({ ...showModal, churn: false });
		setStep(1);
	};

	useEffect(() => {
		if (alert.showAlert) {
			setTimeout(() => {
				dispatch(hideAlert());
			}, alert.duration);
		}
	}, [alert]);

	return (
		<Modal
			open={showModal.churn}
			onClose={handleCloseModal}
			sx='w-[94%] !max-w-[600px]'
		>
			{step === 2 && (
				<div
					className='w-5 h-5 bg-h-primary rounded-full flex justify-center items-center absolute left-2 top-2 cursor-pointer'
					onClick={() => setStep(step - 1)}
				>
					<CaretLeft
						size={14}
						className='min-w-[14px] text-white'
						weight='bold'
					/>
				</div>
			)}
			<div className='mt-4'>
				<Heading
					size='xs'
					weight='bold'
					sx='text-center '
					responsive
				>
					{step === 1 && (
						<span>
							No te vayas <br /> Estos son los beneficios de tu suscripción
						</span>
					)}
					{step === 2 && (
						<span>
							Queremos conocer tu opinión <br /> ¡Ayúdanos a mejorar!
						</span>
					)}
					{step === 3 && <span>Cancelación exitosa</span>}
				</Heading>
				{step === 1 && (
					<>
						<div className='flex flex-col gap-2 mt-6'>
							{activeSubscription?.product_version?.product?.benefits
								?.slice(0, 3)
								.map((benefit, index) => (
									<div
										key={index}
										className='flex gap-1 lg:gap-2 items-center'
									>
										<CheckCircle
											weight='light'
											size={20}
											className='text-h-primary min-w-[20px]'
										/>
										<Text size='s'>{benefit}</Text>
									</div>
								))}
						</div>
						<Text sx='mt-6 !block'>
							Tu suscripción se cancelará a partir del <b>{getDate()}</b>
						</Text>
					</>
				)}
				{step === 3 && (
					<Text sx='mt-6 !block text-center mb-4'>
						A partir del <b>{getDate()}</b>, la suscripción para
						<b> {activeSubscription?.additional_information?.regimes}</b>{' '}
						quedará cancelada; no olvides que puedes reactivarla cuando lo
						desees.
					</Text>
				)}
				{step === 2 && (
					<form
						className='mt-8'
						onSubmit={handleSubmit(onSubmit)}
					>
						<Input
							autofocus
							minRows={4}
							multiline
							rules={{ required: LITERALS.FORM_REQUIRED_ERROR }}
							control={control}
							name='userMessage'
							type='text'
							placeholder='Escribe aquí tu opinión'
							errorMessage={
								errors.userMessage && userMessage?.length < 1
									? 'Este campo es requerido'
									: ''
							}
						/>
					</form>
				)}
				{step < 3 && (
					<div className='flex gap-2 lg:gap-6 mt-8 flex-col lg:flex-row'>
						<Button
							label='volver'
							onClick={handleCloseModal}
						/>
						<Button
							disabled={step === 2 && !userMessage}
							loading={isLoading.sendFeedback || isLoading.cancelSubscription}
							variant='outline'
							label={
								step === 1 ? 'Cancelar suscripción' : 'Confirmar cancelación'
							}
							onClick={handleNextStep}
						/>
					</div>
				)}
			</div>
		</Modal>
	);
};

export default ModalChurn;
