import React, { useEffect, useMemo, useRef, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import {
	Handshake,
	SignOut,
	SquaresFour,
	ChartPieSlice,
	Receipt,
	HouseLine,
	ChartLineUp,
	Files,
	ReadCvLogo,
} from '@phosphor-icons/react';
import Text from '../../atoms/Text';
import ROUTES from '../../../shared/constants/routes';
import {
	getFirstLetter,
	isLocalStorageEnabled,
	isSessionStorageEnabled,
} from '../../../shared/utils/general.util';
import { RootState } from '../../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { restartAppState } from '../../../redux/slices/sat.slice';
import { restartTaxPayerData } from '../../../redux/slices/taxProfile.slice';
import { logout, removeToken } from '../../../redux/slices/auth.slice';
import LOCAL_STORAGE_KEY from '../../../shared/constants/localStorageKey';
import { PLANS_UTM_VALUES } from '../../../shared/constants/utms';
import { setOverwriteUtm, updateUTMs } from '../../../redux/slices/cart.slice';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import GROWTHBOOK_FEATURE_FLAGS from '../../../shared/utils/growthbook.util';
import RappiLogo from '../../../assets/img/svg/rappi-logo.svg';
import useCleverTapEvent from '../../../hooks/useClevertapEvent';
import {
	ANALYTICS_EVENTS,
	EVENT_TYPES,
} from '../../../shared/constants/events';
import { resetPlans } from '../../../redux/slices/plans.slice';

interface SidebarItemProps {
	icon: JSX.Element;
	text: string;
	active?: boolean;
	onClick: () => void;
}

const SidebarItem = ({
	icon,
	text,
	active: initialActive,
	onClick,
}: SidebarItemProps) => {
	const [active, setActive] = useState(initialActive);

	useEffect(() => {
		setActive(initialActive);
	}, [initialActive]);

	const handleClick = () => {
		setActive(!active);
		onClick();
	};

	return (
		<li
			onClick={handleClick}
			className={`
				relative flex flex-col items-center justify-center // Add these properties
				py-2 sm:px-4 px-2 w-full text-center
				font-medium cursor-pointer
				transition-colors group
				${initialActive ? 'bg-h-primary text-white' : 'hover:bg-h-blue-20 text-h-dark'}
				md:w-1/2 lg:w-1/3 xl:w-1/4
				h-[60px]
			`}
		>
			{icon}
			{text && (
				<span className={`transition-all mt-1`}>
					<Text
						size='xxs'
						color={initialActive ? 'white' : 'dark'}
						weight='medium'
					>
						{text}
					</Text>
				</span>
			)}
		</li>
	);
};

const MobileMenu: React.FC = () => {
	const dispatch = useDispatch();
	const logClevertapEvent = useCleverTapEvent();

	const [activeItem, setActiveItem] = useState(0);
	const userProfile = useSelector((state: RootState) => state.profile.user);
	const location = useLocation();

	const { isFromApp } = useSelector((state: RootState) => state.profile);

	const navigate = useNavigate();

	const showInvoices = useFeatureIsOn(
		GROWTHBOOK_FEATURE_FLAGS.SHOW_FISCAL_DOCUMENTS,
	);
	const showCalendar = useFeatureIsOn(
		GROWTHBOOK_FEATURE_FLAGS.SHOW_TAXBOARD_CALENDAR,
	);

	const rappiCertificates = useSelector(
		(state: RootState) => state.rappi.certificates,
	);

	const routes = [
		{
			label: 'Planes',
			icon: <Files size={24} />,
			path: ROUTES.PLANS_AND_PRICES,
			onClick: () => {
				const { utm_campaign, utm_medium, utm_source } =
					PLANS_UTM_VALUES.HOME_TAB;
				if (isSessionStorageEnabled()) {
					const overwriteUtmKey = LOCAL_STORAGE_KEY.OVERWRITE_UTM;
					const campaignKey = LOCAL_STORAGE_KEY.CAMPAIGN;
					const mediumKey = LOCAL_STORAGE_KEY.MEDIUM;
					const sourceKey = LOCAL_STORAGE_KEY.SOURCE;

					let overwriteUtm = sessionStorage.getItem(overwriteUtmKey);

					if (overwriteUtm === null) {
						sessionStorage.setItem(overwriteUtmKey, 'true');
						overwriteUtm = 'true';
					}

					if (overwriteUtm === 'true') {
						sessionStorage.setItem(campaignKey, utm_campaign);
						sessionStorage.setItem(mediumKey, utm_medium);
						sessionStorage.setItem(sourceKey, utm_source);
					} else {
						if (!sessionStorage.getItem(campaignKey)) {
							sessionStorage.setItem(campaignKey, utm_campaign);
						}
						if (!sessionStorage.getItem(mediumKey)) {
							sessionStorage.setItem(mediumKey, utm_medium);
						}
						if (!sessionStorage.getItem(sourceKey)) {
							sessionStorage.setItem(sourceKey, utm_source);
						}
					}
				} else {
					const currentOverwriteUtm = useSelector(
						(state: RootState) => state.cart.overwriteUtm,
					);

					if (
						currentOverwriteUtm === undefined ||
						currentOverwriteUtm === null
					) {
						dispatch(setOverwriteUtm(true));
					}

					if (currentOverwriteUtm === true) {
						dispatch(updateUTMs(PLANS_UTM_VALUES.HOME_TAB));
					} else {
						const currentUTMs = useSelector(
							(state: RootState) => state.cart.utmInfo,
						);
						const updatedUTMs = {
							...currentUTMs,
							utm_campaign: currentUTMs?.utm_campaign || utm_campaign,
							utm_medium: currentUTMs?.utm_medium || utm_medium,
							utm_source: currentUTMs?.utm_source || utm_source,
						};
						dispatch(updateUTMs(updatedUTMs));
					}
				}
				navigate(ROUTES.PLANS_AND_PRICES);
			},
		},
		{
			label: 'Facturación',
			icon: <Receipt size={24} />,
			path: ROUTES.INVOICING,
			onClick: () => navigate(ROUTES.INVOICING),
		},
		{
			label: 'Inicio',
			icon: <HouseLine size={24} />,
			path: ROUTES.ROOT,
			onClick: () => navigate(ROUTES.ROOT),
		},
		{
			label: 'Impuestos',
			icon: <ChartLineUp size={24} />,
			path: ROUTES.TAXES,
			onClick: () => navigate(ROUTES.TAXES),
		},
		{
			label: 'Más',
			icon: <SquaresFour size={24} />,
			path: ROUTES.USER_PROFILE,
			onClick: () => handleMoreOptions(),
		},
	].filter((route) => {
		if (!showCalendar && route.label === 'Impuestos') {
			return false;
		}
		return true;
	});

	if (!showCalendar) {
		const statusOfService = {
			label: 'Estado',
			icon: <ChartPieSlice size={24} />,
			path: ROUTES.SERVICE_STATUS,
			onClick: () => navigate(ROUTES.SERVICE_STATUS),
		};

		routes.splice(-1, 0, statusOfService);
	}

	const activeRouteIndex = useMemo(() => {
		return routes.findIndex((route) => route.path === location.pathname);
	}, [routes, location.pathname]);

	useEffect(() => {
		setActiveItem(activeRouteIndex);
	}, [activeRouteIndex]);

	const handleItemClick = (index: number) => {
		setActiveItem(index === activeItem ? -1 : index);
		setShowListMenu(index === activeItem ? true : false);
	};

	const renderIcon = (icon: JSX.Element, slug: string) => {
		const isActive =
			(location.pathname.includes(slug) && slug !== '/') ||
			(slug === '/' && location.pathname === '/');

		return React.cloneElement(icon, {
			weight: isActive ? 'fill' : 'regular',
		});
	};

	const [showListMenu, setShowListMenu] = useState(false);

	const handleMoreOptions = () => {
		setShowListMenu((prev) => !prev);
	};

	const onLogout = () => {
		if (isLocalStorageEnabled()) {
			localStorage.removeItem(LOCAL_STORAGE_KEY.TOKEN);
		}
		dispatch(removeToken());
		dispatch(restartAppState());
		dispatch(restartTaxPayerData());
		dispatch(logout());
		navigate(ROUTES.LOGIN);
	};

	const listMenu = [
		{
			label: 'Salir',
			icon: (
				<SignOut
					size={20}
					weight='thin'
				/>
			),
			onClick: onLogout,
			isVisible: !isFromApp,
		},
		{
			label: 'Referidos',
			icon: (
				<Handshake
					size={20}
					weight='thin'
				/>
			),
			onClick: () => navigate(ROUTES.REFERRALS),
			path: ROUTES.REFERRALS,
			isVisible: true,
		},

		{
			label: 'Estado del servicio',
			icon: (
				<ChartPieSlice
					size={21}
					weight='thin'
				/>
			),
			onClick: () => navigate(ROUTES.SERVICE_STATUS),
			path: ROUTES.SERVICE_STATUS,
			isVisible: true,
		},
		{
			label: 'Documentos fiscales',
			icon: (
				<ReadCvLogo
					size={21}
					weight='thin'
				/>
			),
			onClick: () => {
				logClevertapEvent(ANALYTICS_EVENTS.FISCAL_DOCUMENTS, {
					event_type: EVENT_TYPES.FISCAL_DOCUMENTS_VIEW_FISCAL_DOCUMENTS,
				});
				navigate(ROUTES.FISCAL_DOCUMENTS);
			},
			path: ROUTES.FISCAL_DOCUMENTS,
			isVisible: true,
		},
		{
			label: `${userProfile?.first_name}`,
			icon: (
				<div className='w-[21px] h-[21px] rounded-full bg-white  border border-h-primary-10 flex items-center justify-center text-white'>
					<Text
						size='xs'
						color='dark'
						weight='medium'
					>
						{getFirstLetter(userProfile?.first_name || '')}
					</Text>
				</div>
			),
			onClick: () => navigate(ROUTES.USER_PROFILE),
			path: ROUTES.USER_PROFILE,
			isVisible: true,
		},
	].filter((item) => {
		if (item.label === 'Estado del servicio' && !showCalendar) {
			return false;
		}
		if (item.label === 'Documentos fiscales' && !showInvoices) {
			return false;
		}
		return true;
	});

	if (rappiCertificates?.length) {
		const rappiCertificatesItem = {
			label: 'Certificados',
			icon: (
				<img
					src={RappiLogo}
					alt='Rappi'
					className='w-10 h-6'
				/>
			),
			path: ROUTES.RAPPI_CERTIFICATES,
			onClick: () => {
				logClevertapEvent(ANALYTICS_EVENTS.RAPPI_CERTIFICATES, {
					event_type: EVENT_TYPES.RAPPI_CERTIFICATES_VIEW,
				});
				navigate(ROUTES.RAPPI_CERTIFICATES);
			},
			isVisible: true,
		};

		listMenu.splice(-1, 0, rappiCertificatesItem);
	}

	const menuRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (
				menuRef.current &&
				event.target instanceof Node &&
				!menuRef.current.contains(event.target)
			) {
				setShowListMenu(false);
			}
		};
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	return (
		<>
			<div
				onClick={() => dispatch(resetPlans())}
				ref={menuRef}
				className='fixed -bottom-1 md:hidden w-full z-40'
			>
				<ul className='grid grid-cols-5 border-t bg-white w-full z-50'>
					{routes.map((route, index) => {
						const isActive =
							(location.pathname.includes(route.path) && route.path !== '/') ||
							(route.label === 'Inicio' && location.pathname === '/');

						return (
							<div key={index}>
								<SidebarItem
									icon={renderIcon(route.icon, route.path)}
									text={index !== routes.length - 1 ? route.label : ''}
									active={isActive}
									onClick={() => {
										handleItemClick(index);
										route?.onClick();
									}}
								/>
							</div>
						);
					})}
				</ul>
			</div>
			{showListMenu && (
				<div
					ref={menuRef}
					className='bg-white shadow fixed bottom-[62px] right-0 rounded-tl-xl lg:hidden z-50'
				>
					<ul className='flex flex-col w-full h-auto items-center'>
						{listMenu
							.filter((item) => item.isVisible)
							.map((item) => (
								<li
									onClick={() => {
										item.onClick();
										setShowListMenu(false);
									}}
									key={item.label}
									className={`md:hidden flex justify-end items-center py-4 px-6 hover:bg-h-blue-20 w-full h-full gap-3 transition duration-200 cursor-pointer border-b ${
										item.path === location.pathname ? 'bg-h-blue-20' : ''
									}`}
								>
									<Text
										color='dark'
										size='caption'
										weight='regular'
									>
										{item.label}
									</Text>
									<div>{item.icon}</div>
								</li>
							))}
					</ul>
				</div>
			)}
		</>
	);
};

export default React.memo(MobileMenu);
