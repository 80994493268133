import { ArrowRight, Warning } from '@phosphor-icons/react';
import Table, { TRowData } from '../../../components/molecules/Table';
import FiscalResult from '../atoms/FiscalResult';
import Button from '../../../components/atoms/Button';
import { useMediaQuery } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { AnnualDeclarationsColumns } from '../../../shared/constants/tables';
import Text from '../../../components/atoms/Text';
import { useDispatch, useSelector } from 'react-redux';
import { setShowModal } from '../../../redux/slices/taxCalendar.slice';
import { RootState } from '../../../redux/store';
import useAddToCart from '../../../hooks/useAddToCart';
import { REGULARIZATION_REGARATION_PRODUCTS_SLUG } from '../../../shared/constants/SlugsProducts';
import { DATES } from '../../../shared/utils/dates';
import { REGIMES_SHORT_NAME } from '../../../shared/constants/regimes';
import { getFormatDate, getMonthName } from '../../../shared/utils/dates.util';
import { fetchMonthlyDeclarationOverview } from '../../../services/taxes.service';
import { ALERT_TYPE } from '../../../components/molecules/Alert';
import ERROR from '../../../shared/constants/errors';
import { showAlert } from '../../../redux/slices/alert.slice';
import { PeriodMonth, TaxboardDeclarationStatus } from '../../../@types/Calendar.type';
import CALENDAR from '../../../shared/constants/calendar';
import useCleverTapEvent from '../../../hooks/useClevertapEvent';
import { ANALYTICS_EVENTS, EVENT_TYPES } from '../../../shared/constants/events';
import { UserRegime } from '../../../@types/TaxProfile';

type Props = {
	handleViewDetail: (declaration_id: number) => void;
};

const AnnualDetail: React.FC<Props> = ({ handleViewDetail }) => {
	const logClevertapEvent = useCleverTapEvent();
	const { userRegimes } = useSelector((state: RootState) => state.taxProfile);

	const [pendingMonthlyDeclarations, setPendingMonthlyDeclarations] = useState<
		PeriodMonth[]
	>([]);
	const { handleAddProductBySlug } = useAddToCart();

	const dispatch = useDispatch();
	const { showModal, selectedPeriod, annualYearSelected } = useSelector(
		(state: RootState) => state.taxCalendar,
	);
	const { activeAnnualSubscription } = useSelector(
		(state: RootState) => state.profile,
	);

	const userProfile = useSelector((state: RootState) => state.profile.user);

	const getMonthlyDeclarationOverview = useCallback(async () => {
		try {
			const response = await fetchMonthlyDeclarationOverview(
				userProfile?.id!,
				annualYearSelected,
			);
			const pendingDeclarations = response.filter(
				(declaration: PeriodMonth) =>
					declaration.status === TaxboardDeclarationStatus.PENDING,
			);
			setPendingMonthlyDeclarations(pendingDeclarations);
		} catch (error) {
			const alert = {
				type: ALERT_TYPE.ERROR,
				title: ERROR.failed_to_fetch_data,
				description: ERROR.try_again_later,
				duration: 2000,
			};
			dispatch(showAlert(alert));
		} finally {
		}
	}, [annualYearSelected]);

	const handleAddProduct = () => {
		logClevertapEvent(ANALYTICS_EVENTS.TAX_DASHBOARD, {
			event_type: EVENT_TYPES.TAXBOARD_ACQUIRE_PERIOD,
			source: 'declaraciones_anuales',
			regime: userRegimes?.map((regime: UserRegime) => regime?.name).join(' - ') || '[]',
		});

		const slugsPerRegime: Record<REGIMES_SHORT_NAME, string> = {
			platec: REGULARIZATION_REGARATION_PRODUCTS_SLUG.REG_PT_TODAS_ACTIVIDADES,
			business: REGULARIZATION_REGARATION_PRODUCTS_SLUG.REG_ACT_EMP,
			resico: REGULARIZATION_REGARATION_PRODUCTS_SLUG.REG_RESICO_PLUS,
			lease: REGULARIZATION_REGARATION_PRODUCTS_SLUG.REG_ARR_MES,
			rif: REGULARIZATION_REGARATION_PRODUCTS_SLUG.REG_RIF,
		};

		handleAddProductBySlug(slugsPerRegime[REGIMES_SHORT_NAME.PLATEC], {
			year: 2023,
			month: 1,
		});
	};

	const RowData: TRowData[] = [selectedPeriod?.year?.selectedYear].map(
		(item) => ({
			rowContent: {
				presentation_date: {
					content: `${getFormatDate(
						item?.presentationDate,
						'DD MMM YYYY',
						false,
						true,
					)}`,
				},
				fiscal_result: {
					content: (
						<FiscalResult
							status={item?.fiscalResult > 0 ? 'negative' : 'neutral'}
						/>
					),
				},
				payment_date: {
					content: '00 Mes 2000',
				},
				delay_days: {
					content: `${Math.round(item?.days_late)} días`,
					textStyle: '!text-h-red-50',
				},
				actions: {
					content: (
						<div className='flex flex-row justify-end'>
							<Button
								onClick={() => handleViewDetail(1)}
								label='Ver resumen'
								variant='text'
								labelColor='primary'
								labelSize='caption'
								sx='whitespace-nowrap'
								icon={
									<ArrowRight
										size={16}
										color='var(--h-primary-50)'
									/>
								}
							/>
						</div>
					),
				},
			},
		}),
	);
	const RowDataMonthlyPending: TRowData[] = pendingMonthlyDeclarations?.map(
		(declaration) => ({
			rowContent: {
				period: {
					content: getMonthName(declaration.month, true),
				},
				regime: {
					content: 'Plataformas Tecnológicas',
				},
				delay_days: {
					content: `${CALENDAR.calculateDelayDays(annualYearSelected, declaration?.month)} días`,
					textStyle: '!text-h-red-50',
				},
				actions: {
					content: (
						<div className='flex flex-row justify-end'>
							<Button
								onClick={() => handleAddProduct()}
								label='Poner al día'
								variant='text'
								labelColor='primary'
								labelSize='caption'
								sx='whitespace-nowrap'
								icon={
									<ArrowRight
										size={16}
										color='var(--h-primary-50)'
									/>
								}
							/>
						</div>
					),
				},
			},
		}),
	);

	const isMobile = useMediaQuery('(max-width: 640px)');

	const showModalPending = () => {
		dispatch(
			setShowModal({
				modalName: 'annualPendingMonths',
				show: !showModal.annualPendingMonths,
			}),
		);
	};

	//todo: Add validation for the year in detail instead of use -> 2023
	const showPending =
		activeAnnualSubscription && annualYearSelected === DATES.currentYear - 1;

	const getAnnualColumnType = () => {
		switch (selectedPeriod?.year?.selectedYear?.status) {
			case TaxboardDeclarationStatus.PRESENTED:
				return AnnualDeclarationsColumns.PRESENTED;
			case TaxboardDeclarationStatus.PENDING:
				return AnnualDeclarationsColumns.PENDING;
			default:
				return AnnualDeclarationsColumns.PENDING;
		}
	};

	useEffect(() => {
		getMonthlyDeclarationOverview();
	}, []);
	
	return (
		<div>
			<Table
				columns={getAnnualColumnType()}
				rowsData={RowData}
				responsive={isMobile}
				sx='mt-2'
			/>
			{showPending && (
				<>
					{isMobile ? (
						<div className='bg-h-neutral-90/20 border border-h-neutral-90 p-3 rounded-lg mt-6'>
							<div className='flex items-center gap-2'>
								<Warning
									weight='fill'
									color='var(--h-red-50)'
									width={18}
									height={18}
								/>
								<Text
									size='mini-1'
									weight='medium'
								>
									Hay declaraciones mensuales pendientes
								</Text>
							</div>
							<Text
								size='mini-1'
								weight='light'
								sx='mt-2 mb-4'
							>
								Pon al día todas las declaraciones mensuales y podrás presentar
								tu declaración anual
							</Text>
							<Button
								labelSize='caption'
								sx={'underline'}
								onClick={showModalPending}
								variant='text'
								label='Ver declaraciones mensuales pendientes'
								labelColor='primary'
							/>
						</div>
					) : (
						pendingMonthlyDeclarations?.length > 0 && (
							<div className='bg-h-neutral-90/20 border border-h-neutral-90 p-3 rounded-lg mt-6'>
								<div className='flex items-center gap-2'>
									<Warning
										weight='fill'
										color='var(--h-red-50)'
										width={18}
										height={18}
									/>
									<Text
										size='caption'
										weight='medium'
									>
										Tienes 4 declaraciones mensuales pendientes para este
										periodo.
									</Text>
								</div>
								<Text
									size='caption'
									weight='light'
									sx='mt-2 mb-4'
								>
									Para presentar tu declaración anual, todas las declaraciones
									mensuales del período deben estar al día.
								</Text>

								<Text
									color='light'
									size='caption'
									weight='bold'
									sx='mb-2'
								>
									Declaciones no presentadas
								</Text>

								<div className='max-h-[calc(100dvh-590px)] overflow-y-auto'>
									<Table
										columns={AnnualDeclarationsColumns.PENDING_MONTHLY}
										rowsData={RowDataMonthlyPending}
										sx='mt-2'
										responsive={isMobile}
									/>
								</div>
							</div>
						)
					)}
				</>
			)}
		</div>
	);
};

export default AnnualDetail;
