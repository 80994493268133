import BenefitsDropdown from '../molecules/BenefitsDropdown';
import PlanFlowButtons from '../molecules/PlanFlowButtons';
import { PropsPlanFlow } from '..';
import {
	PlanFlowContentWrapper,
	TopBanner,
} from '../organisms/LayoutCustomPlan';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { useFeatureValue } from '@growthbook/growthbook-react';
import { getElasticyPrice } from '../../../shared/utils/products.utils';
import GROWTHBOOK_FEATURE_FLAGS from '../../../shared/utils/growthbook.util';
import { APP_CONFIGURATIONS } from '../../../shared/constants/configurations';
import useAddToCart from '../../../hooks/useAddToCart';
import Text from '../../../components/atoms/Text';
import PlanCheckInput from '../molecules/PlanCheckInput';
import { DATES, getLastYears } from '../../../shared/utils/dates';
import { PRODUCTS_SLUG } from '../../../shared/constants/SlugsProducts';
import { setPlanConfig } from '../../../redux/slices/plans.slice';
import useCleverTapEvent from '../../../hooks/useClevertapEvent';
import {
	ANALYTICS_EVENTS,
	EVENT_TYPES,
} from '../../../shared/constants/events';
import { Item } from '../../../@types/Ecommerce';

const AnnualRegularization: React.FC<PropsPlanFlow> = ({
	product,
	planConfig,
	startingPrice,
}) => {
	const dispatch = useDispatch();
	const logClevertapEvent = useCleverTapEvent();
	const { dataLayer } = window as any;
	const taxPayer = useSelector((state: RootState) => state.taxProfile.taxPayer);
	const userProfile = useSelector((state: RootState) => state.profile.user);

	const { handleAddCustomProduct, isLoading: isLoadingAddToCart } =
		useAddToCart();

	const { productName, subtitle } = product;
	const { products } = useSelector((state: RootState) => state.productList);

	const numYears = 4;

	const getPriceWithElasticity = (price: number) =>
		APP_CONFIGURATIONS.SHOW_PRICE_ELASTICY
			? getElasticyPrice(price, elasticityPrice.group_experiment)
			: price;

	const elasticityPrice = useFeatureValue(
		GROWTHBOOK_FEATURE_FLAGS.GET_PRICE_ELASTICITY,
		{ group_experiment: 'A' },
	);

	const annualRegularizationProduct = products
		?.flatMap((item) => item?.products)
		?.find(
			(productInfoItem) =>
				productInfoItem?.slug === PRODUCTS_SLUG.ANNUAL_DECLARATION_HARD,
		);

	const annualPrice =
		annualRegularizationProduct?.price &&
		getPriceWithElasticity(annualRegularizationProduct?.price);

	const propsTopBanner = {
		productName,
		subtitle,
		price: startingPrice,
		afterPrice: 'Cada año',
	};

	const handleAddProduct = async () => {
		if (!planConfig?.annualYears) return;

		const payload = planConfig?.annualYears?.map((year) => ({
			product_version_id:
				annualRegularizationProduct?.default_product_version_id,
			custom_product: {
				group_experiment: elasticityPrice.group_experiment,
				total_paid: Number(annualPrice),
				payload_user_id: userProfile?.id,
			},
			additional_information: {
				regime: [],
				year,
			},
		}));

		const eventProperties = {
			event_type: EVENT_TYPES.PLANS_ADD_TO_CART,
			product_name:
				annualRegularizationProduct?.name || 'annual_regularization',
			regime: annualRegularizationProduct?.regime?.join('') || '',
			period: payload?.[0]?.additional_information?.year,
		};
		logClevertapEvent(ANALYTICS_EVENTS.ADD_TO_CART, eventProperties);

		const response = await handleAddCustomProduct(payload);
		dataLayer.push({ ecommerce: null });
		dataLayer.push({
			event: ANALYTICS_EVENTS.ADD_TO_CART,
			phone: userProfile?.cellphone,
			rfc: taxPayer?.code ?? '',
			ecommerce: {
				items: response?.map((item: Item) => ({
					price: item?.base_price / 100,
					item_id: item?.product_version?.product_id,
					item_name: item?.product_version?.product?.name,
					category: item?.product_version?.product?.regime?.toString(),
					item_category1: item?.product_version?.product?.category,
					item_category2: item?.purchase_type,
				})),
			},
		});
	};

	const handleAddYears = (year: number) => {
		const newYears = planConfig?.annualYears?.includes(year);

		dispatch(
			setPlanConfig({
				...planConfig,
				annualYears: newYears
					? planConfig?.annualYears?.filter((y) => y !== year)
					: [...(planConfig?.annualYears || []), year],
			}),
		);
	};

	return (
		<>
			<TopBanner {...propsTopBanner} />
			<div className='flex flex-col justify-between h-full'>
				<PlanFlowContentWrapper>
					<Text
						size='body-3'
						weight='medium'
						sx='mb-4 mt-1'
						responsive
					>
						Selecciona el año que deseas declarar
					</Text>
					<div className='grid grid-cols-2 gap-2 md:gap-4'>
						{getLastYears(numYears, DATES.currentYear - 1).map(
							(year, index) => (
								<PlanCheckInput
									key={`annual-${index}`}
									label={`${year}`}
									checked={planConfig?.annualYears?.includes(year) || false}
									onClick={() => handleAddYears(year)}
								/>
							),
						)}
					</div>
					<BenefitsDropdown benefits={annualRegularizationProduct?.benefits} />
				</PlanFlowContentWrapper>
				<PlanFlowButtons
					disableButton={!planConfig.annualYears}
					buttonLabel={product?.buttonText}
					isLoading={isLoadingAddToCart.products}
					onClickButton={handleAddProduct}
				/>
			</div>
		</>
	);
};

export default AnnualRegularization;
