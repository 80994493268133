import React, { useMemo } from 'react';
import Text from '../../atoms/Text';
import {
	capitalizeFirstLetter,
	formatCurrency,
	parseDeductibleAmount,
} from '../../../shared/utils/general.util';

interface Props {
	name: string;
	amount: string;
	onAction?: () => void;
}

const DeductiblesBannerInfo: React.FC<Props> = ({ name, amount, onAction }) => {
	const amountNumber = useMemo(() => parseDeductibleAmount(amount), [amount]);

	return (
		<div className='py-3 sm:py-4 lg:py-6 xl:py-3 2xl:py-12'>
			<div className='pl-4 lg:pl-6'>
				<Text
					size='caption'
					color='dark'
					sx='!block z-50 max-w-[60%] lg:text-xl xl:text-xl 2xl:text-2xl'
				>
					<span className='font-bold mr-2'>
						{capitalizeFirstLetter(name.split(' ')[0].toLocaleLowerCase())}
					</span>

					{amountNumber > 0 ? (
						<>
							¡Tienes{' '}
							<span className='font-bold'>
								${formatCurrency(amountNumber, false)}{' '}
							</span>{' '}
							en gastos deducibles en el 2023!
						</>
					) : (
						<>¡No cuentas con deducciones personales!</>
					)}
				</Text>
			</div>
			<div className='bg-h-primary-90 mt-1 lg:mt-3 pl-4 lg:pl-6 py-2'>
				<Text
					size='mini-1'
					color='dark'
					sx={`max-w-[60%] sm:max-w-[70%] xl:max-w-[300px] !block lg:text-lg xl:text-md 2xl:text-xl ${
						amountNumber === 0 && '!text-h-primary'
					}`}
				>
					{amountNumber > 0 ? (
						'Contrata tu declaración anual con Heru y recupera tu saldo a favor.'
					) : (
						<>
							Presenta tu declaración anual con Heru y aun podrás{' '}
							<strong>obtener un saldo a favor.</strong>
						</>
					)}
				</Text>
			</div>
			<div className='inline-flex justify-between'>
				<button
					className='bg-h-primary-40 text-white text-[10px] md:text-[12px] p-2 rounded-lg border mt-2 lg:mt-4 ml-2'
					onClick={onAction}
				>
					{amountNumber > 0
						? 'Contratar declaración anual'
						: 'Presentar declaración anual'}
				</button>
				<Text
					size='mini-2'
					sx='ml-2 lg:ml-6 mt-4'
				>
					* Aplican T&C
				</Text>
			</div>
		</div>
	);
};

export default React.memo(DeductiblesBannerInfo);
