import Text from '../../atoms/Text';
import Input from '../../atoms/Input';
import Button from '../../atoms/Button';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { SubmitHandler, useForm } from 'react-hook-form';
import { useEffect, useMemo, useState } from 'react';
import LITERALS from '../../../shared/constants/literals';
import { REGEX } from '../../../shared/constants/regex';
import {
	fetchUserSATPolling,
	linkSATAccountEFirmaHeru,
	linkSATAccountHeru,
	postSATLinkFlow,
} from '../../../services/satLinking.service';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import InputFile from '../../atoms/InputFile';
import { convertFiletoBase64 } from '../../../shared/utils/general.util';
import { LockSimpleOpen, Warning } from '@phosphor-icons/react';
import { showAlert, AlertPayload } from '../../../redux/slices/alert.slice';
import { ALERT_TYPE } from '../Alert';
import {
	ANALYTICS_EVENTS,
	EVENT_TYPES,
} from '../../../shared/constants/events';
import {
	msgToolTipPassword,
	msgTooltip,
} from '../../../shared/constants/SatLinkMessages';
import Modal from '../Modal/Modal';
import clevertap from 'clevertap-web-sdk';
import Divider from '../../atoms/Divider';
import {
	POLLING_STATUS,
	SATPolling,
	TUserLink,
} from '../../../@types/SATLinking';
import { useSATLinkFlow } from '../../../shared/constants/links';
import AlertBanner from '../AlertBanner';
import useCleverTapEvent from '../../../hooks/useClevertapEvent';

//DATA
type SATLinkForm = {
	rfc: string;
	password: string;
};

export type SATEFirmaForm = {
	cert: any;
	key: any;
	password: string;
};

interface Props {
	sx?: string;
	showEFirmaForm?: boolean;
	onCallbackSuccess?: (value: any) => void;
	onCallbackError?: (error: any) => void;
	buttonLabel?: string;
	title?: string;
}

export const schemaSATLinking = yup
	.object()
	.shape({
		rfc: yup
			.string()
			.required(LITERALS.REQUIRED_FIELD)
			.matches(REGEX.rfcRegex, LITERALS.REG_EXP_RFC),
		password: yup.string().required(LITERALS.REQUIRED_FIELD),
		// .matches(REGEX.nameRegex, LITERALS.PASSWORD_INVALID)
		// .min(8, LITERALS.MIN_LENGTH_PASSWORD),
	})
	.required(LITERALS.REQUIRED_FIELD);

const schemaEFirma = yup
	.object({
		key: yup.mixed().required(LITERALS.REQUIRED_FIELD),
		cert: yup.mixed().required(LITERALS.REQUIRED_FIELD),
		password: yup.string().required(LITERALS.REQUIRED_FIELD),
		// .matches(REGEX.nameRegex, LITERALS.MIN_LENGTH_PASSWORD)
		// .min(8, LITERALS.MIN_LENGTH_PASSWORD),
	})
	.required(LITERALS.REQUIRED_FIELD);

const SATLinkingForm = ({
	sx,
	showEFirmaForm,
	onCallbackSuccess,
	onCallbackError,
	buttonLabel = 'Vincular',
	title,
}: Props) => {
	const {
		control,
		handleSubmit,
		formState: { errors },
	} = useForm<SATLinkForm>({
		resolver: yupResolver(schemaSATLinking),
		defaultValues: {
			rfc: '',
			password: '',
		},
	});

	const {
		control: controlEFirma,
		handleSubmit: handleSubmitEFirma,
		formState: { errors: errorsEFirma },
	} = useForm<SATEFirmaForm>({
		resolver: yupResolver(schemaEFirma),
		defaultValues: {
			cert: undefined,
			key: undefined,
			password: '',
		},
	});

	const [isLoading, setIsLoading] = useState(false);
	const [numbersOfIntents, setNumbersOfIntents] = useState(0);
	const [openMondal, setOpenMondal] = useState(false);
	const userProfile = useSelector((state: RootState) => state.profile.user);
	const [pollingStatus, setPollingStatus] = useState(POLLING_STATUS.INACTIVE);
	const [userRFC, setUserRFC] = useState('');
	const { tokenSession } = useSelector((state: RootState) => state.auth);

	const dispatch = useDispatch();
	const { dataLayer } = window as any;
	const logClevertapEvent = useCleverTapEvent();

	const logLinkEvent = (source: 'rfc' | 'Efirma', rfc?: string) => {
		logClevertapEvent(ANALYTICS_EVENTS.HOME_LINK, {
			event_type: EVENT_TYPES.HOME_LINK_ATTEMP,
			id: userProfile?.id!,
			phone: userProfile?.cellphone!,
			source,
		});

		dataLayer.push({
			event: ANALYTICS_EVENTS.ADD_RFC,
			user_id: userProfile?.id,
			phone: userProfile?.cellphone,
			rfc: rfc,
		});
	};

	const successLinkHandler = async (resp: unknown, rfc?: string) => {
		clevertap.profile.push({
			Site: {
				rfc: rfc ? rfc : userRFC,
			},
		});
		showAlertTop({
			title: 'Éxito',
			type: ALERT_TYPE.SUCCESS,
			description: LITERALS.SUCCESS_SAT_LINK,
		});
		setIsLoading(false);
		onCallbackSuccess?.(resp);
	};

	//? POLLING SAT LINK FLOW
	const pullingLinkHandler = async () => {
		try {
			const response: SATPolling = await fetchUserSATPolling(
				userProfile?.id!,
				tokenSession,
			);

			switch (response.code) {
				case POLLING_STATUS.PENDING:
					setPollingStatus(POLLING_STATUS.PENDING);
					setTimeout(() => {
						pullingLinkHandler();
					}, 2000);
					break;

				case POLLING_STATUS.DONE:
					setPollingStatus(POLLING_STATUS.DONE);
					successLinkHandler(response);
					break;

				case POLLING_STATUS.FAIL:
					setPollingStatus(POLLING_STATUS.FAIL);
					setIsLoading(false);
					showAlertTop({
						title: 'Error',
						type: ALERT_TYPE.ERROR,
						description: response.errorDetail,
					});

					break;

				default:
					break;
			}
		} catch (error) {
			// @ts-ignore
			if (error?.response?.data?.msg) {
				// @ts-ignore
				showAlertTop({
					title: 'Error',
					type: ALERT_TYPE.ERROR,
					// @ts-ignore
					description: error.response.data.msg,
				});
			} else {
				showAlertTop({
					title: 'Error',
					type: ALERT_TYPE.ERROR,
					description: LITERALS.FISCAL_LINK_ERROR,
				});
			}
			setIsLoading(false);
			onCallbackError?.(error);
		}
	};

	//? POST SAT LINK FLOW
	const linkSatHandler = async (userData: TUserLink) => {
		try {
			await postSATLinkFlow(userData, tokenSession);
			setPollingStatus(POLLING_STATUS.PENDING);
			setTimeout(() => {
				pullingLinkHandler();
			}, 15000);
		} catch (error) {
			setIsLoading(false);
			setPollingStatus(POLLING_STATUS.FAIL);
		}
	};

	const onSubmit: SubmitHandler<SATLinkForm> = async (data) => {
		if (!userProfile?.id || isLoading) return;
		logLinkEvent('rfc', data.rfc);
		if (numbersOfIntents === 4) {
			setNumbersOfIntents(0);
		}
		try {
			setIsLoading(true);
			const userData = {
				userId: userProfile?.id,
				rfc: data.rfc,
				password: data.password,
				source: LITERALS.PLATFORM,
			};
			setUserRFC(data.rfc);
			if (useSATLinkFlow) {
				linkSatHandler(userData);
				return;
			}
			const resp = await linkSATAccountHeru(userData, tokenSession);
			successLinkHandler(resp, data.rfc);
		} catch (error) {
			// @ts-ignore
			if (error?.response?.data?.msg) {
				showAlertTop({
					title: 'Error',
					type: ALERT_TYPE.ERROR,
					// @ts-ignore
					description: error.response.data.msg,
				});
			} else {
				showAlertTop({
					title: 'Error',
					type: ALERT_TYPE.ERROR,
					description: LITERALS.FISCAL_LINK_ERROR,
				});
			}
			setNumbersOfIntents(numbersOfIntents + 1);
			setIsLoading(false);
			onCallbackError?.(error);
		}
	};

	const onSubmitEFirma: SubmitHandler<SATEFirmaForm> = async (data) => {
		if (!userProfile?.id || isLoading) return;
		logLinkEvent('Efirma');
		if (!data.cert || !data.key || !data.password) {
			showAlertTop({
				title: 'Error',
				type: ALERT_TYPE.ERROR,
				description: LITERALS.REQUIRED_FIELDS,
			});
			return;
		}
		let cert = await convertFiletoBase64(data.cert)
			.then((res) => res as string)
			.catch((err) => err);
		let key = await convertFiletoBase64(data.key)
			.then((res) => res as string)
			.catch((err) => err);
		try {
			setIsLoading(true);
			cert.includes('data:') && (cert = cert.split(',')[1]);
			key.includes('data:') && (key = key.split(',')[1]);
			const userData = {
				userId: userProfile?.id,
				cert,
				key,
				password: data.password,
				source: LITERALS.PLATFORM,
			};
			const resp = await linkSATAccountEFirmaHeru(userData, tokenSession);
			setIsLoading(false);

			if (!resp) {
				showAlertTop({
					title: 'Error',
					type: ALERT_TYPE.ERROR,
					description: LITERALS.FISCAL_LINK_ERROR,
				});
				onCallbackError?.(resp);
				return;
			}
			onCallbackSuccess?.(resp);
			showAlertTop({
				title: 'Éxito',
				type: ALERT_TYPE.SUCCESS,
				description: LITERALS.SUCCESS_SAT_LINK,
			});
		} catch (error) {
			setIsLoading(false);

			// @ts-ignore
			if (error?.response?.data?.msg) {
				// @ts-ignore
				showAlertTop({
					title: 'Error',
					type: ALERT_TYPE.ERROR,
					// @ts-ignore
					description: error.response.data.msg,
				});
			} else {
				showAlertTop({
					title: 'Error',
					type: ALERT_TYPE.ERROR,
					description: LITERALS.FISCAL_LINK_ERROR,
				});
			}
			setIsLoading(false);
			onCallbackError?.(error);
		}
	};

	useEffect(() => {
		if (numbersOfIntents === 3) {
			showAlertTop({
				title: 'Alerta',
				type: ALERT_TYPE.WARNING,
				description: '¿Necesitas ayuda?',
			});
		}
	}, [numbersOfIntents]);

	const text = useMemo(
		() =>
			showEFirmaForm
				? 'No tengo sellos digitales'
				: 'No tengo contraseña o la olvide ',
		[showEFirmaForm],
	);

	const showAlertTop = ({ title, type, description }: AlertPayload) => {
		const alert = {
			type,
			title,
			description,
		};
		dispatch(showAlert(alert));
	};

	return (
		<div className={`${sx}`}>
			<Modal
				open={openMondal}
				onClose={() => setOpenMondal(false)}
			>
				<div className='!w-[calc(100vw-60px)] max-w-[500px] h-[calc(100vh-100px)] pt-6 relative'>
					<Text
						size='s'
						sx='text-center bg-h-gray-10 flex justify-center py-2 mt-[-20px] z-10'
						color='medium'
					>
						Aviso: SATID es una página ajena a Heru.
					</Text>
					<iframe
						title='Embedded Page'
						width='100%'
						height='100%'
						src='https://satid.sat.gob.mx/'
						allowFullScreen
					></iframe>
				</div>
			</Modal>
			{showEFirmaForm ? (
				<form onSubmit={handleSubmitEFirma(onSubmitEFirma)}>
					<div className='flex flex-col gap-4'>
						<Text size='m'>Certificado</Text>
						<InputFile
							control={controlEFirma}
							name='cert'
							accept='.cer'
							errorMessage={errorsEFirma.cert?.message as string}
							label='Sube tu certificado (.cer)'
						/>
						<Text size='m'>Clave privada</Text>
						<InputFile
							control={controlEFirma}
							name='key'
							accept='.key'
							errorMessage={errorsEFirma.key?.message as string}
							label='Sube tu clave privada (.key)'
						/>
						<Input
							label='Contraseña clave privada'
							showTooltip
							tooltipMessage={msgToolTipPassword}
							control={controlEFirma}
							name='password'
							type='password'
							placeholder='Contraseña clave privada'
							errorMessage={errorsEFirma.password?.message}
							maxLength={50}
							rules={{ required: true }}
						/>
						<Button
							loading={isLoading}
							disabled={
								!!errorsEFirma.key ||
								!!errorsEFirma.cert ||
								!!errorsEFirma.password ||
								isLoading
							}
							label={buttonLabel}
							type='submit'
						/>
					</div>
				</form>
			) : (
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className='flex flex-col gap-4'>
						{title && (
							<>
								<Text
									size='body-2'
									weight='bold'
									sx='hidden lg:block'
								>
									{title}
								</Text>
								<Text
									size='body-3'
									weight='bold'
									sx='block lg:hidden'
								>
									{title}
								</Text>
								<Divider />
							</>
						)}
						<Input
							label='RFC'
							control={control}
							name='rfc'
							type='text'
							placeholder='Ingresa tu RFC'
							errorMessage={errors.rfc?.message}
							autofocus
							maxLength={13}
							showValueUpperCase
							showTooltip
							tooltipMessage={msgTooltip}
						/>

						<Input
							label='Contraseña Plataforma SAT'
							control={control}
							name='password'
							type='password'
							placeholder='Contraseña Plataforma SAT'
							errorMessage={errors.password?.message}
							showTooltip
							tooltipMessage={msgToolTipPassword}
						/>
						<Button
							loading={isLoading}
							disabled={!!errors.rfc || !!errors.password || isLoading}
							label={buttonLabel}
							type='submit'
						/>
					</div>
				</form>
			)}
			{isLoading && pollingStatus === POLLING_STATUS.INACTIVE && (
				<div className='bg-slate-100 mt-4 p-6'>
					<Text
						size='m'
						color='primary'
					>
						Estamos obteniendo tu información, ya casi terminamos...
					</Text>
				</div>
			)}
			{isLoading && pollingStatus === POLLING_STATUS.PENDING && (
				<AlertBanner
					icon={
						<Warning
							size={24}
							weight='fill'
							color='var(--h-primary-30)'
						/>
					}
					variant='info'
					title='El proceso de vinculación de tu información fiscal puede tardar algunos segundos'
					sx='mt-4 !border-none items-center'
				/>
			)}
			<div className='mt-6 justify-center'>
				<button
					type='button'
					className='w-full'
					onClick={() => setOpenMondal(true)}
				>
					<Text
						size='m'
						color='primary'
						sx='text-center justify-center mt-4 mb-8 '
						weight='medium'
					>
						{text}
					</Text>
				</button>
				<div className='flex flex-row gap-x-3 items-center lg:mt-[110px]'>
					<LockSimpleOpen size={25} />
					<Text
						size='s'
						color='dark'
						sx=''
					>
						Tus datos están 100% protegidos, desvincula cuando quieras
					</Text>
				</div>
			</div>
		</div>
	);
};

export default SATLinkingForm;
