import React, { useMemo } from 'react';
import {
	AdditionalInformation,
	DELETE_IDS,
	Item,
} from '../../../../../@types/Order.type';
import Text from '../../../../../components/atoms/Text';
import {
	formatPrice,
	getPeriodicity,
} from '../../../../../shared/utils/general.util';
import Button from '../../../../../components/atoms/Button';
import { Trash, X } from '@phosphor-icons/react';
import { CategoryName } from '../../../../../@types/Products.type';
import { isRegularizationProduct } from '../../../../../shared/utils/ecommerce.util';
import { nameMonthFull } from '../../../../../shared/utils/formatMonth';

interface IProductItemProps {
	id: number;
	slug: string;
	description: string;
	basePrice: number;
	isLoadDeleteItem: boolean;
	category: string;
	discount?: number;
	showDeleteBtn?: boolean;
	handleItemDelete: (id: number) => void;
	periodicity?: number;
	additionalInformation?: any;
	regularizationProducts?: Item[];
	onDeleteCartItems?: (items: DELETE_IDS[]) => void;
}
const ProductItem: React.FC<IProductItemProps> = ({
	id,
	slug,
	description,
	basePrice,
	category,
	periodicity,
	discount,
	isLoadDeleteItem,
	showDeleteBtn = true,
	handleItemDelete,
	additionalInformation,
	regularizationProducts = [],
	onDeleteCartItems,
}) => {
	const renderProductVersionInfo = useMemo(() => {
		if (category === CategoryName.DeclaraciónMensual) {
			return (
				<div className='px-1.5 py-1 bg-gray-50 rounded border border-blue-100 justify-center items-center gap-1 flex'>
					<div className='text-center text-blue-950 text-base font-normal leading-tight'>
						{periodicity && getPeriodicity(periodicity)}
					</div>
				</div>
			);
		}
		if (category === CategoryName.DeclaraciónAnual) {
			return (
				<div className='px-1.5 py-1 bg-gray-50 rounded border border-blue-100 justify-center items-center gap-1 flex'>
					<div className='text-center text-blue-950 text-base font-normal  leading-tight'>
						{additionalInformation?.year ?? ''}
					</div>
				</div>
			);
		}

		return null;
	}, [category, periodicity, additionalInformation]);

	const handlerDeleteItems = () => {
		if (onDeleteCartItems && category === CategoryName.DeclaraciónAtrasada) {
			onDeleteCartItems(
				regularizationProducts.map((item) => ({ shopping_item_id: item.id })),
			);
		} else {
			handleItemDelete(id);
		}
	};

	const periodLabelReg = useMemo(
		() => (additionalInfo: AdditionalInformation) => {
			if (additionalInfo.month) {
				return nameMonthFull(additionalInfo?.month);
			}

			if (additionalInfo.period) {
				return additionalInfo.period;
			}

			return null;
		},
		[regularizationProducts],
	);

	return (
		<div className='w-full p-6 bg-white rounded-lg shadow flex-col justify-start items-start gap-4'>
			<div className='self-stretch flex-col justify-center items-start gap-4 flex'>
				<div className='self-stretch justify-start items-center gap-2.5 inline-flex'>
					<Text
						size='xl'
						weight='bold'
						sx='grow shrink basis-0 text-blue-950 leading-[25px] lg:block hidden'
					>
						{description}
					</Text>
					<Text
						size='l'
						weight='bold'
						sx='grow shrink basis-0 text-blue-950 leading-[25px] lg:hidden block'
					>
						{description}
					</Text>
					{showDeleteBtn && (
						<div className='flex-col justify-center items-center gap-1 inline-flex'>
							<Button
								variant='outline'
								disabled={isLoadDeleteItem}
								icon={
									<Trash
										color='var(--h-gray-400)'
										size={24}
										weight='light'
									/>
								}
								label=''
								sx='!border-transparent w-6 h-6 justify-center items-center !shadow-none'
								onClick={handlerDeleteItems}
							/>
						</div>
					)}
				</div>

				<div className='w-full h-[0px] border border-neutral-200'></div>

				{!isRegularizationProduct(category, slug) ? (
					<div className='flex flex-row items-center justify-between gap-6 w-full'>
						<div>{renderProductVersionInfo}</div>

						<div className='ml-2 text-right text-h-text-medium text-lg font-bold leading-[25px]'>
							{!!discount && (
								<span className='text-h-text-medium'>
									${formatPrice(basePrice - discount ?? 0)}
								</span>
							)}{' '}
							<span
								className={`${
									!!discount ? 'line-through text-h-text-light' : ''
								}`}
							>
								${formatPrice(basePrice ?? 0)}
							</span>
						</div>
					</div>
				) : (
					<div className='w-full'>
						<div className='flex flex-wrap gap-2.5'>
							{regularizationProducts.map((item, index) => (
								<div
									key={`reg-${item.id}_${index}`}
									className={`pl-4 pr-1 py-1 bg-gray-50 rounded border border-blue-100 justify-center items-center gap-2 flex`}
								>
									<div className='text-center text-blue-950 text-base font-normal leading-tight'>
										<span>
											{periodLabelReg(item?.additional_information ?? {})}
										</span>
										<span className='ml-1'>
											{item.additional_information.year!}
										</span>
									</div>

									<Button
										variant='text'
										disabled={isLoadDeleteItem}
										icon={
											<X
												size={12}
												weight='thin'
											/>
										}
										label=''
										onClick={() => handleItemDelete(item.id)}
									/>
								</div>
							))}
						</div>
						<div className='flex flex-row mt-3 justify-end items-center gap-x-3 w-full '>
							<div>
								<Text
									size='caption'
									color='light'
									sx={`${!!discount ? 'line-through' : ''}`}
								>{`Unidad $${formatPrice(basePrice ?? 0)} (x${
									regularizationProducts.length
								})`}</Text>

								{!!discount && (
									<Text
										size='caption'
										color='medium'
									>{`Unidad $${formatPrice(basePrice - discount ?? 0)} (x${
										regularizationProducts.length
									})`}</Text>
								)}
							</div>

							<div className=' gap-x-2'>
								{!!discount && (
									<Text
										size='body-2'
										weight='bold'
										sx='line-through'
										color='light'
									>
										${formatPrice(basePrice * regularizationProducts.length)}
									</Text>
								)}
								<Text
									size='body-2'
									weight='bold'
									color='medium'
								>
									$
									{formatPrice(
										(!!discount ? basePrice - discount : basePrice) *
											regularizationProducts.length,
									)}
								</Text>
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default React.memo(ProductItem);
