import React, { useEffect, useState } from 'react';
import Heading from '../../components/atoms/Heading';
import { formatPrice } from '../../shared/utils/general.util';
import Button from '../../components/atoms/Button';
import Text from '../../components/atoms/Text';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { PAYMENT_METHOD } from '../../@types/Checkout.type';
import ROUTES from '../../shared/constants/routes';
import { useNavigate } from 'react-router-dom';
import { updateAmountOfItems } from '../../redux/slices/cart.slice';
import { ANALYTICS_EVENTS } from '../../shared/constants/events';
import useCleverTapEvent from '../../hooks/useClevertapEvent';
import ModalReference from '../pendingReferences/components/ModalReference';

const SuccessfulPurchase: React.FC = () => {
	const userProfile = useSelector((state: RootState) => state.profile.user);
	const taxPayer = useSelector((state: RootState) => state.taxProfile.taxPayer);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const logClevertapEvent = useCleverTapEvent();

	const [showModalReference, setShowModalReference] = useState(false);

	const { dataLayer } = window as any;
	const queryParams = new URLSearchParams(location.search);

	const total = queryParams.get('total');
	const items = queryParams.get('items');
	const itemsPrices = queryParams.get('prices');
	const paymentMethod = queryParams.get('paymentMethod');
	const reference = queryParams.get('reference');
	const orderId = queryParams.get('orderId');
	const tax = queryParams.get('tax');
	const coupon = queryParams.get('coupon');

	// Separar los IDs de los items
	const itemsIds = items
		? items.split('&').map((item) => item.split('=')[1])
		: [];
	// Separar los precios de los items
	const itemsPricesArray = itemsPrices
		? itemsPrices.split('&').map((item) => item.split('=')[1])
		: [];

	const resetCard = () => {
		dispatch(updateAmountOfItems(0));
	};

	const trackingEvent = () => {
		const dataLayerProperties = {
			event: ANALYTICS_EVENTS.PURCHASE,
			phone: userProfile?.cellphone,
			rfc: taxPayer?.code ?? '',
			ecommerce: {
				transaction_id: orderId,
				value: formatPrice(parseInt(total!)),
				tax: (parseInt(tax!) ?? 0) / 100,
				currency: 'MXN',
				payment_method: paymentMethod,
				coupon: coupon ?? '',
				items: itemsIds?.map((item) => ({
					item_name: item,
				})),
			},
		};
		dataLayer.push({ ecommerce: null });
		dataLayer.push(dataLayerProperties);

		const eventProperties = {
			Amount: parseInt(total!) / 100,
			'Charged ID': orderId!,
			'Payment mode': paymentMethod ?? '',
			succed: true,
			coupon: coupon ?? '',
			Items: itemsIds?.map((item) => ({
				item_name: item,
			})),
		};
		logClevertapEvent(ANALYTICS_EVENTS.CHARGED, eventProperties);
	};

	useEffect(() => {
		resetCard();
		logClevertapEvent(ANALYTICS_EVENTS.PAGE_VIEWED, {
			name: ROUTES.SUCCESSFUL_PURCHASE,
		});
	}, []);

	useEffect(() => {
		trackingEvent();
	}, []);

	return (
		<div className='bg-white min-h-full'>
			<ModalReference
				paymentMethod={paymentMethod ?? ''}
				showModal={showModalReference}
				setShowModal={setShowModalReference}
				reference={reference ?? ''}
				//@ts-expect-error
				total={total ?? 0}
			/>
			<div
				className={`bg-[url('https://heru-static-assets.s3.us-east-2.amazonaws.com/Group-1000003242.webp')] justify-center min-h-screen flex flex-col items-center 2xl:mt-[-46px] p-4 lg:p-10`}
			>
				<Heading
					size='s'
					weight='bold'
					sx='mb-5'
				>
					{(paymentMethod === PAYMENT_METHOD.SPEI ||
						paymentMethod === PAYMENT_METHOD.CASH) &&
						'¡Gracias!'}
					{(paymentMethod === PAYMENT_METHOD.TRADITIONAL_CASH ||
						paymentMethod === PAYMENT_METHOD.CARD ||
						paymentMethod === PAYMENT_METHOD.PAYPAL) &&
						'Gracias por tu compra'}
				</Heading>
				<Heading
					size='xs'
					sx='mb-2 md:mb-4 w-full text-center'
				>
					{(paymentMethod === PAYMENT_METHOD.SPEI ||
						paymentMethod === PAYMENT_METHOD.CASH) &&
						'Tu compra se encuentra en proceso'}
					{(paymentMethod === PAYMENT_METHOD.TRADITIONAL_CASH ||
						paymentMethod === PAYMENT_METHOD.CARD ||
						paymentMethod === PAYMENT_METHOD.PAYPAL) &&
						`Estamos tramitando tu orden. Recibirás un correo de confirmación a `}
				</Heading>
				{(paymentMethod === PAYMENT_METHOD.TRADITIONAL_CASH ||
					paymentMethod === PAYMENT_METHOD.CARD ||
					paymentMethod === PAYMENT_METHOD.PAYPAL) && (
					<Heading
						size='xs'
						weight='bold'
						sx='mb-10 text-center'
					>
						{userProfile?.email}
					</Heading>
				)}
				<div className='flex flex-col items-center justify-center p-4 md:p-10 w-auto rounded-lg shadow'>
					{(paymentMethod === PAYMENT_METHOD.CASH ||
						paymentMethod === PAYMENT_METHOD.SPEI) && (
						<div className='mb-10 w-full  p-6 bg-h-blue-20 rounded-lg border border-blue-200 flex-col gap-4 flex justify-center items-center text-center'>
							{paymentMethod === PAYMENT_METHOD.SPEI ? (
								<Text
									sx='text-sky-500'
									size='l'
									weight='bold'
								>
									Ya puedes realizar tu pago a través de transferencia
								</Text>
							) : (
								<Text
									sx='text-sky-500'
									size='l'
									weight='bold'
								>
									Ya puedes realizar tu pago en el OXXO más cercano
								</Text>
							)}

							<Text size='l'>
								{`Verifica el número de referencia aquí o encuéntralo en el correo electrónico ${userProfile?.email}`}
							</Text>

							<Button
								label='Ver instrucciones de pago'
								onClick={() => setShowModalReference(true)}
								sx='self-stretch px-3 md:px-8 py-6 rounded-lg shadow justify-center items-center gap-2.5 inline-flex mt-4 md:mt-0'
							/>
						</div>
					)}
					{itemsIds.map((item, index) => (
						<div
							key={index}
							className='w-full h-[25px] justify-between items-center inline-flex m-2 mb-12 md:mb-4'
						>
							<Text
								size='m'
								sx='block md:hidden'
							>
								{item}
							</Text>
							<Text
								size='l'
								sx='hidden md:block'
							>
								{item}
							</Text>
							<Text
								size='m'
								weight='bold'
								sx='block md:hidden'
							>
								{`$${formatPrice(parseInt(itemsPricesArray[index]))}`}
							</Text>
							<Text
								size='l'
								weight='bold'
								sx='hidden md:block'
							>
								{`$${formatPrice(parseInt(itemsPricesArray[index]))}`}
							</Text>
						</div>
					))}
					<div className='w-full h-[25px] justify-between items-start gap-1 inline-flex m-10'>
						<Text
							sx='block md:hidden'
							size='m'
							weight='bold'
						>
							Total a pagar
						</Text>
						<Text
							sx='hidden md:block'
							size='l'
							weight='bold'
						>
							Total a pagar
						</Text>
						<Text
							sx='block md:hidden'
							size='m'
							weight='bold'
						>
							{`$${formatPrice(parseInt(total!))}`}
						</Text>
						<Text
							sx='hidden md:block'
							size='l'
							weight='bold'
						>
							{`$${formatPrice(parseInt(total!))}`}
						</Text>
					</div>

					<Button
						sx='self-stretch px-3 md:px-8 py-3.5 rounded-lg shadow justify-center items-center gap-2.5 inline-flex'
						variant='outline'
						label='Ir a mis compras'
						onClick={() =>
							navigate(ROUTES.SHOPPING, {
								replace: true,
							})
						}
					/>
				</div>
				
			</div>
		</div>
	);
};

export default SuccessfulPurchase;
