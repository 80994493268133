export type CredentialHeruType = {
	userId: number;
	rfc: string;
	password: string;
	source: string;
};

export type CredentialFilesHeruType = {
	userId: number;
	password: string;
	cert: string;
	key: string;
	source: string;
};

export interface SATLinkingAccount {
	msg: string;
	detail: string;
	id: number;
	user_id: number;
	source_id: string;
	source_user_id?: string;
	status: string;
	created_at: string;
	internal_code: number;
}

export type UserLinkedType = {
	id: number;
	user_id: number;
	source_id: string;
	source_user_id: null | string;
	status: string;
	created_at: string;
	credential_id: number;
	username: string;
	linked: boolean;
};

export enum SATLinkStatus {
	PENDING = 'PENDING',
	ACTIVE = 'ACTIVE',
	FAIL = 'FAIL',
}

export type SATPolling = {
	message: string;
	code: number;
	errorDetail: string;
	data: null;
};

export type TUserLink = {
	userId: number;
	rfc: string;
	password: string;
	source: string;
};
export enum POLLING_STATUS {
	PENDING = 404,
	FAIL = 409,
	DONE = 200,
	INACTIVE = 0,
}
