import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clevertap from 'clevertap-web-sdk';

import { Product } from '../../@types/Products.type';
import { getProducts } from '../../services/plans.service';
import { RootState } from '../../redux/store';
import {
	MONTHLY_DECLARATION_PRODUCTS_SLUG,
	PRODUCTS_SLUG,
} from '../../shared/constants/SlugsProducts';
import {
	getElasticyPrice,
	getPriceFromProductVersionId,
} from '../../shared/utils/products.utils';
import { getProductIdBySlug } from '../../shared/utils/ecommerce.util';
import { useFeatureValue } from '@growthbook/growthbook-react';
import GROWTHBOOK_FEATURE_FLAGS from '../../shared/utils/growthbook.util';
import { ANALYTICS_EVENTS, EVENT_TYPES } from '../../shared/constants/events';
import ROUTES from '../../shared/constants/routes';
import growthbookFile from '../../shared/constants/growthbook';
import Heading from '../../components/atoms/Heading';
import TabSelector from '../../components/molecules/TabSelector';
import ProductCard from './organisms/ProductCard';

import PlanBenefits from './organisms/PlanBenefits';
import {
	CircularProgress,
	MenuItem,
	Select,
	useMediaQuery,
} from '@mui/material';
import LayoutCustomPlan from './organisms/LayoutCustomPlan';
import HeruPlusFlow from './ProductsLogic/HeruPlusFlow';
import HeruProFlow from './ProductsLogic/HeruProFlow';
import { fetchUserRegimesIncomes } from '../../services/satLinking.service';
import { addUserRegimes } from '../../redux/slices/taxProfile.slice';
import { APP_CONFIGURATIONS } from '../../shared/constants/configurations';
import { addProducts } from '../../redux/slices/products.slice';
import { getQueryParam } from '../../shared/utils/general.util';
import AnnualRegularization from './ProductsLogic/AnnualRegularization';
import useAddToCart from '../../hooks/useAddToCart';
import { DATES } from '../../shared/utils/dates';
import RegularizationsFlow from './ProductsLogic/RegularizationsFlow';
import PLANS_PRODUCTS_INFO from './ecommerceData';
import {
	setPlanConfig,
	setSelectedInvoiceCategory,
	setSelectedProduct,
} from '../../redux/slices/plans.slice';
import Text from '../../components/atoms/Text';
import { UserRegime } from '../../@types/TaxProfile';
import useCleverTapEvent from '../../hooks/useClevertapEvent';
import { Item } from '../../@types/Ecommerce';

enum PLAN_CATEGORY {
	MONTHLY_DECLARATIONS = 'monthly_declarations',
	REGULARIZATIONS = 'regularizations',
	ANNUAL_DECLARATIONS = 'annual_declarations',
	ASAT = 'asat',
}

const PLANS_CATEGORIES = [
	{
		label: 'Declaraciones mensuales',
		slug: PLAN_CATEGORY.MONTHLY_DECLARATIONS,
	},
	{
		label: 'Declaraciones atrasadas',
		slug: PLAN_CATEGORY.REGULARIZATIONS,
	},
	{
		label: 'Declaraciones anuales',
		slug: PLAN_CATEGORY.ANNUAL_DECLARATIONS,
	},
	{
		label: 'Trámites con el SAT',
		slug: PLAN_CATEGORY.ASAT,
	},
];

export type PlanRegularizationPeriod = {
	year: number;
	month?: number;
	type?: string;
	period?: string;
};

export type PlanConfig = {
	paymentFrecuency?: number;
	regimes?: string[];
	annualYears?: number[];
	regimeConfig?: {
		lease: string;
	};
	step: number;
	regularizationPeriods?: PlanRegularizationPeriod[];
};

export type PlansProduct = {
	tagLabel?: string;
	imageUrl: string;
	productName: React.ReactNode;
	subtitle?: string;
	price: number;
	afterPrice?: string;
	beforePrice?: string;
	description: string;
	buttonText: string;
	buttonVariant?: 'primary' | 'secondary';
	slug: string;
	defaultId: number;
};

export type PropsPlanFlow = {
	product: PlansProduct;
	planConfig: PlanConfig;
	setPlanConfig: any;
	startingPrice: number;
};

const PlansAndPrices = () => {
	const dispatch = useDispatch();
	const logClevertapEvent = useCleverTapEvent();
	const { dataLayer } = window as any;
	const taxPayer = useSelector((state: RootState) => state.taxProfile.taxPayer);

	const { selectedInvoiceCategory, planConfig, selectedProduct } = useSelector(
		(state: RootState) => state.plans,
	);
	const [products, setProducts] = useState<Product[]>([]);
	const [isLoadingServices, setIsLoadingServices] = useState<boolean>(true);

	const { handleAddCustomProduct, isLoading: isLoadingAddToCart } =
		useAddToCart();

	const handleCategory = (slug: string) => {
		dispatch(setSelectedInvoiceCategory(slug));
	};

	const { tokenSession } = useSelector((state: RootState) => state.auth);
	const userProfile = useSelector((state: RootState) => state.profile.user);

	const elasticityPrice = useFeatureValue(
		GROWTHBOOK_FEATURE_FLAGS.GET_PRICE_ELASTICITY,
		{ group_experiment: 'A' },
	);

	const getAllProducts = useCallback(async () => {
		try {
			const data = await getProducts(tokenSession);
			dispatch(addProducts(data));
			const productsUncategorized = data?.flatMap((item) => item?.products);

			if (productsUncategorized?.length > 0) {
				setProducts(productsUncategorized);
				const slugs = [
					PRODUCTS_SLUG.ANNUAL_DECLARATION,
					MONTHLY_DECLARATION_PRODUCTS_SLUG.DECL_FULL,
					PRODUCTS_SLUG.ASSISTANCE_ASAT,
				].map((slug) => ({
					[slug]: getElasticyPrice(
						getPriceFromProductVersionId(data, getProductIdBySlug(data, slug)),
						elasticityPrice.group_experiment,
					),
				}));
				const profileConfigSlugs = {
					Site: {
						pricing_experiment: elasticityPrice.group_experiment,
						...slugs[0],
						...slugs[1],
						...slugs[2],
					},
				};
				// console.log('profile push event', profileConfigSlugs);
				clevertap.profile.push(profileConfigSlugs);
			}
		} catch (error) {}
	}, []);

	const getUserRegimes = async () => {
		try {
			const response = await fetchUserRegimesIncomes();
			const validUserRegimes = response.filter(
				(regime: UserRegime) => regime?.code,
			);
			dispatch(addUserRegimes(validUserRegimes));
		} catch (error) {}
	};

	const loadGrowthbook = async () => {
		await growthbookFile.loadFeatures();
	};

	const getPriceWithElasticity = (price: number) =>
		APP_CONFIGURATIONS.SHOW_PRICE_ELASTICY
			? getElasticyPrice(price, elasticityPrice.group_experiment)
			: price;

	const basePriceByProduct = (product: PlansProduct) => {
		const productInfo = products?.find(
			(productInfoItem) =>
				productInfoItem?.default_product_version_id === product?.defaultId,
		);
		const price = productInfo ? productInfo?.price : product?.price;

		return getPriceWithElasticity(price / 100);
	};

	const getFlowByProductSelected = (product: PlansProduct) => {
		if (!selectedProduct) return null;

		const props = {
			product,
			planConfig,
			setPlanConfig,
			startingPrice: basePriceByProduct(selectedProduct),
		};

		const flows: Record<string, React.ReactNode> = {
			plus: <HeruPlusFlow {...props} />,
			pro: <HeruProFlow {...props} />,
			regularizations: <RegularizationsFlow {...props} />,
			annual_regularization: <AnnualRegularization {...props} />,
		};
		return flows[product?.slug as string];
	};

	const handleClickProduct = async (product: PlansProduct) => {
		dispatch(setSelectedProduct(product));

		if (!userProfile) return;

		if (product.slug === PRODUCTS_SLUG.ASSISTANCE_ASAT) {
			const productASAT = products?.find(
				(productInfoItem) =>
					productInfoItem?.slug === PRODUCTS_SLUG.ASSISTANCE_ASAT,
			);
			if (!productASAT) return;

			const payload = [
				{
					product_version_id: productASAT?.default_product_version_id,
					custom_product: {
						group_experiment: elasticityPrice.group_experiment,
						total_paid: Number(getPriceWithElasticity(productASAT?.price)),
					},
				},
			];

			const eventProperties = {
				event_type: EVENT_TYPES.PLANS_ADD_TO_CART,
				product_name: productASAT?.name,
			};

			logClevertapEvent(ANALYTICS_EVENTS.ADD_TO_CART, eventProperties);

			const response = await handleAddCustomProduct(payload);
			dataLayer.push({ ecommerce: null });
			dataLayer.push({
				event: ANALYTICS_EVENTS.ADD_TO_CART,
				phone: userProfile?.cellphone,
				rfc: taxPayer?.code ?? '',
				ecommerce: {
					items: response?.map((item: Item) => ({
						price: item?.base_price / 100,
						item_id: item?.product_version?.product_id,
						item_name: item?.product_version?.product?.name,
						category: item?.product_version?.product?.regime?.toString(),
						item_category1: item?.product_version?.product?.category,
						item_category2: item?.purchase_type,
					})),
				},
			});
		}
		if (product.slug === PRODUCTS_SLUG.ANNUAL_DECLARATION) {
			const productAnnualDeclaration = products?.find(
				(productInfoItem) =>
					productInfoItem?.slug === PRODUCTS_SLUG.ANNUAL_DECLARATION,
			);
			if (!productAnnualDeclaration) return;

			const payload = [
				{
					product_version_id:
						productAnnualDeclaration?.default_product_version_id,
					custom_product: {
						group_experiment: elasticityPrice.group_experiment,
						total_paid: Number(
							getPriceWithElasticity(productAnnualDeclaration?.price),
						),
						payload_user_id: userProfile?.id,
					},
					additional_information: {
						regimes: [],
						year: DATES.currentYear,
					},
				},
			];

			const eventProperties = {
				event_type: EVENT_TYPES.PLANS_ADD_TO_CART,
				product_name: productAnnualDeclaration?.name,
				regime: productAnnualDeclaration?.regime?.join(''),
				period: payload?.[0]?.additional_information?.year,
			};
			logClevertapEvent(ANALYTICS_EVENTS.ADD_TO_CART, eventProperties);

			await handleAddCustomProduct(payload);
		}
	};

	const handleCloseProductFlow = () => {
		dispatch(setSelectedProduct(null));
		dispatch(
			setPlanConfig({
				step: 1,
			}),
		);
	};

	const propsLayoutCustomPlan = {
		closeModal: () => handleCloseProductFlow(),
		planFlow: getFlowByProductSelected(selectedProduct!),
		selectedProduct,
	};

	useEffect(() => {
		setIsLoadingServices(true);
		Promise.all([
			getAllProducts(),
			getUserRegimes(),
			// Elasticity delay
			new Promise<void>((resolve) => {
				setTimeout(() => {
					resolve();
				}, 1000);
			}),
		]).finally(() => {
			setIsLoadingServices(false);
		});
		logClevertapEvent(ANALYTICS_EVENTS.PAGE_VIEWED, {
			name: ROUTES.PLANS_AND_PRICES,
		});
	}, []);

	useEffect(() => {
		if (!!userProfile?.id) {
			loadGrowthbook();
			growthbookFile?.setAttributes({
				user_id: userProfile?.id,
			});
		}
	}, [userProfile]);

	useEffect(() => {
		logClevertapEvent(ANALYTICS_EVENTS.PLANS, {
			event_type: EVENT_TYPES.PLANS_TAB,
			tab: selectedInvoiceCategory,
		});
	}, [selectedInvoiceCategory]);

	useEffect(() => {
		const product_flow = getQueryParam('product_flow');
		const periodicity = getQueryParam('periodicity');
		const param_step = getQueryParam('step');
		const annual_regularization_years = getQueryParam('years');

		if (annual_regularization_years) {
			const numberSupportedYears = 5;
			const annual_reg_years = annual_regularization_years
				.split(',')
				.map(Number);
			const annual_reg_years_supported = annual_reg_years.filter(
				(year) =>
					Number(year) < DATES.currentYear - 1 ||
					Number(year) >= DATES.currentYear - numberSupportedYears,
			);

			dispatch(
				setPlanConfig({
					...planConfig,
					annualYears: annual_reg_years_supported,
				}),
			);
		}

		if (param_step) {
			dispatch(
				setPlanConfig({
					...planConfig,
					step: Number(param_step),
				}),
			);
		}

		if (periodicity) {
			const periodicityByName: Record<string, number> = {
				monthly: 30,
				bimester: 60,
				quarterly: 90,
				biannual: 180,
				annual: 365,
			};

			dispatch(
				setPlanConfig({
					...planConfig,
					paymentFrecuency: periodicityByName[periodicity],
				}),
			);
		}

		if (product_flow) {
			const categoryByFlow: Record<string, string> = {
				heru_plus: 'monthly_declarations',
				heru_pro: 'monthly_declarations',
				regularizations: 'regularizations',
				annual: 'annual_declarations',
				annual_regularization: 'annual_declarations',
				asat: 'asat',
			};

			const productByFlow: Record<string, PlansProduct> = {
				heru_plus: PLANS_PRODUCTS_INFO.monthly_declarations.products[0],
				heru_pro: PLANS_PRODUCTS_INFO.monthly_declarations.products[1],
				regularizations: PLANS_PRODUCTS_INFO.regularizations.products[0],
				annual_regularization:
					PLANS_PRODUCTS_INFO.annual_declarations.products[1],
			};

			dispatch(
				setSelectedInvoiceCategory(
					categoryByFlow[product_flow] || PLAN_CATEGORY.MONTHLY_DECLARATIONS,
				),
			);

			dispatch(setSelectedProduct(productByFlow[product_flow]));
		}

		logClevertapEvent(ANALYTICS_EVENTS.PLANS, {
			event_type: EVENT_TYPES.PLANS_TAB,
			tab: selectedInvoiceCategory,
		});
	}, []);

	const isMobile = useMediaQuery('(max-width: 768px)');

	if (isLoadingServices) {
		return (
			<div className='flex items-center justify-center mt-10'>
				<CircularProgress size={25} />
			</div>
		);
	}

	const isNotProductFlow =
		selectedProduct?.slug !== PRODUCTS_SLUG.ANNUAL_DECLARATION &&
		selectedProduct?.slug !== PRODUCTS_SLUG.ASSISTANCE_ASAT;

	if (isMobile && selectedProduct && isNotProductFlow)
		return <LayoutCustomPlan {...propsLayoutCustomPlan} />;

	if (!products.length)
		return (
			<div className='w-full h-full flex flex-col items-center justify-center'>
				<Text weight='bold'>Ocurrio un error</Text>
				<Text>
					No se pudieron cargar los productos, por favor intenta de nuevo más
					tarde.
				</Text>
			</div>
		);

	return (
		<section className='p-3 lg:py-6 lg:px-12 flex flex-col gap-4'>
			<Heading
				children='Planes y precios'
				weight='bold'
				size='s'
			/>
			{isMobile ? (
				<Select
					size='small'
					value={selectedInvoiceCategory}
					onChange={(e) =>
						dispatch(setSelectedInvoiceCategory(e.target.value as string))
					}
				>
					{PLANS_CATEGORIES.map((category, index) => (
						<MenuItem
							value={category.slug}
							key={`category-${index}`}
						>
							{category.label}
						</MenuItem>
					))}
				</Select>
			) : (
				<TabSelector
					handleCategory={handleCategory}
					options={PLANS_CATEGORIES}
					currentOption={selectedInvoiceCategory}
				/>
			)}
			<div className='w-full max-w-screen-md mx-auto flex flex-col gap-4 mt-2'>
				<div className='flex flex-col sm:flex-row gap-10'>
					{selectedProduct && <LayoutCustomPlan {...propsLayoutCustomPlan} />}
					{selectedInvoiceCategory === PLAN_CATEGORY.MONTHLY_DECLARATIONS &&
						PLANS_PRODUCTS_INFO[
							PLAN_CATEGORY.MONTHLY_DECLARATIONS
						].products.map((product, index) => {
							return (
								<ProductCard
									key={`product-${index}`}
									tagLabel={product.tagLabel}
									imageUrl={product.imageUrl}
									productName={product.productName}
									subtitle={product.subtitle}
									price={basePriceByProduct(product)}
									afterPrice={product.afterPrice}
									beforePrice={product.beforePrice}
									description={product.description}
									buttonVariant={product.buttonVariant}
									buttonText={product.buttonText}
									onClick={() => handleClickProduct(product)}
									isLoading={
										selectedProduct?.slug === product.slug &&
										isLoadingAddToCart.products
									}
								/>
							);
						})}
					{selectedInvoiceCategory === PLAN_CATEGORY.REGULARIZATIONS &&
						PLANS_PRODUCTS_INFO[PLAN_CATEGORY.REGULARIZATIONS].products.map(
							(product, index) => {
								return (
									<ProductCard
										key={`product-${index}`}
										tagLabel={product.tagLabel}
										imageUrl={product.imageUrl}
										productName={product.productName}
										subtitle={product.subtitle}
										price={basePriceByProduct(product)}
										afterPrice={product.afterPrice}
										beforePrice={product.beforePrice}
										description={product.description}
										buttonVariant={product.buttonVariant}
										buttonText={product.buttonText}
										onClick={() => handleClickProduct(product)}
										isLoading={
											selectedProduct?.slug === product.slug &&
											isLoadingAddToCart.products
										}
									/>
								);
							},
						)}
					{selectedInvoiceCategory === PLAN_CATEGORY.ANNUAL_DECLARATIONS &&
						PLANS_PRODUCTS_INFO[PLAN_CATEGORY.ANNUAL_DECLARATIONS].products.map(
							(product, index) => {
								return (
									<ProductCard
										key={`product-${index}`}
										tagLabel={product.tagLabel}
										imageUrl={product.imageUrl}
										productName={product.productName}
										subtitle={product.subtitle}
										price={basePriceByProduct(product)}
										afterPrice={product.afterPrice}
										beforePrice={product.beforePrice}
										description={product.description}
										buttonVariant={product.buttonVariant}
										buttonText={product.buttonText}
										onClick={() => handleClickProduct(product)}
										isLoading={
											selectedProduct?.slug === product.slug &&
											isLoadingAddToCart.products
										}
									/>
								);
							},
						)}
					{selectedInvoiceCategory === PLAN_CATEGORY.ASAT &&
						PLANS_PRODUCTS_INFO[PLAN_CATEGORY.ASAT].products.map(
							(product, index) => {
								return (
									<ProductCard
										key={`product-${index}`}
										tagLabel={product.tagLabel}
										imageUrl={product.imageUrl}
										productName={product.productName}
										subtitle={product.subtitle}
										price={basePriceByProduct(product)}
										afterPrice={product.afterPrice}
										beforePrice={product.beforePrice}
										description={product.description}
										buttonVariant={product.buttonVariant}
										buttonText={product.buttonText}
										onClick={() => handleClickProduct(product)}
										isLoading={
											selectedProduct?.slug === product.slug &&
											isLoadingAddToCart.products
										}
									/>
								);
							},
						)}
				</div>
				<PlanBenefits
					tableColumns={
						PLANS_PRODUCTS_INFO[selectedInvoiceCategory].benefits.tableColumns
					}
					tableRows={
						PLANS_PRODUCTS_INFO[selectedInvoiceCategory].benefits.tableRows
					}
				/>
			</div>
		</section>
	);
};

export default PlansAndPrices;
