import { Dispatch, SetStateAction } from 'react';
import Modal from '../../../../components/molecules/Modal/Modal';
import SATLink from '../../../../components/molecules/SATLink';

interface Props {
	showModal: boolean;
	setShowModal: Dispatch<SetStateAction<any>>;
	onCallbackSuccess?: (value: any) => void;
}

const ModalLinkRFC = ({
	showModal,
	setShowModal,
	onCallbackSuccess
}: Props) => {

	return (
		<Modal
			open={showModal}
			onClose={() => setShowModal(false)}
			sx='w-[94%] !max-w-[662px]'
		>
      <SATLink onCallbackSuccess={onCallbackSuccess} />
		</Modal>
	);
};

export default ModalLinkRFC;
