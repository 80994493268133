import BenefitsDropdown from '../molecules/BenefitsDropdown';
import PlanFlowButtons from '../molecules/PlanFlowButtons';
import { PropsPlanFlow } from '..';
import { PlanPaymentFrecuencySelector } from '../organisms/PlanSelectors';
import {
	PlanFlowContentWrapper,
	TopBanner,
} from '../organisms/LayoutCustomPlan';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { useFeatureValue } from '@growthbook/growthbook-react';
import { getElasticyPrice } from '../../../shared/utils/products.utils';
import GROWTHBOOK_FEATURE_FLAGS from '../../../shared/utils/growthbook.util';
import { APP_CONFIGURATIONS } from '../../../shared/constants/configurations';
import { formatCurrency } from '../../../shared/utils/general.util';
import useAddToCart from '../../../hooks/useAddToCart';
import {
	ANALYTICS_EVENTS,
	EVENT_TYPES,
} from '../../../shared/constants/events';
import useCleverTapEvent from '../../../hooks/useClevertapEvent';
import { Item } from '../../../@types/Ecommerce';

const HeruPlusFlow: React.FC<PropsPlanFlow> = ({
	product,
	planConfig,
	startingPrice,
}) => {
	const logClevertapEvent = useCleverTapEvent();
	const { dataLayer } = window as any;
	const taxPayer = useSelector((state: RootState) => state.taxProfile.taxPayer);
	const userProfile = useSelector((state: RootState) => state.profile.user);

	const { handleAddCustomProduct, isLoading: isLoadingAddToCart } =
		useAddToCart();

	const { productName, subtitle } = product;

	const { products } = useSelector((state: RootState) => state.productList);

	const getPriceWithElasticity = (price: number) =>
		APP_CONFIGURATIONS.SHOW_PRICE_ELASTICY
			? getElasticyPrice(price, elasticityPrice.group_experiment)
			: price;

	const elasticityPrice = useFeatureValue(
		GROWTHBOOK_FEATURE_FLAGS.GET_PRICE_ELASTICITY,
		{ group_experiment: 'A' },
	);

	const heruPlusProduct = products
		?.flatMap((item) => item?.products)
		?.find(
			(productInfoItem) =>
				productInfoItem?.default_product_version_id === product.defaultId,
		);

	const currentProductVersion = heruPlusProduct?.product_versions?.find(
		(version) => version.periodicity === planConfig?.paymentFrecuency,
	);

	const currentProductVersionPrice = currentProductVersion
		? getPriceWithElasticity(currentProductVersion?.price / 100)
		: product?.price;

	const getProductTotalPrice = () => {
		if (!planConfig?.paymentFrecuency) return startingPrice;

		const amountsDivider: Record<number, number> = {
			30: 1,
			90: 3,
			180: 6,
			365: 12,
		};

		if (currentProductVersionPrice) {
			return Math.round(
				currentProductVersionPrice /
					amountsDivider[planConfig.paymentFrecuency],
			);
		}
		return Math.round(
			startingPrice / amountsDivider[planConfig.paymentFrecuency],
		);
	};

	const propsTopBanner = {
		productName,
		subtitle,
		price: getProductTotalPrice(),
		afterPrice: '/mes',
		beforePrice: !planConfig?.paymentFrecuency ? 'Desde' : '',
		priceDescription: planConfig?.paymentFrecuency
			? `$${formatCurrency(currentProductVersionPrice, false)} Total`
			: '',
	};

	const handleAddProduct = async () => {
		const payload = [
			{
				product_version_id: currentProductVersion?.id,
				custom_product: {
					group_experiment: elasticityPrice.group_experiment,
					total_paid: Number(currentProductVersionPrice * 100),
				},
			},
		];
		const eventProperties = {
			event_type: EVENT_TYPES.PLANS_ADD_TO_CART,
			product_name: heruPlusProduct?.name || 'heru_plus',
			regime: heruPlusProduct?.regime?.join('') || '',
		};
		logClevertapEvent(ANALYTICS_EVENTS.ADD_TO_CART, eventProperties);
		const response = await handleAddCustomProduct(payload);
		dataLayer.push({ ecommerce: null });
		dataLayer.push({
			event: ANALYTICS_EVENTS.ADD_TO_CART,
			phone: userProfile?.cellphone,
			rfc: taxPayer?.code ?? '',
			ecommerce: {
				items: response?.map((item: Item) => ({
					price: item?.base_price / 100,
					item_id: item?.product_version?.product_id,
					item_name: item?.product_version?.product?.name,
					category: item?.product_version?.product?.regime?.toString(),
					item_category1: item?.product_version?.product?.category,
					item_category2: item?.purchase_type,
				})),
			},
		});
	};

	return (
		<>
			<TopBanner {...propsTopBanner} />
			<div className='flex flex-col justify-between h-full'>
				<PlanFlowContentWrapper>
					<PlanPaymentFrecuencySelector planConfig={planConfig} />
					<BenefitsDropdown benefits={heruPlusProduct?.benefits} />
				</PlanFlowContentWrapper>
				<PlanFlowButtons
					disableButton={!planConfig.paymentFrecuency}
					buttonLabel={product?.buttonText}
					isLoading={isLoadingAddToCart.products}
					onClickButton={handleAddProduct}
				/>
			</div>
		</>
	);
};

export default HeruPlusFlow;
