import React, {
	createContext,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import {
	CaretDoubleLeft,
	CaretDoubleRight,
	CaretLeft,
	ChartLineUp,
	ChartPieSlice,
	Files,
	Handshake,
	HouseLine,
	ReadCvLogo,
	Receipt,
	SignOut,
} from '@phosphor-icons/react';
import { useLocation } from 'react-router-dom';
import RappiLogo from '../../../assets/img/svg/rappi-logo.svg';
import RappiLogoWhite from '../../../assets/img/svg/rappi-logo-white.svg';

//? Components
import Text from '../../atoms/Text';
import CustomIcon from '../../atoms/CustomIcon';

//? Utils
import ROUTES, { ROUTES_SLUGS } from '../../../shared/constants/routes';
import { PLANS_UTM_VALUES } from '../../../shared/constants/utms';
import LOCAL_STORAGE_KEY from '../../../shared/constants/localStorageKey';
import HeruLogoIcon from '../../../assets/img/svg/heru_logo.svg';
import {
	getFirstLetter,
	isLocalStorageEnabled,
	isSessionStorageEnabled,
} from '../../../shared/utils/general.util';

//? Redux
import { RootState } from '../../../redux/store';
import { logout, removeToken } from '../../../redux/slices/auth.slice';
import { restartAppState } from '../../../redux/slices/sat.slice';
import { restartTaxPayerData } from '../../../redux/slices/taxProfile.slice';
import { setExpandedSidebar } from '../../../redux/slices/expanded.slice';
import { setOverwriteUtm, updateUTMs } from '../../../redux/slices/cart.slice';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import GROWTHBOOK_FEATURE_FLAGS from '../../../shared/utils/growthbook.util';
import useCleverTapEvent from '../../../hooks/useClevertapEvent';
import {
	ANALYTICS_EVENTS,
	EVENT_TYPES,
} from '../../../shared/constants/events';
import { resetPlans } from '../../../redux/slices/plans.slice';

interface CollapsibleSidebarProps {
	expanded: boolean;
	setExpanded: React.Dispatch<React.SetStateAction<boolean>>;
}
interface SidebarItemProps {
	icon: JSX.Element;
	text: string;
	active?: boolean;
	onClick: () => void;
	shortName?: string;
	isLogout?: boolean;
	onLogout?: () => void;
}

const SidebarItem = ({
	icon,
	text,
	active: initialActive,
	onClick,
	shortName,
	isLogout,
	onLogout,
}: SidebarItemProps) => {
	const { expanded } = useContext(SidebarContext);
	const [active, setActive] = useState(initialActive);
	useEffect(() => {
		setActive(initialActive);
	}, [initialActive]);

	const handleClick = () => {
		if (!active) {
			setActive(true);
			onClick();
			if (isLogout) {
				onLogout!();
			}
		}
	};

	return (
		<div
			onClick={handleClick}
			className={`flex items-center h-16 cursor-pointer transition-colors group p-6 ${
				expanded ? 'justify-start' : 'justify-center'
			} ${initialActive ? 'bg-h-primary ' : 'hover:bg-h-blue-20 '}`}
		>
			{icon}
			{expanded && (
				<Text
					size='m'
					sx='ml-4'
					color={initialActive ? 'white' : 'darkMenu'}
					weight={initialActive ? 'medium' : 'light'}
				>
					{text}
				</Text>
			)}
			{!expanded && (
				<div
					className={`
          absolute left-14 rounded-md px-4 py-1 ml-5
          bg-h-blue-70
          invisible opacity-20 -translate-x-3 transition-all
          group-hover:visible group-hover:opacity-100 group-hover:translate-x-0
      `}
				>
					<CaretLeft
						size={15}
						weight='fill'
						className='absolute left-[-9px] top-1.5'
						color='var(--h-dark)'
					/>
					<Text
						size='xs'
						color='white'
						weight='regular'
					>
						{shortName}
					</Text>
				</div>
			)}
		</div>
	);
};

const SidebarContext = createContext({ expanded: false });

const CollapsibleSidebar: React.FC<CollapsibleSidebarProps> = ({
	expanded,
	setExpanded,
}) => {
	const location = useLocation();
	const [activeItem, setActiveItem] = useState('home');
	const rappiCertificates = useSelector(
		(state: RootState) => state.rappi.certificates,
	);

	const navigate = useNavigate();
	const dispatch = useDispatch();
	const logClevertapEvent = useCleverTapEvent();

	const userProfile = useSelector((state: RootState) => state.profile.user);

	const showInvoices = useFeatureIsOn(
		GROWTHBOOK_FEATURE_FLAGS.SHOW_FISCAL_DOCUMENTS,
	);
	const showCalendar = useFeatureIsOn(
		GROWTHBOOK_FEATURE_FLAGS.SHOW_TAXBOARD_CALENDAR,
	);

	const routes = [
		{
			label: 'Inicio',
			icon: (
				<HouseLine
					size={26}
					weight='light'
				/>
			),
			path: ROUTES.ROOT,
			slug: ROUTES_SLUGS.HOME,
			shortName: 'Inicio',
		},
		{
			label: 'Planes y precios',
			icon: (
				<Files
					size={26}
					weight='light'
				/>
			),
			path: ROUTES.PLANS_AND_PRICES,
			slug: ROUTES_SLUGS.PLANS_AND_PRICES,
			shortName: 'Planes',
		},
		{
			label: 'Facturación',
			icon: (
				<Receipt
					size={26}
					weight='light'
				/>
			),
			path: ROUTES.INVOICING,
			slug: ROUTES_SLUGS.INVOICING,
			shortName: 'Facturación',
		},
		{
			label: 'Documentos fiscales',
			icon: <ReadCvLogo size={24} />,
			path: ROUTES.FISCAL_DOCUMENTS,
			slug: ROUTES_SLUGS.FISCAL_DOCUMENTS,
			shortName: 'Documentos',
		},
		{
			label: 'Tablero de impuestos',
			icon: (
				<ChartLineUp
					size={26}
					weight='light'
				/>
			),
			path: ROUTES.TAXES,
			slug: ROUTES_SLUGS.TAXES,
			shortName: 'Impuestos',
		},
		{
			label: 'Estado del servicio',
			icon: (
				<ChartPieSlice
					size={26}
					weight='light'
				/>
			),
			path: ROUTES.SERVICE_STATUS,
			slug: ROUTES_SLUGS.SERVICE_STATUS,
			shortName: 'Servicios',
		},
	].filter((route) => {
		if (!showInvoices && route.slug === ROUTES_SLUGS.FISCAL_DOCUMENTS) {
			return false;
		}
		if (!showCalendar && route.slug === ROUTES_SLUGS.TAXES) {
			return false;
		}
		return true;
	});

	if (rappiCertificates?.length) {
		routes.push({
			label: 'Certificados Rappi',
			icon: (
				<img
					src={
						location.pathname.includes(ROUTES_SLUGS.RAPPI_CERTIFICATES)
							? RappiLogoWhite
							: RappiLogo
					}
					alt='Rappi'
					className='w-10 h-10'
				/>
			),
			path: ROUTES.RAPPI_CERTIFICATES,
			slug: ROUTES_SLUGS.RAPPI_CERTIFICATES,
			shortName: 'Certificados Rappi',
		});
	}

	const bottomRoutes = [
		{
			label: userProfile?.first_name || '',
			icon: (
				<div
					className={`w-7 h-7 rounded-full border ${
						location.pathname.includes(ROUTES_SLUGS.PROFILE)
							? 'border-h-neutral-100'
							: 'border-h-primary-10'
					} flex items-center justify-center`}
				>
					<Text
						size='caption'
						weight='medium'
						color={
							location.pathname.includes(ROUTES_SLUGS.PROFILE)
								? 'white'
								: 'darkMenu'
						}
					>
						{getFirstLetter(userProfile?.first_name || '')}
					</Text>
				</div>
			),
			path: ROUTES.USER_PROFILE,
			slug: ROUTES_SLUGS.PROFILE,
			shortName: 'Perfil',
		},
		{
			label: 'Referidos',
			icon: (
				<Handshake
					size={26}
					weight='light'
				/>
			),
			path: ROUTES.REFERRALS,
			slug: ROUTES_SLUGS.REFERRALS,
			shortName: 'Referidos',
		},
		{
			label: 'Salir',
			icon: (
				<SignOut
					size={26}
					weight='light'
				/>
			),
			path: ROUTES.LOGIN,
			slug: ROUTES_SLUGS.LOGOUT,
			shortName: 'Salir',
		},
	];

	const activeRouteSlug = useMemo(() => {
		const foundRoute = routes.find((route) => route.path === location.pathname);
		const foundBottomRoute = bottomRoutes.find(
			(route) => route.path === location.pathname,
		);
		const combinedSlug = foundRoute?.slug || foundBottomRoute?.slug || '';

		return combinedSlug;
	}, [routes, bottomRoutes, location.pathname]);

	useEffect(() => {
		setActiveItem(activeRouteSlug);
	}, [activeRouteSlug]);

	const handleItemClick = (slug: string) => {
		if (slug === 'plans') {
			const { utm_campaign, utm_medium, utm_source } =
				PLANS_UTM_VALUES.HOME_TAB;
			if (isSessionStorageEnabled()) {
				const overwriteUtmKey = LOCAL_STORAGE_KEY.OVERWRITE_UTM;
				const campaignKey = LOCAL_STORAGE_KEY.CAMPAIGN;
				const mediumKey = LOCAL_STORAGE_KEY.MEDIUM;
				const sourceKey = LOCAL_STORAGE_KEY.SOURCE;

				let overwriteUtm = sessionStorage.getItem(overwriteUtmKey);

				if (overwriteUtm === null) {
					sessionStorage.setItem(overwriteUtmKey, 'true');
					overwriteUtm = 'true';
				}

				if (overwriteUtm === 'true') {
					sessionStorage.setItem(campaignKey, utm_campaign);
					sessionStorage.setItem(mediumKey, utm_medium);
					sessionStorage.setItem(sourceKey, utm_source);
				} else {
					if (!sessionStorage.getItem(campaignKey)) {
						sessionStorage.setItem(campaignKey, utm_campaign);
					}
					if (!sessionStorage.getItem(mediumKey)) {
						sessionStorage.setItem(mediumKey, utm_medium);
					}
					if (!sessionStorage.getItem(sourceKey)) {
						sessionStorage.setItem(sourceKey, utm_source);
					}
				}
			} else {
				const currentOverwriteUtm = useSelector(
					(state: RootState) => state.cart.overwriteUtm,
				);

				if (currentOverwriteUtm === undefined || currentOverwriteUtm === null) {
					dispatch(setOverwriteUtm(true));
				}

				if (currentOverwriteUtm === true) {
					dispatch(updateUTMs(PLANS_UTM_VALUES.HOME_TAB));
				} else {
					const currentUTMs = useSelector(
						(state: RootState) => state.cart.utmInfo,
					);
					const updatedUTMs = {
						...currentUTMs,
						utm_campaign: currentUTMs?.utm_campaign || utm_campaign,
						utm_medium: currentUTMs?.utm_medium || utm_medium,
						utm_source: currentUTMs?.utm_source || utm_source,
					};
					dispatch(updateUTMs(updatedUTMs));
				}
			}
		}

		switch (slug) {
			case ROUTES_SLUGS.FISCAL_DOCUMENTS:
				logClevertapEvent(ANALYTICS_EVENTS.FISCAL_DOCUMENTS, {
					event_type: EVENT_TYPES.FISCAL_DOCUMENTS_VIEW_FISCAL_DOCUMENTS,
				});
				break;
			case ROUTES_SLUGS.RAPPI_CERTIFICATES:
				logClevertapEvent(ANALYTICS_EVENTS.RAPPI_CERTIFICATES, {
					event_type: EVENT_TYPES.RAPPI_CERTIFICATES_VIEW,
				});
				break;
			default:
				break;
		}

		setActiveItem(slug);
	};

	const sidebarWidth = useMemo(() => {
		return expanded ? '64' : 'max-content';
	}, [expanded]);

	const onLogout = () => {
		if (isLocalStorageEnabled()) {
			localStorage.removeItem(LOCAL_STORAGE_KEY.TOKEN);
		}
		dispatch(removeToken());
		dispatch(restartAppState());
		dispatch(restartTaxPayerData());
		dispatch(logout());
	};

	const renderIcon = (slug: string, icon: JSX.Element) => {
		const isActive =
			location.pathname.includes(slug) ||
			(slug === 'home' && location.pathname === '/');

		return React.cloneElement(icon, {
			weight: isActive ? 'fill' : 'light',
			color: isActive ? 'var(--h-neutral-100)' : 'var(--h-dark-menu)',
		});
	};

	const handleResetPlans = () => {
		dispatch(resetPlans());
	};

	useEffect(() => {
		dispatch(setExpandedSidebar(expanded));
	}, [dispatch, expanded]);

	return (
		<aside
			onClick={handleResetPlans}
			className={`h-screen fixed top-0 left-0 z-[90] w-${sidebarWidth} bg-white shadow-md transition-width`}
		>
			<nav className='h-full flex flex-col'>
				<div className='p-4 flex justify-between items-center'>
					{expanded ? (
						<button
							onClick={() => navigate(ROUTES.ROOT) }
							className={`overflow-hidden transition-all`}
						>
							<CustomIcon
								sx='ml-3'
								color={'var(--h-primary)'}
								name={'heru-logo'}
								width={80}
							/>
						</button>
					) : (
						<button
							onClick={() => navigate(ROUTES.ROOT)}
							className={`overflow-hidden transition-all`}
						>
							<img
								src={HeruLogoIcon}
								alt='heru_logo'
								className='w-10 h-10'
							/>
						</button>
					)}

					<button
						onClick={() => setExpanded((curr) => !curr)}
						className={`p-1.5 rounded-lg bg-gray-50 hover:bg-gray-100 absolute right-4 text-h-medium ${
							expanded ? '' : 'right-[-40px]'
						}`}
					>
						{expanded ? (
							<CaretDoubleLeft size={16} />
						) : (
							<CaretDoubleRight size={16} />
						)}
					</button>
				</div>

				<SidebarContext.Provider value={{ expanded }}>
					<ul className='flex-1 mt-2'>
						{routes.map((route) => {
							return (
								<Link
									to={route.path}
									key={route.slug}
								>
									<SidebarItem
										icon={renderIcon(route.slug, route.icon)}
										text={route.label}
										active={
											location.pathname.includes(route.slug) ||
											(route.slug === 'home' && location.pathname === '/')
										}
										onClick={() => handleItemClick(route.slug)}
										shortName={route.shortName}
									/>
								</Link>
							);
						})}
					</ul>
					<div className='bg-white flex flex-col border-t'>
						<ul className='flex-1 mt-2'>
							{bottomRoutes.map((route) => (
								<Link
									to={route.path}
									key={route.slug}
								>
									<SidebarItem
										icon={renderIcon(route.slug, route.icon)}
										text={route.label}
										active={route.slug === activeItem}
										onClick={() => handleItemClick(route.slug)}
										shortName={route.shortName}
										isLogout={route.slug === 'logout'}
										onLogout={onLogout}
									/>
								</Link>
							))}
						</ul>
					</div>
				</SidebarContext.Provider>
			</nav>
		</aside>
	);
};

export default React.memo(CollapsibleSidebar);
