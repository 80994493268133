import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import PromptForm from '../../components/organisms/PromptForm';
import Text from '../../components/atoms/Text';
import LinkingForm from '../../components/organisms/LinkingForm';
import { getOnboardingWelcome } from '../../services/heru-ai.service';
import toast, { Toaster } from 'react-hot-toast';
import { CaretLeft, CaretRight } from '@phosphor-icons/react';
import ERROR from '../../shared/constants/errors';
import Heading from '../../components/atoms/Heading';
import Button from '../../components/atoms/Button';
import Recommendations from './Recommendations';
import { OpenAIResponse } from '../../@types/OpenAI.type';
import LOCAL_STORAGE_KEY from '../../shared/constants/localStorageKey';
import Modal from '../../components/molecules/Modal/Modal';
import { useNavigate } from 'react-router-dom';
import Alert, { ALERT_TYPE } from '../../components/molecules/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { hideAlert, showAlert } from '../../redux/slices/alert.slice';
import { RootState } from '../../redux/store';
import {
	getOrders,
	postAddProductsBulk,
	postOrders,
} from '../../services/order.service';
import ROUTES from '../../shared/constants/routes';
import { Order } from '../../@types/Order.type';
import { getProducts } from '../../services/plans.service';
import { ListProducts, ProductsCategory } from '../../@types/Products.type';
import { getLastRegularizationMonth } from '../../shared/utils/dates';
import { RegimesEnum } from '../../@types/Regime.type';
import { ANALYTICS_EVENTS, EVENT_TYPES } from '../../shared/constants/events';
import { getUserProfile } from '../../services/profile.service';
import { Profile } from '../../@types/Profile.type';
import { Item } from '../../@types/Ecommerce';
import {
	setOverwriteUtm,
	updateAmountOfItems,
	updateUTMs,
} from '../../redux/slices/cart.slice';
import { PLANS_UTM_VALUES } from '../../shared/constants/utms';
import OnboardingCarousel from './molecules/OnboardingCarousel';
import useCleverTapEvent from '../../hooks/useClevertapEvent';
import {
	isLocalStorageEnabled,
	isSessionStorageEnabled,
} from '../../shared/utils/general.util';
import {
	getElasticyPrice,
	getPriceFromProductVersionId,
} from '../../shared/utils/products.utils';
import { APP_CONFIGURATIONS } from '../../shared/constants/configurations';
import { useFeatureValue } from '@growthbook/growthbook-react';
import GROWTHBOOK_FEATURE_FLAGS from '../../shared/utils/growthbook.util';

const Onboarding: React.FC = () => {
	const [step, setStep] = useState(1);
	const [isLoading, setIsLoading] = useState({
		updateProfile: false,
		welcomeMessage: false,
		recommendationMessage: false,
		addToCart: false,
	});
	const [AIWelcomeMessage, setAIWelcomeMessage] = useState('');
	const [products, setProducts] = useState<ListProducts[]>([]);

	const [showModal, setShowModal] = useState({
		skip: false,
	});
	const [order, setOrder] = useState<Order[]>([]);
	const [userInfo, setUserInfo] = useState<Profile | null>(null);
	const [recommendedProduct, setRecommendedProduct] = useState({
		id: 0,
		regime: '',
		period: 0,
	});

	const userName = useMemo(
		() =>
			isLocalStorageEnabled()
				? localStorage.getItem(LOCAL_STORAGE_KEY.USER_NAME)?.split(' ')?.[0] ??
					''
				: userInfo?.first_name?.split(' ')?.[0] ?? '',
		[userInfo],
	);

	const alert = useSelector((state: RootState) => state.alert);
	const { tokenSession } = useSelector((state: RootState) => state.auth);

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { dataLayer } = window as any;
	const logClevertapEvent = useCleverTapEvent();
	const elasticityPrice = useFeatureValue(
		GROWTHBOOK_FEATURE_FLAGS.GET_PRICE_ELASTICITY,
		{ group_experiment: 'A' },
	);

	const getProfile = useCallback(async () => {
		try {
			const userInfo = await getUserProfile(tokenSession);
			setUserInfo(userInfo);
		} catch (error) {}
	}, []);

	const getOrder = useCallback(async () => {
		try {
			const response = await getOrders('false', undefined, tokenSession);
			setOrder(response);
			dispatch(updateAmountOfItems(response?.[0]?.items?.length || 0));

			if (response.length === 0) {
				postOrder();
			}
		} catch (error) {}
	}, []);

	const postOrder = useCallback(async () => {
		try {
			const body = {
				status: 'open',
			};
			await postOrders(body, tokenSession);
			getOrder();
		} catch (error) {}
	}, []);

	const postAddProduct = useCallback(async (body: object, orderId: number) => {
		sendClevertapEvent(EVENT_TYPES.ONBOARDING_ADD_PRODUCT);

		try {
			const response = await postAddProductsBulk(orderId, body, tokenSession);

			dataLayer.push({ ecommerce: null });
			dataLayer.push({
				event: ANALYTICS_EVENTS.ADD_TO_CART,
				phone: userInfo?.cellphone,
				rfc: '',
				ecommerce: {
					items: response?.map((item: Item) => ({
						price: item?.base_price / 100,
						item_id: item?.product_version?.product_id,
						item_name: item?.product_version?.product?.name,
						category: item?.product_version?.product?.regime,
						item_category1: item?.product_version?.product?.category,
						item_category2: item?.purchase_type,
					})),
				},
			});
		} catch (error: any | Error) {}
	}, []);

	const getAllProducts = useCallback(async () => {
		try {
			const data = await getProducts(tokenSession);
			setProducts(data);
		} catch (error) {}
	}, []);

	const handleAddProduct = async (
		product_id?: number,
		regime?: string,
		period?: string | number,
	) => {
		const { utm_campaign, utm_medium, utm_source } =
			PLANS_UTM_VALUES.ASAT_RECOMMENDATION;
		if (isSessionStorageEnabled()) {
			const overwriteUtmKey = LOCAL_STORAGE_KEY.OVERWRITE_UTM;
			const campaignKey = LOCAL_STORAGE_KEY.CAMPAIGN;
			const mediumKey = LOCAL_STORAGE_KEY.MEDIUM;
			const sourceKey = LOCAL_STORAGE_KEY.SOURCE;

			let overwriteUtm = sessionStorage.getItem(overwriteUtmKey);

			if (overwriteUtm === null) {
				sessionStorage.setItem(overwriteUtmKey, 'true');
				overwriteUtm = 'true';
			}

			if (overwriteUtm === 'true') {
				sessionStorage.setItem(campaignKey, utm_campaign);
				sessionStorage.setItem(mediumKey, utm_medium);
				sessionStorage.setItem(sourceKey, utm_source);
			} else {
				if (!sessionStorage.getItem(campaignKey)) {
					sessionStorage.setItem(campaignKey, utm_campaign);
				}
				if (!sessionStorage.getItem(mediumKey)) {
					sessionStorage.setItem(mediumKey, utm_medium);
				}
				if (!sessionStorage.getItem(sourceKey)) {
					sessionStorage.setItem(sourceKey, utm_source);
				}
			}
		} else {
			const currentOverwriteUtm = useSelector(
				(state: RootState) => state.cart.overwriteUtm,
			);

			if (currentOverwriteUtm === undefined || currentOverwriteUtm === null) {
				dispatch(setOverwriteUtm(true));
			}

			if (currentOverwriteUtm === true) {
				dispatch(updateUTMs(PLANS_UTM_VALUES.ASAT_RECOMMENDATION));
			} else {
				const currentUTMs = useSelector(
					(state: RootState) => state.cart.utmInfo,
				);
				const updatedUTMs = {
					...currentUTMs,
					utm_campaign: currentUTMs?.utm_campaign || utm_campaign,
					utm_medium: currentUTMs?.utm_medium || utm_medium,
					utm_source: currentUTMs?.utm_source || utm_source,
				};
				dispatch(updateUTMs(updatedUTMs));
			}
		}

		setIsLoading({ ...isLoading, addToCart: true });
		if (!product_id) {
			setIsLoading({ ...isLoading, addToCart: false });
			setStep(4);
			return;
		}

		const bodySubscriptions = (price: number) => [
			{
				product_version_id: product_id,
				additional_information: {
					regimes: regime && regime !== RegimesEnum.MULTIPLE ? [regime] : [],
				},
				custom_product: APP_CONFIGURATIONS.SHOW_PRICE_ELASTICY
					? {
							group_experiment: elasticityPrice.group_experiment,
							total_paid: price.toString(),
						}
					: undefined,
			},
		];

		const bodyAnnual = [
			{
				product_version_id: product_id,
				additional_information: {
					regimes: [],
					year: period,
				},
			},
		];

		const bodyRegularizationMonthly = [
			{
				product_version_id: product_id,
				additional_information: {
					month: getLastRegularizationMonth().month,
					year: getLastRegularizationMonth().year,
				},
			},
		];

		const getBody = () => {
			const _price =
				getElasticyPrice(
					getPriceFromProductVersionId(products, product_id),
					elasticityPrice.group_experiment,
				) * 100;
			switch (AIWelcomeMessage) {
				case ProductsCategory.Regularization:
					return bodyRegularizationMonthly;
				case ProductsCategory.Annual:
					return bodyAnnual;
				case ProductsCategory.ASAT:
					return [
						{
							product_version_id: product_id,
							custom_product: APP_CONFIGURATIONS.SHOW_PRICE_ELASTICY
								? {
										group_experiment: elasticityPrice.group_experiment,
										total_paid: _price.toString(),
									}
								: undefined,
						},
					];
				case ProductsCategory.Subscription:
					return bodySubscriptions(_price);
				default:
					return bodySubscriptions(_price);
			}
		};

		await postAddProduct(getBody(), order?.[0]?.id);
		setIsLoading({ ...isLoading, addToCart: false });

		if (
			AIWelcomeMessage === ProductsCategory.ASAT ||
			AIWelcomeMessage === ProductsCategory.csf
		) {
			navigate(ROUTES.CHECKOUT);
			return;
		}
		setStep(4);
	};

	const onSubmitPromptWelcome = async (data: { userMessage: string }) => {
		sendClevertapEvent(EVENT_TYPES.ONBOARDING_WELCOME);
		setIsLoading({ ...isLoading, welcomeMessage: true });
		try {
			const response = (await getOnboardingWelcome(
				data.userMessage,
				tokenSession,
			)) as OpenAIResponse;

			const functionCall = response?.choices?.[0]?.message?.function_call;

			if (functionCall) {
				if (functionCall.name === 'error') {
					const alert = {
						type: ALERT_TYPE.ERROR,
						title: 'Lo sentimos, no pudimos entender tu mensaje.',
						description:
							'En Heru te ayudamos con tus impuestos, si tienes alguna relacionada a impuestos por favor intenta de nuevo.',
					};
					dispatch(showAlert(alert));
					return;
				}
				setAIWelcomeMessage(functionCall.name);
				setStep(3);
				return;
			}
			toast.error(ERROR.invalid_prompt, {
				position: 'top-right',
			});
		} catch (error) {
			toast.error(ERROR.failed_to_fetch, {
				position: 'top-right',
			});
		} finally {
			setIsLoading({ ...isLoading, welcomeMessage: false });
		}
	};

	const handleGoBack = () => {
		setStep(step - 1);
	};

	const handleSkip = async () => {
		if (step === 4) {
			await handleAddProduct(
				recommendedProduct.id,
				recommendedProduct.regime,
				recommendedProduct.period,
			);
			navigate(ROUTES.ROOT);
			return;
		}

		let routeToNavigate;

		switch (AIWelcomeMessage) {
			case 'csf':
				routeToNavigate = ROUTES.ROOT;
				break;
			case 'invoicing':
				routeToNavigate = ROUTES.INVOICING;
				break;
			default:
				routeToNavigate = ROUTES.CHECKOUT;
				break;
		}

		if (step === 5) {
			sendClevertapEvent(EVENT_TYPES.ONBOARDING_SKIP_LINK_CREDENTIALS);
			navigate(routeToNavigate);
		} else {
			sendClevertapEvent(EVENT_TYPES.ONBOARDING_SKIP_ADD_PRODUCT);
			navigate(ROUTES.ROOT);
		}
	};

	const userIntentDescription = () => {
		const recommendations: Record<string, string> = {
			csf: 'Para que puedas obtener tu constancia de situación fiscal es necesario que vincules tu cuenta del SAT.',
			regularization:
				'Para que podamos presentar tus declaraciones atrasadas es necesario que vincules tu cuenta del SAT.',
			subscription:
				'Para que podamos presentar tus declaraciones mensuales es necesario que vincules tu cuenta del SAT.',
			annual:
				'Para que podamos presentar tu declaración anual es necesario que vincules tu cuenta del SAT.',
			invoicing: 'Comienza a facturar en minutos vinculando tu cuenta del SAT.',
		};

		if (AIWelcomeMessage) {
			return recommendations[AIWelcomeMessage];
		}
		return 'Para que puedas acceder a tus datos fiscales es necesario que vincules tu cuenta del SAT.';
	};

	const sendClevertapEvent = (event_type: string) => {
		logClevertapEvent(ANALYTICS_EVENTS.ONBOARDING, {
			event_type,
		});
	};

	useEffect(() => {
		if (alert.showAlert) {
			setTimeout(() => {
				dispatch(hideAlert());
			}, alert.duration);
		}
	}, [alert]);

	useEffect(() => {
		getProfile();
		getAllProducts();
		getOrder();
		logClevertapEvent(ANALYTICS_EVENTS.PAGE_VIEWED, {
			name: ROUTES.ONBOARDING,
		});
	}, []);

	const handleNextStep = () => {
		sendClevertapEvent(EVENT_TYPES.ONBOARDING_FIRST_STEP);
		setStep(2);
	};

	const handleCloseModal = (type: 'closeIcon' | 'resumeButton') => {
		switch (type) {
			case 'closeIcon':
				sendClevertapEvent(
					step === 5
						? EVENT_TYPES.ONBOARDING_EXIT_LINK_CREDENTIALS
						: EVENT_TYPES.ONBOARDING_EXIT_ADD_PRODUCT,
				);
				break;
			default:
				sendClevertapEvent(
					step === 5
						? EVENT_TYPES.ONBOARDING_RESUME_LINK_CREDENTIALS
						: EVENT_TYPES.ONBOARDING_RESUME_ADD_PRODUCT,
				);
				break;
		}
		setShowModal({ ...showModal, skip: false });
	};

	const seeLaterBtn = () => {
		const { utm_campaign, utm_medium, utm_source } =
			PLANS_UTM_VALUES.ONBOARDING;
		if (isSessionStorageEnabled()) {
			const overwriteUtmKey = LOCAL_STORAGE_KEY.OVERWRITE_UTM;
			const campaignKey = LOCAL_STORAGE_KEY.CAMPAIGN;
			const mediumKey = LOCAL_STORAGE_KEY.MEDIUM;
			const sourceKey = LOCAL_STORAGE_KEY.SOURCE;

			let overwriteUtm = sessionStorage.getItem(overwriteUtmKey);

			if (overwriteUtm === null) {
				sessionStorage.setItem(overwriteUtmKey, 'true');
				overwriteUtm = 'true';
			}

			if (overwriteUtm === 'true') {
				sessionStorage.setItem(campaignKey, utm_campaign);
				sessionStorage.setItem(mediumKey, utm_medium);
				sessionStorage.setItem(sourceKey, utm_source);
			} else {
				if (!sessionStorage.getItem(campaignKey)) {
					sessionStorage.setItem(campaignKey, utm_campaign);
				}
				if (!sessionStorage.getItem(mediumKey)) {
					sessionStorage.setItem(mediumKey, utm_medium);
				}
				if (!sessionStorage.getItem(sourceKey)) {
					sessionStorage.setItem(sourceKey, utm_source);
				}
			}
		} else {
			const currentOverwriteUtm = useSelector(
				(state: RootState) => state.cart.overwriteUtm,
			);

			if (currentOverwriteUtm === undefined || currentOverwriteUtm === null) {
				dispatch(setOverwriteUtm(true));
			}

			if (currentOverwriteUtm === true) {
				dispatch(updateUTMs(PLANS_UTM_VALUES.ONBOARDING));
			} else {
				const currentUTMs = useSelector(
					(state: RootState) => state.cart.utmInfo,
				);
				const updatedUTMs = {
					...currentUTMs,
					utm_campaign: currentUTMs?.utm_campaign || utm_campaign,
					utm_medium: currentUTMs?.utm_medium || utm_medium,
					utm_source: currentUTMs?.utm_source || utm_source,
				};
				dispatch(updateUTMs(updatedUTMs));
			}
		}
		setShowModal({ ...showModal, skip: true });
	};

	return (
		<div className='bg-h-dark min-h-screen flex justify-center sm:items-center p-3'>
			{alert.showAlert && (
				<Alert
					type={alert.type}
					description={alert.description}
					title={alert.title}
				/>
			)}
			<Modal
				open={showModal.skip}
				onClose={() => handleCloseModal('closeIcon')}
				sx='w-[94%] !max-w-md'
			>
				<div className=''>
					<Heading
						size='xs'
						color='dark'
						weight='medium'
						sx='mt-2 mr-5'
					>
						{step === 5
							? '¿Omitir vinculación?'
							: '¿Omitir análisis personalizado?'}
					</Heading>
					<Text sx='mt-1'>Te recomendamos seguir el proceso.</Text>
					<Button
						label={step === 5 ? 'Vincular' : 'Seguir con el proceso'}
						onClick={() => handleCloseModal('resumeButton')}
						sx='mt-5'
					/>
					<Button
						label='Lo haré luego'
						variant='outline'
						onClick={handleSkip}
						sx='mt-2'
					/>
				</div>
			</Modal>
			<Toaster />
			<div className='w-full  max-w-[460px] px-4 sm:px-8 bg-white border-2 border-h-primary/20 py-6 sm:py-12 rounded-xl relative shadow overflow-hidden'>
				{step > 1 && (
					<div className=' max-w-[380px] mx-auto flex justify-between '>
						{step === 2 && <span />}
						{step > 2 && (
							<Button
								label='Volver'
								variant='tertiary'
								onClick={handleGoBack}
								beforeIcon={
									<CaretLeft
										size={12}
										color='var(--h-dark)'
									/>
								}
								sx='relative !w-fit z-10'
							/>
						)}
						{step > 1 && (
							<Button
								label='Ver después'
								variant='tertiary'
								onClick={seeLaterBtn}
								icon={
									<CaretRight
										size={12}
										color='var(--h-dark)'
									/>
								}
								sx='relative !w-fit z-10'
							/>
						)}
					</div>
				)}
				{step === 1 && <OnboardingCarousel handleNextStep={handleNextStep} />}
				{step === 2 && (
					<PromptForm
						step={step}
						title={`Hola ${userName}`}
						subtitle='Soy tu contador personalizado, déjame saber en que te puedo ayudar:'
						example='Por ejemplo: quiero presentar mis impuestos.'
						buttonLabel='Continuar'
						onSubmit={onSubmitPromptWelcome}
						loading={isLoading.welcomeMessage}
					/>
				)}
				{step === 3 && (
					<Recommendations
						setStep={setStep}
						AIWelcomeMessage={AIWelcomeMessage}
						handleAddProduct={handleAddProduct}
						products={products}
						isLoadingAddToCart={isLoading.addToCart}
						setRecommendedProduct={setRecommendedProduct}
					/>
				)}
				{step === 4 && (
					<LinkingForm
						userIntentDescription={userIntentDescription()}
						recommendation={AIWelcomeMessage}
						products={products}
						handleAddProduct={handleAddProduct}
						sendClevertapEvent={sendClevertapEvent}
					/>
				)}
			</div>
		</div>
	);
};

export default memo(Onboarding);
