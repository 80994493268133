import { ArrowRight, Eye } from '@phosphor-icons/react';

import Text from '../../../components/atoms/Text';
import DropdownDeclarations from '../../taxes/components/molecules/DropdownDeclarations';
import Table, { TColumns, TRowData } from '../../../components/molecules/Table';
import FiscalResult from '../atoms/FiscalResult';
import Button from '../../../components/atoms/Button';
import { CircularProgress, useMediaQuery } from '@mui/material';
import React from 'react';
import { TaxboardDeclarationsColumns } from '../../../shared/constants/tables';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { getFormatDate, getMonthName } from '../../../shared/utils/dates.util';
import CALENDAR from '../../../shared/constants/calendar';
import {
	DeclarationsByMonthList,
	TaxboardDeclarationStatus,
} from '../../../@types/Calendar.type';

type Props = {
	handleViewDetail: (
		declaration_id: number,
		declarationPresentedDate?: string,
	) => void;
	isLoadingDetail: boolean;
	handleAddToCart: (regime?: string) => void;
	isLoadingAddToCart: boolean;
};

type DeclarationCategory = {
	title: string;
	subtitle: string;
	status: 'warning' | 'pending' | 'processing' | 'success';
	quantity: number;
	columns: TColumns[];
	slug: string;
};

const MonthlyDetail: React.FC<Props> = ({
	handleViewDetail,
	isLoadingDetail,
	handleAddToCart,
	isLoadingAddToCart,
}) => {
	const [selectedPeriodIndex, setSelectedPeriodIndex] = React.useState<
		any | undefined
	>(undefined);

	const { selectedPeriod, month, year } = useSelector(
		(state: RootState) => state.taxCalendar,
	);

	const handleAction = (
		declaration: DeclarationsByMonthList,
		index: number,
	) => {
		if (declaration?.status === TaxboardDeclarationStatus.IN_PROCESS) {
			return;
		}

		setSelectedPeriodIndex(index);
		if (declaration?.status === TaxboardDeclarationStatus.PENDING) {
			handleAddToCart(declaration?.regimen);
			return;
		}
		handleViewDetail(
			declaration?.declarationId!,
			declaration?.declarationDate || `Abril ${year}`,
		);
	};

	const isMobile = useMediaQuery('(max-width: 640px)');

	const declarationsGroupedByStatus =
		selectedPeriod?.month?.detail?.declarations?.reduce(
			(acc, declaration) => {
				const status = declaration.status;
				if (!acc[status]) {
					acc[status] = [];
				}
				acc[status].push(declaration);
				return acc;
			},
			{} as Record<string, DeclarationsByMonthList[]>,
		);

	const declarationsColumns: DeclarationCategory[] = [
		{
			title: 'No presentadas',
			subtitle: '1 Régimen',
			status: 'warning',
			quantity: 1,
			columns: TaxboardDeclarationsColumns.PENDING,
			slug: TaxboardDeclarationStatus.PENDING,
		},
		{
			title: 'Pendientes de pago al SAT',
			subtitle: '1 Régimen',
			status: 'pending',
			quantity: 1,
			columns: TaxboardDeclarationsColumns.PENDING_PAYMENT,
			slug: TaxboardDeclarationStatus.PENDING_PAY,
		},
		{
			title: 'En proceso',
			subtitle: '1 Régimen',
			status: 'processing',
			quantity: 1,
			columns: TaxboardDeclarationsColumns.IN_PROCESS,
			slug: TaxboardDeclarationStatus.IN_PROCESS,
		},
		{
			title: 'Presentadas',
			subtitle: '1 Régimen',
			status: 'success',
			quantity: 1,
			columns: TaxboardDeclarationsColumns.PRESENTED,
			slug: TaxboardDeclarationStatus.PRESENTED,
		},
	];

	const filteredDeclarationsColumns = declarationsColumns.filter(
		(data) => declarationsGroupedByStatus?.[data.slug]?.length,
	);

	const getRowData = (status: string) => {
		const declarations = declarationsGroupedByStatus?.[status];
		const declarationsOrderByDeclarationDate = declarations?.sort(
			(a, b) =>
				new Date(b.declarationDate || '').getTime() -
				new Date(a.declarationDate || '').getTime(),
		);

		const rowData: TRowData[] | undefined =
			declarationsOrderByDeclarationDate?.map((declaration, index) => {
				const isSelected =
					selectedPeriodIndex === index &&
					selectedPeriod?.month?.detailByRegime;

				return {
					onClick: () => handleAction(declaration, index),
					rowStyle: `${
						declaration?.status !== TaxboardDeclarationStatus.IN_PROCESS
							? `hover:!bg-h-neutral-90/50 cursor-pointer`
							: ''
					} ${isSelected ? 'bg-h-neutral-90/50' : ''}`,
					rowContent: {
						presentation_date: {
							content:
								TaxboardDeclarationStatus.PENDING === declaration?.status ||
								TaxboardDeclarationStatus.IN_PROCESS === declaration?.status
									? `17 ${getMonthName(month + 1, true)} ${year}`
									: `${getFormatDate(
											declaration?.declarationDate || '',
											'DD MMM YYYY',
											false,
											true,
										)}`,
						},
						regime: { content: declaration?.regimen || '' },
						fiscal_result: {
							content:
								declaration?.status === TaxboardDeclarationStatus.IN_PROCESS ? (
									'En proceso...'
								) : declaration?.fiscalResult !== undefined ? (
									<FiscalResult
										status={
											declaration?.fiscalResult > 0
												? declaration?.status ===
													TaxboardDeclarationStatus.PRESENTED
													? 'paid'
													: 'negative'
												: 'neutral'
										}
									/>
								) : (
									'-'
								),
						},
						declaration_type: {
							content:
								declaration?.declarationtype === 'NORMAL'
									? 'Normal'
									: isMobile
										? 'Complem.'
										: 'Complementaria' || 'Normal',
						},
						delay_days: {
							content: `${CALENDAR.calculateDelayDays(year, month)} días`,
							textStyle: '!text-h-red-50',
						},
						date_payment: {
							content: `${getFormatDate(
								declaration?.dueDate || '',
								'DD MMM YYYY',
								false,
								true,
							)}`,
						},
						actions: {
							content: (
								<div className='flex flex-row justify-end'>
									<Button
										label={
											declaration?.status === TaxboardDeclarationStatus.PENDING
												? 'Poner al día'
												: isMobile
													? 'Ver'
													: 'Ver detalle'
										}
										variant='text'
										labelColor='primary'
										labelSize='caption'
										sx='whitespace-nowrap items-center !gap-2 !text-sm'
										icon={
											selectedPeriodIndex === index &&
											(isLoadingAddToCart || isLoadingDetail) ? (
												<CircularProgress size={14} />
											) : declaration?.status ===
											  TaxboardDeclarationStatus.PENDING ? (
												<ArrowRight
													size={16}
													color='var(--h-primary-50)'
												/>
											) : (
												<Eye
													weight={isSelected ? 'fill' : 'regular'}
													size={16}
													color='var(--h-primary-50)'
												/>
											)
										}
									/>
								</div>
							),
						},
					},
				};
			});

		return rowData;
	};

	return (
		<div>
			<Text
				size='caption'
				color='light'
				weight='medium'
				sx='mb-1'
			>
				Declaraciones
			</Text>

			<div className='max-h-[calc(100dvh-300px)] xl:max-h-[calc(100dvh-400px)] overflow-y-auto pr-1'>
				{filteredDeclarationsColumns.map((data, index) => (
					<DropdownDeclarations
						key={`dropdown-${index}`}
						title={data.title}
						subtitle={data.subtitle}
						status={data.status}
						quantity={data.quantity}
						className='mb-1'
					>
						<Table
							columns={data.columns}
							rowsData={getRowData(data.slug) ?? []}
							sx='mt-2'
							responsive={isMobile}
						/>
					</DropdownDeclarations>
				))}
			</div>
		</div>
	);
};

export default MonthlyDetail;
