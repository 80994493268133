import { Fragment, useState } from 'react';

import {
	CaretDown,
	CaretRight,
	CaretUp,
	CheckFat,
	ClockCountdown,
	LockSimple,
	SignOut,
} from '@phosphor-icons/react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

//? Types
import { SummaryUnblock } from '../../../../@types/BlockedSubs';
import { SubscriptionType } from '../../../../@types/Subscription';
import { ServiceStatusElement } from '../../../../@types/ServiceStatus.type';

//? Components
import Tag from '../../../../components/molecules/Tag';
import Text from '../../../../components/atoms/Text';
import Divider from '../../../../components/atoms/Divider';
import Button from '../../../../components/atoms/Button';
import ModalUnLockStatements from './ModalUnlockStatements';

//? Utils
import {
	getRecurrency,
	isSessionStorageEnabled,
} from '../../../../shared/utils/general.util';
import {
	ACTION_CURRENT_SLUG,
	GLOBAL_STATUS,
	VARIANT_STATUS,
	renderAnnualValidateStatus,
} from '../../../../shared/constants/serviceStatus';
import ROUTES from '../../../../shared/constants/routes';
import EmptyServiceIcon from '../../../../assets/img/svg/empty_service.svg';
import { PLANS_UTM_VALUES } from '../../../../shared/constants/utms';
import LOCAL_STORAGE_KEY from '../../../../shared/constants/localStorageKey';

//? Services
import {
	getListBlockedCart,
	getPriceBlockedCart,
	postAddItemToBlockedCart,
	postCreateBlockedCart,
} from '../../../../services/serviceStatus.service';

//? Redux
import { RootState } from '../../../../redux/store';
import {
	setOverwriteUtm,
	updateUTMs,
} from '../../../../redux/slices/cart.slice';
interface ActiveServicesProps {
	subscriptions: SubscriptionType[];
	serviceStatus: ServiceStatusElement[];
	fetchServiceStatus: () => void;
}

const headersTable = [
	{
		id: 1,
		name: 'Servicio',
	},
	{
		id: 2,
		name: 'Declaraciones presentadas',
	},
];
export interface BlockedBulkList {
	product_version_id: number;
	custom_product?: CustomProduct;
	additional_information?: AdditionalInformation;
}

interface CustomProduct {
	total_paid: number;
}
interface AdditionalInformation {
	year: number;
	month: number;
	service_id: number;
	subscription_id: number;
}

const ActiveServices: React.FC<ActiveServicesProps> = ({
	subscriptions,
	serviceStatus,
	fetchServiceStatus,
}) => {
	const [open, setOpen] = useState<number | null>(null);

	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { tokenSession } = useSelector((state: RootState) => state.auth);

	const handleOpen = (itemId: number) => {
		setOpen((prev) => (prev === itemId ? null : itemId));
	};

	const getTagByStatus = (status: string, label: string) => {
		switch (status) {
			case VARIANT_STATUS.IN_PROGRESS:
				return (
					<Tag
						label={label}
						type='filled'
						caseVariant='loading'
						iconLeft={
							<ClockCountdown
								size={16}
								weight='light'
							/>
						}
					/>
				);
			case VARIANT_STATUS.COMPLETED:
				return (
					<Tag
						label={label}
						type='filled'
						caseVariant='success'
						iconLeft={
							<CheckFat
								size={16}
								weight='light'
							/>
						}
					/>
				);
			case VARIANT_STATUS.BLOCKED:
				return (
					<Tag
						label={label}
						type='filled'
						caseVariant='error'
						iconLeft={
							<LockSimple
								size={16}
								weight='light'
							/>
						}
					/>
				);
			case VARIANT_STATUS.ABANDONED:
				return (
					<Tag
						label={label}
						type='filled'
						caseVariant='inactive'
						iconLeft={
							<SignOut
								size={16}
								weight='light'
							/>
						}
					/>
				);
			default:
				return (
					<Tag
						label={label}
						type='filled'
						caseVariant='loading'
						iconLeft={
							<ClockCountdown
								size={16}
								weight='light'
							/>
						}
					/>
				);
		}
	};
	const goToPlans = () => {
		const { utm_campaign, utm_medium, utm_source } =
			PLANS_UTM_VALUES.SERVICES_STATUS;
		if (isSessionStorageEnabled()) {
			const overwriteUtmKey = LOCAL_STORAGE_KEY.OVERWRITE_UTM;
			const campaignKey = LOCAL_STORAGE_KEY.CAMPAIGN;
			const mediumKey = LOCAL_STORAGE_KEY.MEDIUM;
			const sourceKey = LOCAL_STORAGE_KEY.SOURCE;

			let overwriteUtm = sessionStorage.getItem(overwriteUtmKey);

			if (overwriteUtm === null) {
				sessionStorage.setItem(overwriteUtmKey, 'true');
				overwriteUtm = 'true';
			}

			if (overwriteUtm === 'true') {
				sessionStorage.setItem(campaignKey, utm_campaign);
				sessionStorage.setItem(mediumKey, utm_medium);
				sessionStorage.setItem(sourceKey, utm_source);
			} else {
				if (!sessionStorage.getItem(campaignKey)) {
					sessionStorage.setItem(campaignKey, utm_campaign);
				}
				if (!sessionStorage.getItem(mediumKey)) {
					sessionStorage.setItem(mediumKey, utm_medium);
				}
				if (!sessionStorage.getItem(sourceKey)) {
					sessionStorage.setItem(sourceKey, utm_source);
				}
			}
		} else {
			const currentOverwriteUtm = useSelector(
				(state: RootState) => state.cart.overwriteUtm,
			);

			if (currentOverwriteUtm === undefined || currentOverwriteUtm === null) {
				dispatch(setOverwriteUtm(true));
			}

			if (currentOverwriteUtm === true) {
				dispatch(updateUTMs(PLANS_UTM_VALUES.SERVICES_STATUS));
			} else {
				const currentUTMs = useSelector(
					(state: RootState) => state.cart.utmInfo,
				);
				const updatedUTMs = {
					...currentUTMs,
					utm_campaign: currentUTMs?.utm_campaign || utm_campaign,
					utm_medium: currentUTMs?.utm_medium || utm_medium,
					utm_source: currentUTMs?.utm_source || utm_source,
				};
				dispatch(updateUTMs(updatedUTMs));
			}
		}
		navigate(ROUTES.PLANS_AND_PRICES);
	};

	const [showUnlockModal, setShowUnlockModal] = useState(false);

	const [isLoadingAddBlocked, setIsLoadingAddBlocked] = useState(false);

	const addPeriodsBlocked = async (subId: number) => {
		try {
			setIsLoadingAddBlocked(true);
			let order = await getListBlockedCart(tokenSession);
			if (order.length === 0) {
				await postCreateBlockedCart(tokenSession);
				order = await getListBlockedCart(tokenSession);
			}
			const data: SummaryUnblock = await getPriceBlockedCart(
				subId,
				tokenSession,
			);

			let blockedProductsList: BlockedBulkList[] = [];
			serviceStatus
				.filter(
					(it) =>
						it?.product?.subscription_id === subId &&
						it?.status !== GLOBAL_STATUS.CLOSED &&
						it.service_has_step.find(
							(step) =>
								step?.fields_status?.pay_subscription_for_this_declaration ===
								'failed',
						),
				)
				.forEach((p) => {
					blockedProductsList.push({
						product_version_id: data?.product_version_id_for_unlock_declaration,
						custom_product: { total_paid: data?.final_price },
						additional_information: {
							month: p?.product?.additional_information?.month,
							year: p?.product?.additional_information?.year,
							service_id: p?.id,
							subscription_id: p?.product?.subscription_id,
						},
					});
				});

			if (order[0]?.items?.length === 0) {
				await postAddItemToBlockedCart(
					order[0]?.id,
					blockedProductsList,
					tokenSession,
				);
			}
			setActiveUnlockModal(true);
			setShowUnlockModal(true);
			setIsLoadingAddBlocked(false);
		} catch (error) {
			setIsLoadingAddBlocked(false);
		}
	};

	const [activeUnlockModal, setActiveUnlockModal] = useState(false);

	const activeServices = serviceStatus.filter(
		(it) => it?.status !== GLOBAL_STATUS.CLOSED,
	);
	const closedServices = serviceStatus.filter(
		(it) => it?.status === GLOBAL_STATUS.CLOSED,
	);

	const renderOnHoldText = (item: ServiceStatusElement) => {
		if (item?.product?.regime?.includes('Plataformas Tecnológicas')) {
			return 'Estamos a la espera de tus certificados de ingresos para proceder con tu declaración.';
		} else {
			return 'Estamos recopilando tus datos y pronto procesaremos tu declaración.';
		}
	};

	const handleItemClick = (item: ServiceStatusElement) => {
		navigate(`${ROUTES.SERVICE_STATUS}/${item?.id}`);
	};

	return (
		<>
			{activeUnlockModal && (
				<ModalUnLockStatements
					isSingle={false}
					showModal={showUnlockModal}
					setShowModal={setShowUnlockModal}
					activeUnlockModal={activeUnlockModal}
					fetchServiceStatus={fetchServiceStatus}
				/>
			)}

			{subscriptions.length === 0 && activeServices.length === 0 ? (
				<div className='bg-white rounded-lg shadow-md p-6'>
					<div className='bg-h-neutral-94 rounded-lg flex flex-col items-center py-10 lg:py-20 px-4 lg:px-0'>
						<img
							src={EmptyServiceIcon}
							alt='empty-cart'
							className='mb-5'
						/>
						<Text
							size='subtitle'
							weight='bold'
							color='medium'
							sx='mb-2 text-center'
						>
							No tienes ninguna compra activa
						</Text>
						<Text
							size='caption'
							color='medium'
							weight='light'
							sx='text-center'
						>
							Mantente al día con tus obligaciones, adquiriendo el plan perfecto
							para tus declaraciones.
						</Text>

						<div className='mt-10 w-full lg:w-60 px-2'>
							<Button
								label='Ver planes y precios'
								onClick={goToPlans}
							/>
						</div>
					</div>
				</div>
			) : (
				<>
					{subscriptions.length === 0 ? (
						<div className='flex flex-col bg-white p-6 w-full rounded-lg mt-2 shadow-md'>
							<Text
								size='body-2'
								color='medium'
								weight='bold'
								sx='mb-6'
							>
								Suscripciones
							</Text>
							<Divider />

							<div className='bg-h-neutral-94 rounded-lg flex flex-col items-center py-10 mt-2 px-2 lg:px-0'>
								<Text
									size='subtitle'
									weight='bold'
									color='medium'
									sx='mb-2 text-center'
								>
									No tienes suscripciones activas
								</Text>
								<Text
									size='caption'
									color='medium'
									weight='light'
									sx='text-center'
								>
									Mantente al día con tus obligaciones, adquiriendo una
									suscripción para tus declaraciones.
								</Text>

								<div className='mt-10 w-full lg:w-60 px-2'>
									<Button
										label='Ver planes y precios'
										onClick={goToPlans}
									/>
								</div>
							</div>
						</div>
					) : (
						<>
							<div className='hidden lg:flex flex-col bg-white p-6 w-full rounded-lg shadow-md'>
								<Text
									size='body-2'
									color='medium'
									weight='bold'
									sx='mb-6'
								>
									Suscripciones
								</Text>
								<Divider />

								<div className='flex items-center border-b border-h-blue-40 justify-between px-2 pb-2'>
									{headersTable.map((item) => (
										<Text
											size='m'
											color='medium'
											weight='bold'
											key={item.id}
										>
											{item.name}
										</Text>
									))}
								</div>

								<div className='mt-2'>
									{subscriptions.map((activeSub, index) => {
										const isOpen = open === activeSub?.id;
										const hasBlockedDeclarations = activeServices?.filter(
											(it) =>
												it?.product?.subscription_id === activeSub?.id &&
												it?.service_has_step?.find(
													(step) =>
														step?.fields_status
															?.pay_subscription_for_this_declaration ===
														'failed',
												),
										);
										const activeGroupSubs = activeServices?.filter(
											(it) => it?.product?.subscription_id === activeSub?.id,
										);
										const closedGroupSubs = closedServices.filter(
											(it) => it?.product?.subscription_id === activeSub?.id,
										);
										return (
											<Fragment key={`sub-${index}`}>
												<div
													className={`flex items-center py-3 px-2 ${
														index % 2 === 0 ? 'bg-white' : 'bg-h-gray-10'
													}`}
												>
													<div className='flex flex-col w-1/2'>
														<Text
															size='caption'
															weight='bold'
															color='medium'
														>
															{activeSub?.product_version?.product?.name ?? ''}
														</Text>
														<Text
															size='caption'
															weight='light'
															color='medium'
														>
															Suscripción{' '}
															{getRecurrency(
																activeSub?.product_version?.periodicity,
															)}
														</Text>
													</div>

													<div className='flex items-center justify-end pr-4 w-1/2'>
														<div className='flex flex-col'>
															<Text
																size='caption'
																weight='medium'
																color='medium'
																sx='mr-4'
															>
																{activeGroupSubs?.length} {''} Activas
															</Text>
															<Text
																size='caption'
																weight='regular'
																color='light'
																sx='mr-4'
															>
																{closedGroupSubs?.length} {''} finalizadas
															</Text>
														</div>

														<button
															className='mr-2'
															onClick={() => handleOpen(activeSub?.id)}
														>
															{activeGroupSubs.length > 0 ? (
																<>
																	{isOpen ? (
																		<CaretUp
																			size={18}
																			weight='light'
																		/>
																	) : (
																		<CaretDown
																			size={18}
																			weight='light'
																		/>
																	)}
																</>
															) : (
																<div className='ml-3' />
															)}
														</button>
													</div>
												</div>

												{isOpen && (
													<div className='px-2'>
														<div className='border border-gray-300 rounded-lg flex flex-col mb-0 mt-2'>
															<div className='flex items-center justify-between px-2 py-2 bg-h-neutral-90'>
																{headersTable.map((item) => (
																	<Text
																		size='caption'
																		color='medium'
																		weight='bold'
																	>
																		{item?.name}
																	</Text>
																))}
															</div>

															{activeServices
																.filter(
																	(it) =>
																		it?.product?.subscription_id ===
																		activeSub?.id,
																)
																.map((item) => {
																	const currentSlug =
																		item?.service_has_step?.find(
																			(step: any) =>
																				step?.id ===
																				item?.current_service_has_step_id,
																		)?.stage?.slug ?? '';
																	return (
																		<div className='flex items-center bg-h-neutral-98 px-2 border-b border-gray-200 py-2'>
																			<div className='flex flex-col w-1/2 py-1'>
																				<Text
																					size='caption'
																					weight='bold'
																					color='medium'
																				>
																					{item?.product?.name ?? ''}
																				</Text>
																				{item?.product?.regime && (
																					<Text
																						size='caption'
																						weight='light'
																						color='medium'
																						sx='mt-2'
																					>
																						{item?.product?.regime
																							?.join(', ')
																							.toString()}
																					</Text>
																				)}
																			</div>

																			<div className='flex w-1/2 items-center justify-end mr-2'>
																				<div
																					onClick={() =>
																						navigate(
																							`/service-status/${item?.id}`,
																						)
																					}
																					className='flex flex-col items-end'
																				>
																					{getTagByStatus(
																						item?.service_has_step?.find(
																							(step: any) =>
																								step?.id ===
																								item?.current_service_has_step_id,
																						)?.stage?.icon_type ?? '',
																						item?.service_has_step.find(
																							(step: any) =>
																								step?.id ===
																								item?.current_service_has_step_id,
																						)?.stage?.name ?? '',
																					)}

																					<div>
																						{item?.current_service_has_step
																							?.stage?.slug ===
																						'stage_in_progress_validate_status_monthly_declaration_in_annual_declaration' ? (
																							<Text
																								size='caption'
																								weight='light'
																								color='medium'
																								sx='text-right !block mt-2'
																							>
																								{renderAnnualValidateStatus(
																									item?.current_service_has_step
																										?.fields_status,
																								)}
																							</Text>
																						) : (
																							<>
																								{currentSlug !==
																									'stage_completed_annual_declaration' && (
																									<Text
																										size='caption'
																										weight='light'
																										color='medium'
																										sx='mt-2 text-right'
																									>
																										{item?.service_has_step?.find(
																											(step: any) =>
																												step?.id ===
																												item?.current_service_has_step_id,
																										)?.stage?.description ?? ''}
																									</Text>
																								)}
																							</>
																						)}
																					</div>
																				</div>

																				<button>
																					<CaretRight
																						onClick={() =>
																							navigate(
																								`${ROUTES.SERVICE_STATUS}/${item?.id}`,
																							)
																						}
																						size={16}
																						weight='light'
																						className='ml-4'
																					/>
																				</button>
																			</div>
																		</div>
																	);
																})}
														</div>

														{hasBlockedDeclarations.length > 0 && (
															<div className='border-b border-r border-l border-gray-300 rounded-b-lg flex items-end justify-end bg-h-primary-95 py-4 pr-10 mb-4'>
																<div className='flex items-end justify-end'>
																	<div className='w-full'>
																		<Button
																			loading={isLoadingAddBlocked}
																			onClick={() =>
																				addPeriodsBlocked(activeSub?.id)
																			}
																			label={`Desbloquear declaraciones bloqueadas (${hasBlockedDeclarations.length})`}
																		/>
																	</div>
																</div>
															</div>
														)}
													</div>
												)}
											</Fragment>
										);
									})}
								</div>
							</div>

							<div className='flex lg:hidden flex-col bg-white p-4 w-full rounded-lg shadow-md'>
								<Text
									size='body-2'
									color='medium'
									weight='medium'
									sx='mb-4'
								>
									Suscripciones
								</Text>
								<Divider />

								<div>
									{subscriptions.map((activeSub, index) => {
										const isOpen = open === activeSub.id;
										const hasBlockedDeclarations = activeServices.filter(
											(it) =>
												it?.product?.subscription_id === activeSub?.id &&
												it?.service_has_step?.find(
													(step) =>
														step?.fields_status
															?.pay_subscription_for_this_declaration ===
														'failed',
												),
										);
										return (
											<Fragment key={`sub-${index}`}>
												<div
													className={`flex items-center py-3 px-2 ${
														index % 2 === 0 ? 'bg-white' : 'bg-h-gray-10'
													}`}
												>
													<div className='flex flex-col w-3/4'>
														<Text
															size='caption'
															weight='bold'
															color='medium'
															sx='mb-1'
														>
															{activeSub?.product_version?.product?.name ?? ''}
														</Text>
														<Text
															size='caption'
															weight='light'
															color='medium'
															sx='mb-1'
														>
															Suscripción{' '}
															{getRecurrency(
																activeSub?.product_version?.periodicity,
															)}
														</Text>
														<div className='flex items-center'>
															<Text
																size='caption'
																weight='medium'
																color='medium'
																sx='mr-2'
															>
																{
																	serviceStatus.filter(
																		(it) =>
																			it?.product?.subscription_id ===
																				activeSub?.id &&
																			it?.status !== GLOBAL_STATUS.CLOSED,
																	).length
																}{' '}
																{''} Activas /
															</Text>
															<Text
																size='caption'
																weight='regular'
																color='light'
															>
																{
																	serviceStatus.filter(
																		(it) =>
																			it?.product?.subscription_id ===
																				activeSub?.id &&
																			it?.status === GLOBAL_STATUS.CLOSED,
																	).length
																}
																{''} finalizadas
															</Text>
														</div>
													</div>

													<div className='flex items-center justify-end pr-4 w-1/4'>
														{serviceStatus.filter(
															(it) =>
																it?.product?.subscription_id ===
																	activeSub?.id &&
																it?.status !== GLOBAL_STATUS.CLOSED,
														).length > 0 ? (
															<button
																className='mr-2'
																onClick={() => handleOpen(activeSub?.id)}
															>
																{isOpen ? (
																	<CaretUp
																		size={18}
																		weight='light'
																		color={'var(--h-primary)'}
																	/>
																) : (
																	<CaretDown
																		size={18}
																		weight='light'
																		color={'var(--h-primary)'}
																	/>
																)}
															</button>
														) : (
															<div className='ml-3' />
														)}
													</div>
												</div>

												{isOpen && (
													<>
														<div className='border border-gray-300 rounded-lg flex flex-col'>
															{serviceStatus
																.filter(
																	(it) =>
																		it?.product?.subscription_id ===
																			activeSub?.id &&
																		it?.status !== GLOBAL_STATUS.CLOSED,
																)
																.map((item, idx) => (
																	<div
																		className='flex flex-col px-4 bg-h-neutral-98 justify-center'
																		key={idx}
																	>
																		<div className='flex items-center justify-between py-4'>
																			<div className='flex items-center'>
																				<div className='mr-2.5'>
																					{getTagByStatus(
																						item?.service_has_step?.find(
																							(step: any) =>
																								step?.id ===
																								item?.current_service_has_step_id,
																						)?.stage?.icon_type ?? '',
																						'',
																					)}
																				</div>
																				<Text
																					size='caption'
																					weight='regular'
																					color='medium'
																				>
																					{item?.product?.name ?? ''}
																				</Text>
																			</div>
																			<button
																				onClick={() =>
																					navigate(
																						`${ROUTES.SERVICE_STATUS}/${item?.id}`,
																					)
																				}
																				className='flex items-center'
																			>
																				<Text
																					size='mini-2'
																					weight='regular'
																					color='primary'
																					sx='mr-2'
																				>
																					Ver detalle
																				</Text>
																				<CaretRight
																					size={16}
																					weight='light'
																					color={'var(--h-primary)'}
																				/>
																			</button>
																		</div>
																		{idx <
																			serviceStatus.filter(
																				(it) =>
																					it?.product?.subscription_id ===
																						activeSub?.id &&
																					it?.status !== GLOBAL_STATUS.CLOSED,
																			).length -
																				1 && (
																			<div className='h-[1px] w-full bg-h-gray-20' />
																		)}
																	</div>
																))}
														</div>

														{hasBlockedDeclarations?.length > 0 && (
															<div className='border-b border-r border-l border-gray-300 rounded-b-lg flex items-end justify-end bg-h-primary-95 p-4'>
																<div className='w-full'>
																	<Button
																		loading={isLoadingAddBlocked}
																		onClick={() =>
																			addPeriodsBlocked(activeSub?.id)
																		}
																		label={`Desbloquear declaraciones bloqueadas (${hasBlockedDeclarations?.length})`}
																	/>
																</div>
															</div>
														)}
													</>
												)}
											</Fragment>
										);
									})}
								</div>
							</div>
						</>
					)}

					{activeServices.filter(
						(item) =>
							item?.service_type_has_product?.product_type === 'acquisition',
					).length === 0 ? (
						<div className='flex flex-col bg-white p-6 w-full rounded-lg mt-6 shadow-md'>
							<Text
								size='body-2'
								color='medium'
								weight='bold'
								sx='mb-6'
							>
								Compras únicas
							</Text>
							<Divider />

							<div className='bg-h-neutral-94 rounded-lg flex flex-col items-center py-10 mt-2 px-4 lg:px-0'>
								<Text
									size='subtitle'
									weight='bold'
									color='medium'
									sx='mb-2 text-center'
								>
									No tienes compras únicas activas
								</Text>
								<Text
									size='caption'
									color='medium'
									weight='light'
									sx='text-center mt-2'
								>
									Mantente al día con tus obligaciones. Adquiérelas en la
									sección de planes y precios.
								</Text>

								<div className='mt-8 w-full lg:w-60 px-2'>
									<Button
										label='Ver planes y precios'
										onClick={goToPlans}
									/>
								</div>
							</div>
						</div>
					) : (
						<>
							<div className='hidden lg:flex flex-col bg-white p-6 w-full rounded-lg mt-6 shadow-md'>
								<Text
									size='body-2'
									color='medium'
									weight='bold'
									sx='mb-6'
								>
									Compras únicas
								</Text>
								<Divider />

								<div className='flex items-center border-b border-h-blue-40 justify-between px-2 pb-2'>
									{headersTable.map((item) => (
										<Text
											key={item.id}
											size='m'
											color='medium'
											weight='bold'
										>
											{item.name}
										</Text>
									))}
								</div>

								<div className='mt-2'>
									{serviceStatus
										?.filter(
											(item) =>
												item?.service_type_has_product?.product_type ===
													'acquisition' &&
												item?.status !== GLOBAL_STATUS.CLOSED,
										)
										.map((item, index) => {
											const currentSlug =
												item?.service_has_step?.find(
													(step: any) =>
														step?.id === item?.current_service_has_step_id,
												)?.stage?.slug ?? '';

											const fields =
												item?.service_has_step?.find(
													(step: any) =>
														step?.id === item?.current_service_has_step_id,
												)?.fields_status ?? {};
											return (
												<div
													key={`acq-${index}`}
													onClick={() => handleItemClick(item)}
													className={`flex items-center py-3 px-2 ${
														index % 2 === 0 ? 'bg-white' : 'bg-h-gray-10'
													}`}
												>
													<div className='flex flex-col w-1/2'>
														<Text
															size='caption'
															weight='bold'
															color='medium'
														>
															{item?.product?.name ?? ''}
														</Text>
														{item?.product?.regime &&
															item?.product?.regime?.map((regime, index) => (
																<Text
																	key={index}
																	size='caption'
																	weight='light'
																	color='medium'
																	sx='mt-2'
																>
																	{regime}
																</Text>
															))}
													</div>

													<div className='flex items-center w-1/2 justify-end pr-2'>
														<div className='flex flex-col items-end'>
															{getTagByStatus(
																item?.service_has_step.find(
																	(step: any) =>
																		step?.id ===
																		item?.current_service_has_step_id,
																)?.stage?.icon_type ?? '',
																item?.service_has_step.find(
																	(step: any) =>
																		step?.id ===
																		item?.current_service_has_step_id,
																)?.stage?.name ?? '',
															)}

															<div className='mt-2'>
																{currentSlug ===
																ACTION_CURRENT_SLUG.STAGE_IN_PROGRESS_VALIDATE_STATUS_MONTHLY_DECLARATION_IN_ANNUAL_DECLARATION ? (
																	<Text
																		size='caption'
																		weight='light'
																		color='medium'
																		sx='text-right !block'
																	>
																		{renderAnnualValidateStatus(fields)}
																	</Text>
																) : (
																	<>
																		{currentSlug ===
																		ACTION_CURRENT_SLUG.STAGE_IN_PROGRESS_ON_HOLD_DECLARATION ? (
																			<Text
																				size='caption'
																				weight='light'
																				color='medium'
																			>
																				{renderOnHoldText(item)}
																			</Text>
																		) : (
																			<>
																				{currentSlug !==
																					ACTION_CURRENT_SLUG.STAGE_COMPLETED_ANNUAL_DECLARATION && (
																					<Text
																						size='caption'
																						weight='light'
																						color='medium'
																					>
																						{item?.service_has_step.find(
																							(step: any) =>
																								step?.id ===
																								item?.current_service_has_step_id,
																						)?.stage?.description ?? ''}
																					</Text>
																				)}
																			</>
																		)}
																	</>
																)}
															</div>
														</div>

														<CaretRight
															size={16}
															weight='light'
															className='ml-4 cursor-pointer'
														/>
													</div>
												</div>
											);
										})}
								</div>
							</div>

							<div className='flex lg:hidden flex-col bg-white p-4 w-full rounded-lg mt-6 shadow-md'>
								<Text
									size='body-2'
									color='medium'
									weight='bold'
									sx='mb-4'
								>
									Compras únicas
								</Text>
								<Divider />

								<div>
									{serviceStatus
										?.filter(
											(item) =>
												item?.service_type_has_product?.product_type ===
													'acquisition' &&
												item?.status !== GLOBAL_STATUS.CLOSED,
										)
										.map((service, index) => {
											const isOpen = open === service?.id;
											return (
												<>
													<div
														className={`flex items-center py-3 px-2 ${
															index % 2 === 0 ? 'bg-white' : 'bg-h-gray-10'
														}`}
													>
														{!isOpen && (
															<div className='mr-2.5'>
																{getTagByStatus(
																	service.service_has_step?.find(
																		(step: any) =>
																			step?.id ===
																			service?.current_service_has_step_id,
																	)?.stage?.icon_type ?? '',
																	'',
																)}
															</div>
														)}

														<div className='flex flex-col w-3/4'>
															<Text
																size='caption'
																weight='bold'
																color='medium'
															>
																{service?.product?.name ?? ''}
															</Text>
															{service?.product?.regime &&
																service?.product?.regime?.map(
																	(regime, index) => (
																		<Text
																			key={index}
																			size='caption'
																			weight='light'
																			color='medium'
																			sx='mt-2'
																		>
																			{regime}
																		</Text>
																	),
																)}
														</div>

														<div className='flex items-center justify-end pr-1 w-1/4'>
															<button
																onClick={() => {
																	handleOpen(service?.id);
																}}
															>
																{isOpen ? (
																	<CaretUp
																		size={18}
																		weight='light'
																		color={'var(--h-primary)'}
																	/>
																) : (
																	<CaretDown
																		size={18}
																		weight='light'
																		color={'var(--h-primary)'}
																	/>
																)}
															</button>
														</div>
													</div>

													{isOpen && (
														<div
															className={` flex flex-col mb-1 px-2 ${
																index % 2 === 0 ? 'bg-white' : 'bg-h-gray-10'
															}`}
														>
															<div
																onClick={() =>
																	navigate(
																		`${ROUTES.SERVICE_STATUS}/${service?.id}`,
																	)
																}
																className='mt-2.5'
															>
																{getTagByStatus(
																	service?.service_has_step?.find(
																		(step: any) =>
																			step?.id ===
																			service?.current_service_has_step_id,
																	)?.stage?.icon_type ?? '',
																	service?.service_has_step?.find(
																		(step: any) =>
																			step?.id ===
																			service?.current_service_has_step_id,
																	)?.stage?.name ?? '',
																)}
															</div>

															<button
																onClick={() =>
																	navigate(
																		`${ROUTES.SERVICE_STATUS}/${service?.id}`,
																	)
																}
																className='flex items-center justify-end mt-2.5 mb-2 mr-1.5'
															>
																<Text
																	key={index}
																	size='mini-1'
																	weight='regular'
																	color='primary'
																	sx='mr-2'
																>
																	Ver detalle
																</Text>
																<CaretRight
																	size={16}
																	weight='light'
																	color={'var(--h-primary)'}
																/>
															</button>
														</div>
													)}
												</>
											);
										})}
								</div>
							</div>
						</>
					)}
				</>
			)}
		</>
	);
};

export default ActiveServices;
