import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
	CaretRight,
	PersonArmsSpread,
	ShoppingBagOpen,
	SignOut,
	UserList,
} from '@phosphor-icons/react';

//? Components
import Text from '../../components/atoms/Text';
import Heading from '../../components/atoms/Heading';

//? Utils
import ROUTES from '../../shared/constants/routes';

//? Redux
import { RootState } from '../../redux/store';
import { ANALYTICS_EVENTS } from '../../shared/constants/events';
import useCleverTapEvent from '../../hooks/useClevertapEvent';
interface itemProps {
	id: number;
	name: string;
	desc: string;
	icon: JSX.Element;
	onClick: () => void;
}

const Profile: React.FC = () => {
	const userProfile = useSelector((state: RootState) => state.profile.user);
	const navigate = useNavigate();
	const [selectItem, setSelectItem] = useState<string>('');
	const logClevertapEvent = useCleverTapEvent();

	const options = [
		{
			id: 1,
			name: 'Cuenta Heru',
			desc: 'Edita tus datos personales para disfrutar de una experiencia personalizada.',
			icon: (
				<PersonArmsSpread
					weight='fill'
					className='h-7 w-7 lg:h-12 lg:w-12'
				/>
			),
			onClick: () => navigate(ROUTES.ACCOUNT_HERU),
		},
		{
			id: 2,
			name: 'Perfil fiscal',
			desc: 'Aquí encontraras información como tu constante situación fiscal, tu RFC y regímenes a los que perteneces.',
			icon: (
				<UserList
					weight='fill'
					className='h-7 w-7 lg:h-12 lg:w-12'
				/>
			),
			onClick: () => navigate(ROUTES.TAX_PROFILE),
		},
		{
			id: 3,
			name: 'Mis compras',
			desc: 'Conoce el estado de tus compras y visualiza los métodos de pago que has empleado de manera fácil y directa.',
			icon: (
				<ShoppingBagOpen
					weight='fill'
					className='h-7 w-7 lg:h-12 lg:w-12'
				/>
			),
			onClick: () => navigate(`${ROUTES.SHOPPING}?profile=true`),
		},
		{
			id: 4,
			name: 'Mis métodos de pago',
			desc: 'Administra todos tus métodos de pago.',
			icon: (
				<SignOut
					weight='fill'
					className='h-7 w-7 lg:h-12 lg:w-12'
				/>
			),
			onClick: () => navigate(ROUTES.PAYMENT_METHODS),
		},
	];

	const handleItemClick = (item: itemProps) => {
		setSelectItem(item.name);
		setTimeout(() => {
			item.onClick();
		}, 150);
	};

	useEffect(() => {
		logClevertapEvent(ANALYTICS_EVENTS.PAGE_VIEWED, {
			name: ROUTES.USER_PROFILE,
		});
	}, [])

	return (
		<div>
			{/* desktop */}
			<div className='hidden lg:flex lg:flex-col'>
				<div className='p-12'>
					<Heading
						size='s'
						color='dark'
						weight='bold'
					>
						Tu cuenta, {userProfile?.first_name} {userProfile?.father_surname}
					</Heading>
					<Text
						size='l'
						weight='regular'
						color='dark'
						sx='mt-2'
					>
						{userProfile?.email}
					</Text>
					<div className='flex flex-col justify-center items-center mt-12 lg:px-5'>
						{options.map((item) => {
							return (
								<button
									key={item.id}
									onClick={() => handleItemClick(item)}
									className={`flex flex-col p-6 mb-4 rounded-md shadow-sm w-3/4 ${
										selectItem === item.name ? 'bg-h-blue-60' : 'bg-white'
									}`}
								>
									<div className='flex justify-between items-center w-full'>
										<Text
											size='body-2'
											color={
												selectItem === item.name ? 'ultraBlueLight' : 'dark'
											}
											weight='bold'
											sx='mb-4'
										>
											{item.name}
										</Text>

										<CaretRight
											size={24}
											weight='light'
											className='mb-4'
										/>
									</div>

									<div className='border border-var(--h-neutral-90)-200 w-full mb-4'></div>
									<Text
										size='body-3'
										weight='regular'
										color={selectItem === item.name ? 'ultraBlueLight' : 'dark'}
										sx='text-left'
									>
										{item.desc}
									</Text>
								</button>
							);
						})}
					</div>
				</div>
			</div>

			{/* mobile */}
			<div className='flex lg:hidden'>
				<div className='p-4'>
					<Text
						size='body-2'
						color='dark'
						weight='bold'
					>
						Tu cuenta, {userProfile?.first_name} {userProfile?.father_surname}
					</Text>
					<Text
						size='caption'
						weight='regular'
						color='dark'
						sx='mt-2'
					>
						{userProfile?.email}
					</Text>
					<div className='flex flex-col mt-6 mb-12'>
						{options.map((item) => {
							return (
								<button
									key={item.id}
									onClick={() => handleItemClick(item)}
									className={`flex flex-col p-6 mb-2 rounded-md shadow-sm ${
										selectItem === item.name ? 'bg-h-blue-60' : 'bg-white'
									}`}
								>
									<div className='flex justify-between items-center w-full'>
										<Text
											size='body-3'
											color={
												selectItem === item.name ? 'ultraBlueLight' : 'dark'
											}
											weight='bold'
											sx='mb-4'
										>
											{item.name}
										</Text>

										<CaretRight
											size={16}
											weight='light'
											className='mb-4'
										/>
									</div>
									<div className='border border-var(--h-neutral-90)-200 w-full mb-4'></div>
									<Text
										size='caption'
										color={selectItem === item.name ? 'ultraBlueLight' : 'dark'}
										sx='text-left'
										weight='regular'
									>
										{item.desc}
									</Text>
								</button>
							);
						})}
					</div>
				</div>
			</div>
		</div>
	);
};

export default React.memo(Profile);
