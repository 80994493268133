import React, { useMemo } from 'react';
import Heading from '../../atoms/Heading';
import Text from '../../atoms/Text';
import { CaretCircleRight, CaretRight } from '@phosphor-icons/react';

type Banner =
	| 'deductibles-unlink'
	| 'deductibles-loading'
	| 'deductibles-linked';

interface Props {
	variant: Banner;
	title?: string;
	description?: string | React.ReactNode;
	onHandlerAction?: () => void;
	sxBannerImg?: string;
	sx?: string;
	sxBannerContent?: string;
	labelButton?: string;
	urlImage?: string;
}

const DeductiblesBanner: React.FC<Props> = ({
	title,
	labelButton,
	sxBannerImg,
	sx,
	description,
	variant,
	urlImage,
	onHandlerAction,
}) => {
	const descriptionComponent = useMemo(() => {
		switch (variant) {
			case 'deductibles-unlink':
				return (
					<Text
						size='body-2'
						weight='bold'
						sx='lg:!text-2xl mr-2'
						color='white'
					>
						{labelButton}
						<CaretCircleRight
							size={24}
							weight='fill'
							color='white'
						/>
					</Text>
				);

			default:
				<>
					<Text
						size='body-1'
						weight='bold'
						sx='flex-col'
						color='white'
					>
						{labelButton}
					</Text>

					<Text
						size='body-1'
						weight='bold'
						sx='!text-2xl mr-2'
						color='white'
					>
						Contratar declaración anual
					</Text>
					<CaretRight
						size={30}
						weight='fill'
						color='white'
					/>
				</>;
		}
	}, [variant, labelButton]);

	const imageUrl = useMemo(() => {
		if (variant === 'deductibles-unlink') {
			return 'https://heru-static-assets.s3.us-east-2.amazonaws.com/man-smile.webp';
		}

		if (variant === 'deductibles-linked') {
			return 'https://heru-static-assets.s3.us-east-2.amazonaws.com/man-small.webp';
		}
		return 'https://heru-static-assets.s3.us-east-2.amazonaws.com/circle-man.webp';
	}, [variant]);

	const imageUrlMobile = useMemo(() => {
		if (variant === 'deductibles-linked') {
			return 'https://heru-static-assets.s3.us-east-2.amazonaws.com/man-small.webp';
		}

		return 'https://heru-static-assets.s3.us-east-2.amazonaws.com/Mask.webp';
	}, [variant]);

	return (
		<div
			className={`flex flex-col relative rounded-xl sm:rounded-r-none overflow-hidden bg-cover bg-no-repeat bg-center lg:bg-top ${sx}`}
		>
			{title && (
				<>
					<Heading
						size='m'
						weight='medium'
						sx='hidden lg:block'
					>
						{title}
					</Heading>
					<Text
						size='xl'
						weight='medium'
						sx='block lg:hidden'
					>
						{title}
					</Text>
				</>
			)}
			<>{description}</>

			{variant === 'deductibles-unlink' && (
				<button
					type='button'
					className='inline-flex my-6 text-left ml-4 lg:ml-6 xl:ml-9 items-start z-10'
					onClick={onHandlerAction}
				>
					{descriptionComponent}
				</button>
			)}
			<img
				src={urlImage ?? imageUrl}
				alt='image banner'
				className={`!hidden lg:!block absolute bottom-0 w-fit h-auto right-0 ${sxBannerImg}`}
			/>
			<img
				src={urlImage ?? imageUrlMobile}
				alt='image banner'
				className={`!block lg:!hidden absolute bottom-0 right-0 ${sxBannerImg}`}
			/>
		</div>
	);
};

export default React.memo(DeductiblesBanner);
