import React from 'react';
import { CircleNotch } from '@phosphor-icons/react';
import Text from '../../atoms/Text';

const LoadingDeductiblesBanner = () => {
	return (
		<div className='bg-h-blue-60 pl-3 lg:pl-9 my-3 lg:my-6 xl:my-10 py-3 xl:py-6'>
			<div className='w-[60%] lg:w-[40%]'>
				<div className='flex flex-row items-center justify-center'>
					<CircleNotch
						className='animate-spin hidden lg:block'
						size={18}
						color='white'
					/>
				</div>
				<Text
					size='label'
					weight='light'
					color='white'
					sx='!block lg:text-center xl:!text-xl xl:mt-2'
				>
					Estamos calculando tus{' '}
					<span className='font-bold'>deducciones personales </span> para tu
					declaración anual
				</Text>
				<div className='flex flex-row items-center justify-center'>
					<CircleNotch
						className='animate-spin block lg:hidden'
						size={22}
						color='white'
					/>
				</div>
			</div>
		</div>
	);
};

export default React.memo(LoadingDeductiblesBanner);
