import { useDispatch } from 'react-redux';
import { FiscalDocumentType } from '../../../@types/FiscalDocuments.type';
import { ALERT_TYPE } from '../../../components/molecules/Alert';
import { showAlert } from '../../../redux/slices/alert.slice';
import {
	getFiscalDocumentDetailById,
	getUserDeclarationReceipts,
	getUserInvoices,
	getUserRetentionCertificates,
} from '../../../services/fiscal-documents.service';
import ERROR from '../../../shared/constants/errors';
import { FiscalDocumentsLists } from './TableFiscalDocuments';

const useHandleFiscalServices = (
	setFiscalDocuments: React.Dispatch<
		React.SetStateAction<FiscalDocumentsLists>
	>,
	fiscalDocuments: FiscalDocumentsLists,
	setIsLoading: React.Dispatch<any>,
	isLoading: Record<string, boolean>,
	setSelectedDocumentId: React.Dispatch<
		React.SetStateAction<{
			selected: number | null;
			loadingSelected: number | null;
		}>
	>,
	selectedDocumentId: {
		selected: number | null;
		loadingSelected: number | null;
	},
	documentType:
		| FiscalDocumentType.INVOICE
		| FiscalDocumentType.RECEIPT
		| FiscalDocumentType.RETENTION,
) => {
	const dispatch = useDispatch();

	const handleError = () => {
		const alert = {
			type: ALERT_TYPE.ERROR,
			title: ERROR.failed_to_fetch_data,
			description: ERROR.try_again_later,
			duration: 2000,
		};
		dispatch(showAlert(alert));
	};

	const handleGetInvoices = async (
		user_id: number,
		page: number,
		invoice_type: string,
		status: string = 'all',
		year?: number,
		month?: string,
	) => {
		try {
			setIsLoading({
				...isLoading,
				invoice: true,
			});
			setFiscalDocuments({
				...fiscalDocuments,
				invoice: null,
			});
			const response = await getUserInvoices(
				user_id,
				page,
				invoice_type,
				status,
				year,
				month,
			);

			setFiscalDocuments({
				...fiscalDocuments,
				invoice: response,
			});
		} catch (error) {
			handleError();
		} finally {
			setIsLoading({
				...isLoading,
				invoice: false,
			});
		}
	};

	const handleGetRetentions = async (
		user_id: number,
		page: number,
		invoice_type?: string,
		year?: number,
		month?: string,
	) => {
		try {
			setIsLoading({
				...isLoading,
				retention: true,
			});
			setFiscalDocuments({
				...fiscalDocuments,
				retention: null,
			});

			const response = await getUserRetentionCertificates(
				user_id,
				page,
				invoice_type,
				year,
				month,
			);

			setFiscalDocuments({
				...fiscalDocuments,
				retention: response,
			});
		} catch (error) {
			handleError();
		} finally {
			setIsLoading({
				...isLoading,
				retention: false,
			});
		}
	};
	const handleGetReceipts = async (
		user_id: number,
		page: number,
		declaration_type: string,
		year?: number,
		month?: string,
	) => {
		try {
			setIsLoading({
				...isLoading,
				receipt: true,
			});
			setFiscalDocuments({
				...fiscalDocuments,
				receipt: null,
			});

			const response = await getUserDeclarationReceipts(
				user_id,
				page,
				declaration_type,
				year,
				month,
			);

			setFiscalDocuments({
				...fiscalDocuments,
				receipt: response,
			});
		} catch (error) {
			handleError();
		} finally {
			setIsLoading({
				...isLoading,
				receipt: false,
			});
		}
	};

	const handleGetFiscalDocument = async (document_id: number) => {
		if (isLoading.detail) return;

		const documentTypeToNumber: Record<string, number> = {
			[FiscalDocumentType.INVOICE]: 1,
			[FiscalDocumentType.RETENTION]: 2,
			[FiscalDocumentType.RECEIPT]: 3,
		};

		setSelectedDocumentId({
			...selectedDocumentId,
			loadingSelected: document_id,
		});

		try {
			setIsLoading({
				...isLoading,
				detail: true,
			});

			const document = await getFiscalDocumentDetailById(
				documentTypeToNumber[documentType],
				document_id,
			);

			setSelectedDocumentId({
				...selectedDocumentId,
				selected: document_id,
			});

			return document;
		} catch (error) {
			handleError();
		} finally {
			setIsLoading({
				...isLoading,
				detail: false,
			});
		}
	};

	return {
		handleGetInvoices,
		handleGetRetentions,
		handleGetReceipts,
		handleGetFiscalDocument,
	};
};

export default useHandleFiscalServices;
