import { useCallback, useEffect, useState } from 'react';
import TaxesAndInvoicesLinked from './TaxesAndInvoicesLinked';
import { useDispatch, useSelector } from 'react-redux';
import {
	fetchSATLinkedById,
	fetchUserRegimesIncomes,
} from '../../services/satLinking.service';
import { CircularProgress } from '@mui/material';
import {
	linkedAccount,
	setIsRecentlyLinked,
} from '../../redux/slices/sat.slice';
import { setUserSubscriptions } from '../../redux/slices/profile.slice';
import { RootState } from '../../redux/store';
import {
	addAcquisitions,
	addProducts,
} from '../../redux/slices/products.slice';
import { getProducts } from '../../services/plans.service';
import { getUserSuscriptions } from '../../services/suscription.service';
import { fetchAcquisitions } from '../../services/shopping.service';
import EmptyCalendar from './organism/EmptyCalendar';
import Alert from '../../components/molecules/Alert';
import {
	addUserHasRetentions,
	addUserRegimes,
} from '../../redux/slices/taxProfile.slice';
import { fetchProfileFiscalRetentions } from '../../services/profile.service';

const TaxesAndInvoices = () => {
	const [isLoading, setIsLoading] = useState({
		linkedAccount: false,
		userRetentions: false,
		delayGrowthbook: true,
	});

	const dispatch = useDispatch();

	const { status } = useSelector((state: RootState) => state.satLinkStatus);
	const userProfile = useSelector((state: RootState) => state.profile.user);

	const getUserFiscalRetentions = async () => {
		setIsLoading({ ...isLoading, userRetentions: true });
		try {
			const response = await fetchProfileFiscalRetentions(userProfile?.id!);
			dispatch(addUserHasRetentions(response));
		} catch (error) {
		} finally {
			setIsLoading({ ...isLoading, userRetentions: false });
		}
	};

	const checkIsRecentlyLinked = () => {
		const createdDate = new Date(status?.created_at).getTime();
		const currentDate = new Date().getTime() + 21600000;
		const isRecentlyLinked = currentDate - createdDate < 600000;

		dispatch(setIsRecentlyLinked(isRecentlyLinked));
	};

	const fetchSatLinkedAcount = async () => {
		setIsLoading({ ...isLoading, linkedAccount: true });
		try {
			const resource = await fetchSATLinkedById();
			dispatch(linkedAccount(resource));
		} catch (error) {
		} finally {
			setIsLoading({ ...isLoading, linkedAccount: false });
			setTimeout(() => {
				setIsLoading({ ...isLoading, delayGrowthbook: false });
			}, 2000);
		}
	};

	const getAllProducts = async () => {
		try {
			const data = await getProducts();
			dispatch(addProducts(data));
		} catch (error) {}
	};

	const fetchUserSuscriptions = useCallback(async () => {
		try {
			const subs = await getUserSuscriptions();
			dispatch(setUserSubscriptions(subs));
		} catch (error) {}
	}, []);

	const getAcquisitions = async () => {
		try {
			const response = await fetchAcquisitions();
			dispatch(addAcquisitions(response));
		} catch (error) {}
	};

	const getUserRegimes = async () => {
		try {
			const response = await fetchUserRegimesIncomes();
			dispatch(addUserRegimes(response));
		} catch (error) {}
	};

	const userLinked = !isLoading.linkedAccount && status?.status && !!userProfile;

	useEffect(() => {
		if (userLinked && userProfile) {
			getAllProducts();
			getAcquisitions();
			fetchUserSuscriptions();
			checkIsRecentlyLinked();
			getUserRegimes();
			getUserFiscalRetentions();
		}
	}, [userLinked, userProfile]);

	useEffect(() => {
		fetchSatLinkedAcount();
	}, []);

	const alert = useSelector((state: RootState) => state.alert);

	if (
		isLoading.linkedAccount ||
		isLoading.userRetentions ||
		isLoading.delayGrowthbook
	) {
		return (
			<div className='flex items-center justify-center mt-10'>
				<CircularProgress size={25} />
			</div>
		);
	}

	if (!userLinked) {
		return <EmptyCalendar linked={false} />;
	}

	return (
		<div>
			{alert.showAlert && (
				<Alert
					type={alert.type}
					description={alert.description}
					title={alert.title}
				/>
			)}
			<TaxesAndInvoicesLinked />
		</div>
	);
};

export default TaxesAndInvoices;
