import HERU_API from '../shared/constants/heruApi';
import { get } from '../shared/utils/FetchSSL.util';

const getUserInvoices = async (
	user_id: number,
	page: number,
	type: string = 'all',
	status: string = 'all',
	year?: number,
	month?: number | string,
) => {
	const params = `
	&page=${page}
	${type !== 'all' ? `&type=${type}` : ''}
	${year ? `&year=${year}` : ''}
	${month ? `&month=${month}` : ''}
	${status !== 'all' ? `&status=${status}` : ''}`;

	try {
		const response = await get(
			`${HERU_API.USER_INVOICES}?userId=${user_id}${params}`,
			{
				token: true,
				isNewGateway: true,
			},
		);
		return response;
	} catch (error) {
		throw error;
	}
};

const getUserRetentionCertificates = async (
	user_id: number,
	page: number,
	type: string = 'all',
	year?: number,
	month?: number | string,
) => {
	const params = `${type !== 'all' ? `&type=${type}` : ''}&page=${page}${year ? `&year=${year}` : ''}${month ? `&month=${month}` : ''}`;

	try {
		const response = await get(
			`${HERU_API.USER_RETENTION_CERTIFICATES}?userId=${user_id}${params}`,
			{
				token: true,
				isNewGateway: true,
			},
		);
		return response;
	} catch (error) {
		throw error;
	}
};
const getUserDeclarationReceipts = async (
	user_id: number,
	page: number,
	type: string = 'all',
	year?: number,
	month?: number | string,
) => {
	const params = `&page=${page}${year ? `&year=${year}` : ''}${month ? `&month=${month}` : ''}${type !== 'all' ? `&type=${type}` : ''}`;

	try {
		const response = await get(
			`${HERU_API.USER_DECLARATION_RECEIPTS}/${user_id}?userId=${user_id}${params}`,
			{
				token: true,
				isNewGateway: true,
			},
		);
		return response;
	} catch (error) {
		throw error;
	}
};

const getFiscalDocumentDetailById = async (
	document_type: number,
	document_id: number,
) => {
	try {
		const response = await get(
			`${HERU_API.USER_FISCAL_DOCUMENT_DETAIL}?type=${document_type}&identifier=${document_id}`,
			{
				token: true,
				isNewGateway: true,
			},
		);
		return response;
	} catch (error) {
		throw error;
	}
};

export {
	getUserInvoices,
	getUserRetentionCertificates,
	getUserDeclarationReceipts,
	getFiscalDocumentDetailById,
};
