import { Experiment, Result } from '@growthbook/growthbook-react';

type GrowtBookData = Map<
	string,
	{
		experiment: Experiment<any>;
		result: Result<any>;
	}
>;

const getInfoByExperiment = (
	growthbookData: GrowtBookData,
	experiment: string,
) => {
	const growthData = {
		experimentName: '',
		experimentValue: '',
	};

	growthbookData.forEach((info) => {
		if (info.result.featureId === experiment) {
			growthData.experimentName = info.experiment.key;
			growthData.experimentValue = info.result.key;
		}
	});

	return growthData;
};

const GROWTHBOOK_FEATURE_FLAGS = {
	IA_ONBOARDING: 'ia_onboarding',
	SHOW_FISCAL_DOCUMENTS: 'show_fiscal_documents',
	SHOW_TAXBOARD_CALENDAR: 'show_taxboard_calendar',
	SHOW_TAXBOARD_BANNER: 'show_taxboard_banner',
	SHOW_ORGANIZATIONS: 'show_organizations',
	SHOW_FISCAL_DASHBOARD: 'showfiscaldashboard',
	SHOW_INVOICING_FISCAL_DASHBOARD: 'showinvoicingfiscaldashboard',
	SHOW_FISCAL_FILING_DETAIL_V1: 'showfiscalfilingdetailv1',
	SHOW_DOCUMENTS_FISCAL_DASHBOARD: 'showdocumentsfiscaldashboard',
	SHOW_INVOICING_DOCUMENT: 'showinvoicingdocument',
	SHOW_RECEIPT_DOCUMENTS: 'showreceiptdocuments',
	SHOW_RETENTION_DOCUMENTS: 'showretentiondocuments',
	SHOW_MARKETING_BANNER_FISCAL_DASHBOARD: 'showmarketingbannerfiscaldashboard',
	REFERRAL_PRICE: 'referral_price_test',
	GET_PRICE_ELASTICITY: 'price_elasticity',
	WEBHOOK_HUBSPOT: 'webhook_hubspot',
};

export default GROWTHBOOK_FEATURE_FLAGS;

export { getInfoByExperiment };
