import { PlansProduct } from '.';
import { PRODUCTS_SLUG } from '../../shared/constants/SlugsProducts';

type PlanCategory = {
	products: PlansProduct[];
	benefits: {
		tableColumns: (
			| { title: React.ReactNode }
			| { title: React.ReactNode; subtitle: string }
		)[];
		tableRows: (string | { text: string; check: true })[][];
	};
};

const COVER_IMAGE = {
	plus: 'https://heru-static-assets.s3.us-east-2.amazonaws.com/plans_plus.webp',
	pro: 'https://heru-static-assets.s3.us-east-2.amazonaws.com/plans_pro.webp',
	regularization:
		'https://heru-static-assets.s3.us-east-2.amazonaws.com/plans_regularization.webp',
	annual:
		'https://heru-static-assets.s3.us-east-2.amazonaws.com/plans_annual.webp',
	annualRegularization:
		'https://heru-static-assets.s3.us-east-2.amazonaws.com/plans_annual_regularization.webp',
	asat: 'https://heru-static-assets.s3.us-east-2.amazonaws.com/plans_asat.webp',
};

const PLANS_PRODUCTS_INFO: Record<string, PlanCategory> = {
	monthly_declarations: {
		products: [
			{
				tagLabel: 'Recomendado',
				imageUrl: COVER_IMAGE.plus,
				productName: 'PLUS',
				subtitle: '2 o más regímenes fiscales',
				price: 50000,
				afterPrice: 'Mensual',
				description:
					'Ideal para emprendedores o pequeñas empresas que tienen ingresos a través de 2 o más regímenes fiscales.',
				buttonText: 'Contratar PLUS',
				buttonVariant: 'primary',
				slug: 'plus',
				defaultId: 92,
			},
			{
				imageUrl: COVER_IMAGE.pro,
				productName: 'PRO',
				subtitle: '1 régimen fiscal',
				price: 19900,
				beforePrice: 'Desde',
				afterPrice: 'Mensual',
				description:
					'Ideal para independientes o emprendedores que tienen ingresos a través de 1 solo régimen fiscal.',
				buttonText: 'Contratar PRO',
				buttonVariant: 'secondary',
				slug: 'pro',
				defaultId: 78,
			},
		],
		benefits: {
			tableColumns: [
				{ title: 'Beneficios' },
				{ title: 'PLUS', subtitle: '2 o más regímenes fiscales' },
				{ title: 'PRO', subtitle: '1 régimen fiscal' },
			],
			tableRows: [
				[
					'Declaración de IVA e ISR',
					{ text: 'Todos los regímenes fiscales', check: true },
					{ text: 'Un régimen fiscal', check: true },
				],
				[
					'Soporte',
					{ text: 'WhatsApp y correo', check: true },
					{ text: 'Correo', check: true },
				],
				[
					'Generar facturas gratis dentro de nuestra plataforma',
					{ text: '100 facturas al mes', check: true },
					{ text: '5 facturas al mes', check: true },
				],
				[
					'Identificación de tus facturas de ingresos y gastos',
					{ text: '', check: true },
					{ text: '', check: true },
				],
				[
					'Análisis de gastos con Inteligencia Artificial para encontrar más gastos deducibles',
					{ text: '', check: true },
					{ text: '', check: true },
				],
				[
					'Actualizaciones automáticas de tu información fiscal',
					{ text: '', check: true },
					{ text: '', check: true },
				],
				[
					'Control de declaraciones presentadas y pendientes por periodo',
					{ text: '', check: true },
					{ text: '', check: true },
				],
			],
		},
	},
	regularizations: {
		products: [
			{
				imageUrl: COVER_IMAGE.regularization,
				productName: 'Declaraciones atrasadas',
				price: 29900,
				afterPrice: 'Mensual',
				beforePrice: 'Desde',
				description:
					'Este servicio es para ti si tienes meses sin declarar y quieres ponerlos al día para evitar multas.',
				buttonText: 'Contratar declaraciones atrasadas',
				buttonVariant: 'primary',
				slug: 'regularizations',
				defaultId: 106,
			},
		],
		benefits: {
			tableColumns: [
				{ title: 'Beneficios' },
				{ title: 'Declaraciones atrasadas', subtitle: '' },
			],
			tableRows: [
				[
					'Identificación de tus facturas de ingresos y gastos por periodo atrasado',
					{ text: '', check: true },
				],
				[
					'Analizamos tus impuestos por periodo atrasado',
					{ text: '', check: true },
				],
				[
					'Presentamos tu declaración por periodo atrasado',
					{ text: '', check: true },
				],
				[
					'Declaración de IVA e ISR por periodo atrasado',
					{ text: 'Por regímen fiscal seleccionado', check: true },
				],
				['Soporte', { text: 'WhatsApp y correo', check: true }],
				[
					'Identificación de tus facturas de ingresos y gastos por periodo atrasado',
					{ text: '', check: true },
				],
				[
					'Análisis de gastos con Inteligencia Artificial para encontrar más gastos deducibles',
					{ text: '', check: true },
				],
			],
		},
	},
	annual_declarations: {
		products: [
			{
				tagLabel: 'Suscripción',
				imageUrl: COVER_IMAGE.annual,
				productName: (
					<>
						Declaración <br />
						2024
					</>
				),
				price: 80000,
				afterPrice: 'Anual',
				description:
					'Presentamos tu declaración del 2024, que se presenta en abril de 2025, se renovará automáticamente cada año.',
				buttonText: 'Contratar declaración 2024',
				buttonVariant: 'primary',
				slug: PRODUCTS_SLUG.ANNUAL_DECLARATION,
				defaultId: 130,
			},
			{
				imageUrl: COVER_IMAGE.annualRegularization,
				productName: 'Declaración anual atrasada',
				price: 80000,
				afterPrice: 'Cada año',
				description:
					'Elige los años pasados que deseas declarar y nos pondremos a trabajar para que evites multas.',
				buttonText: 'Contratar declaración anual atrasada',
				buttonVariant: 'secondary',
				slug: 'annual_regularization',
				defaultId: 45,
			},
		],
		benefits: {
			tableColumns: [
				{ title: 'Beneficios' },
				{
					title: (
						<>
							Declaración <br /> 2024
						</>
					),
					subtitle: '',
				},
				{
					title: (
						<>
							Declaración <br /> anual atrasada
						</>
					),
					subtitle: '',
				},
			],
			tableRows: [
				[
					'Identificación de tus facturas de ingresos y gastos',
					{ text: 'Año 2024', check: true },
					{ text: 'Año atrasado contratado', check: true },
				],
				[
					'Declaración de IVA e ISR',
					{ text: 'Año 2024', check: true },
					{ text: 'Año atrasado contratado', check: true },
				],
				[
					'Soporte',
					{ text: 'WhatsApp y correo', check: true },
					{ text: 'WhatsApp y correo', check: true },
				],
				[
					'Análisis de gastos con Inteligencia Artificial para encontrar más gastos deducibles',
					{ text: '', check: true },
					{ text: '', check: true },
				],
				[
					'Seguimiento a tu devolución automática de saldo a favor.',
					{ text: 'Año 2024', check: true },
					{ text: 'Año atrasado contratado', check: true },
				],
				[
					'Asesoria en caso de identificar meses pendientes por declarar',
					{ text: 'Año 2024', check: true },
					{ text: 'Año atrasado contratado', check: true },
				],
			],
		},
	},
	asat: {
		products: [
			{
				imageUrl: COVER_IMAGE.asat,
				productName: 'Trámites ante el SAT',
				price: 50000,
				description:
					'Te apoyamos a hacer cualquier alta o cambio que requieras de tu información fiscal.',
				buttonText: 'Comprar ASAT PLUS',
				buttonVariant: 'primary',
				slug: PRODUCTS_SLUG.ASSISTANCE_ASAT,
				defaultId: 31,
			},
		],
		benefits: {
			tableColumns: [
				{ title: 'Beneficios' },
				{ title: 'Trámites ante el SAT', subtitle: '' },
			],
			tableRows: [
				[
					'Análisis  y asistencia personalizada con contador',
					{ text: 'WhatsApp y correo', check: true },
				],
				['Soporte', { text: 'WhatsApp y correo', check: true }],
				['Agendamos tu cita con el SAT', { text: '', check: true }],
				[
					'Te damos de alta o baja en los regímenes que necesites',
					{ text: '', check: true },
				],
				['Obtener sellos digitales', { text: '', check: true }],
				['Cambio de domicilo', { text: '', check: true }],
				['Baja o cambio de obligaciones fiscales', { text: '', check: true }],
			],
		},
	},
};

export default PLANS_PRODUCTS_INFO;
