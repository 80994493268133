import { useDispatch, useSelector } from 'react-redux';
import { showAlert } from '../../../redux/slices/alert.slice';
import {
	fetchDeclarationsByYear,
	fetchMonthlyDeclarationOverview,
} from '../../../services/taxes.service';
import { useEffect, useState } from 'react';
import { RootState } from '../../../redux/store';
import { ALERT_TYPE } from '../../../components/molecules/Alert';
import ERROR from '../../../shared/constants/errors';
import Text from '../../../components/atoms/Text';
import { DATES, getLastYears } from '../../../shared/utils/dates';
import CALENDAR from '../../../shared/constants/calendar';
import Tag from '../../../components/molecules/Tag';
import TagIconCalendar from '../atoms/TagIconCalendar';
import Button from '../../../components/atoms/Button';
import { CircularProgress, Skeleton, useMediaQuery } from '@mui/material';
import { ArrowRight } from '@phosphor-icons/react';
import {
	DeclarationYear,
	DeclarationsByMonthList,
	PeriodMonth,
	TaxboardDeclarationStatus,
} from '../../../@types/Calendar.type';
import { getFormatDate } from '../../../shared/utils/dates.util';
import { PRODUCTS_SLUG } from '../../../shared/constants/SlugsProducts';
import useAddToCart from '../../../hooks/useAddToCart';
import ModalAnnualPendingMonths from './ModalAnnualPendingMonths';
import {
	setShowModal,
	updatedAnnualYearSelected,
} from '../../../redux/slices/taxCalendar.slice';
import { CONFIG_WHATSAPP_SUPPORT } from '../../../shared/constants/whatsappNumbers';
import useCleverTapEvent from '../../../hooks/useClevertapEvent';
import {
	ANALYTICS_EVENTS,
	EVENT_TYPES,
} from '../../../shared/constants/events';
import { UserRegime } from '../../../@types/TaxProfile';

const AnnualTemporalyDetail = () => {
	const logClevertapEvent = useCleverTapEvent();

	const { handleAddProductBySlug, isLoading: isLoadingAddToCart } =
		useAddToCart();

	const dispatch = useDispatch();

	const userProfile = useSelector((state: RootState) => state.profile.user);

	const { isRecentlyLinked } = useSelector(
		(state: RootState) => state.satLinkStatus,
	);

	const { activeAnnualSubscription } = useSelector(
		(state: RootState) => state.profile,
	);
	const { showModal, annualYearSelected } = useSelector(
		(state: RootState) => state.taxCalendar,
	);
	const { acquisitions } = useSelector((state: RootState) => state.productList);
	const { userRegimes } = useSelector((state: RootState) => state.taxProfile);

	const [isLoading, setIsLoading] = useState({
		detail: false,
		declarations: false,
		monthly: false,
		monthlyPendingByYear: false,
	});
	const [declarations, setDeclarations] = useState<DeclarationYear[]>([]);
	const [pendingDeclarationsByYear, setPendingDeclarationsByYear] = useState<
		Record<string, PeriodMonth[]>
	>({});

	const getDeclarationsByYear = async (year: number) => {
		try {
			const response = await fetchDeclarationsByYear(year, userProfile?.id!);
			return response;
		} catch (error) {
			const alert = {
				type: ALERT_TYPE.ERROR,
				title: ERROR.failed_to_fetch_data,
				description: ERROR.try_again_later,
				duration: 2000,
			};
			dispatch(showAlert(alert));
			return null;
		} finally {
		}
	};

	const getAllDeclarations = async () => {
		const years = getLastYears(
			CALENDAR.supportedNumberOfYears.yearly,
			DATES.currentYear - 1,
		);

		setDeclarations([]);
		setIsLoading((prev) => ({ ...prev, declarations: true }));

		try {
			const declarationPromises = years.map(async (year) => {
				const declaration = await getDeclarationsByYear(year);
				return { ...declaration, year };
			});

			const declarations = await Promise.all(declarationPromises);
			setDeclarations(declarations);
		} catch (error) {
			const alert = {
				type: ALERT_TYPE.ERROR,
				title: ERROR.failed_to_fetch_data,
				description: ERROR.try_again_later,
				duration: 2000,
			};
			dispatch(showAlert(alert));
		} finally {
			setIsLoading((prev) => ({ ...prev, declarations: false }));
		}
	};

	const getMonthlyPendingDeclarations = async (year: number) => {
		try {
			setIsLoading((prev) => ({ ...prev, monthly: true }));
			const response = await fetchMonthlyDeclarationOverview(
				userProfile?.id!,
				year,
			);

			const pendingDeclarations = response?.declarations?.filter(
				(declaration: DeclarationsByMonthList) =>
					declaration.status === TaxboardDeclarationStatus.PENDING ||
					declaration.status === TaxboardDeclarationStatus.PENDING_PAY,
			);

			return pendingDeclarations;
		} catch (error) {
			const alert = {
				type: ALERT_TYPE.ERROR,
				title: ERROR.failed_to_fetch_data,
				description: ERROR.try_again_later,
				duration: 2000,
			};
			dispatch(showAlert(alert));
			return null;
		} finally {
			setIsLoading((prev) => ({ ...prev, monthly: false }));
		}
	};

	const isMobile = useMediaQuery('(max-width: 640px)');

	const checkIsAcquired = (declaration: DeclarationYear) => {
		if (
			declaration.year === DATES.currentYear - 1 &&
			activeAnnualSubscription?.additional_information?.year ===
				declaration.year
		)
			return true;
		if (
			acquisitions?.find(
				(acq) =>
					acq.product?.slug === PRODUCTS_SLUG.ANNUAL_DECLARATION_HARD &&
					Number(acq.additional_information?.year) === declaration.year,
			)
		)
			return true;
	};

	const yearAcquiredDontHavePendingDeclarations = (
		declaration: DeclarationYear,
	) => {
		return (
			checkIsAcquired(declaration) &&
			pendingDeclarationsByYear?.[declaration?.year]?.filter(
				(item) => item?.status === TaxboardDeclarationStatus.PENDING,
			)?.length === 0
		);
	};

	const isAcquiredAndhavePendingPayDeclarations = (
		declaration: DeclarationYear,
	) =>
		checkIsAcquired(declaration) &&
		pendingDeclarationsByYear[declaration?.year]?.filter(
			(declaration) =>
				declaration.status === TaxboardDeclarationStatus.PENDING_PAY,
		).length > 0;

	const textPendingDeclarations = (declaration: DeclarationYear) => {
		if (
			!checkIsAcquired(declaration) ||
			declaration.status === TaxboardDeclarationStatus.PRESENTED
		) {
			return '';
		}

		if (yearAcquiredDontHavePendingDeclarations(declaration)) {
			if (isAcquiredAndhavePendingPayDeclarations(declaration)) {
				return (
					<>
						Tienes declaraciones pendientes de pago al SAT.{' '}
						{!isMobile && <br />}
						Contacta a un contador para poder continuar con tu declaración anual
						de {declaration?.year}
					</>
				);
			}
			return '';
		}

		return isMobile ? (
			'Hay declaraciones mensuales pendientes.'
		) : (
			<>
				Tienes declaraciones mensuales pendientes. {!isMobile && <br />}
				Ponlas al día para presentar la declaración anual
			</>
		);
	};

	const handleAction = (declaration: DeclarationYear) => {
		dispatch(updatedAnnualYearSelected(declaration?.year));

		if (
			declaration?.status === TaxboardDeclarationStatus.PENDING ||
			declaration?.status === TaxboardDeclarationStatus.IN_PROCESS
		) {
			if (checkIsAcquired(declaration)) {
				dispatch(
					setShowModal({
						modalName: 'annualPendingMonths',
						show: !showModal.annualPendingMonths,
					}),
				);
				return;
			}

			logClevertapEvent(ANALYTICS_EVENTS.TAX_DASHBOARD, {
				event_type: EVENT_TYPES.TAXBOARD_ACQUIRE_PERIOD,
				source: 'declaraciones_anuales',
				regime:
					userRegimes?.map((regime: UserRegime) => regime?.name).join(' - ') ||
					'[]',
			});

			const slugAnnual =
				Number(declaration?.year) == DATES.currentYear - 1
					? PRODUCTS_SLUG.ANNUAL_DECLARATION
					: PRODUCTS_SLUG.ANNUAL_DECLARATION_HARD;

			handleAddProductBySlug(slugAnnual, {
				year: declaration?.year,
				regime: [],
			});
		}

		if (declaration?.declarationId) {
			window.open(declaration?.pdfUrl, '_blank');
		}
	};

	const getCTAButton = (declaration: DeclarationYear) => {
		if (isRecentlyLinked) {
			return null;
		}

		if (
			declaration?.status === TaxboardDeclarationStatus.IN_PROCESS &&
			yearAcquiredDontHavePendingDeclarations(declaration)
		) {
			if (isAcquiredAndhavePendingPayDeclarations(declaration)) {
				return (
					<Button
						onClick={() => window.open(CONFIG_WHATSAPP_SUPPORT, '_blank')}
						label={'Contactar a un contador'}
						variant='text'
						labelColor='primary'
						labelSize={isMobile ? 'mini-1' : 'caption'}
						sx='items-center !gap-2 !text-right'
						icon={
							<ArrowRight
								size={16}
								color='var(--h-primary-50)'
							/>
						}
					/>
				);
			}

			return null;
		}

		if (
			declaration?.status === TaxboardDeclarationStatus.PENDING ||
			declaration?.status === TaxboardDeclarationStatus.IN_PROCESS
		) {
			const isLoadingCTA =
				isLoadingAddToCart.products && annualYearSelected === declaration?.year;

			if (yearAcquiredDontHavePendingDeclarations(declaration)) {
				return null;
			}

			return (
				<Button
					onClick={() => handleAction(declaration)}
					label={
						checkIsAcquired(declaration)
							? isMobile
								? 'Presentar pendientes'
								: 'Presentar declaraciones mensuales pendientes'
							: declaration?.year === DATES.currentYear - 1
								? 'Presentar anual 2023'
								: 'Poner al día'
					}
					variant='text'
					labelColor='primary'
					labelSize={isMobile ? 'mini-1' : 'caption'}
					sx='items-center !gap-2 !text-right'
					icon={
						isLoadingCTA ? (
							<CircularProgress size={14} />
						) : (
							<ArrowRight
								size={16}
								color='var(--h-primary-50)'
							/>
						)
					}
				/>
			);
		}

		return (
			<Button
				onClick={() => handleAction(declaration)}
				label={'Descargar acuse'}
				variant='text'
				labelColor='primary'
				labelSize={isMobile ? 'mini-1' : 'caption'}
				sx='items-center !gap-2 !text-right'
				icon={
					false ? (
						<CircularProgress size={14} />
					) : (
						<ArrowRight
							size={16}
							color='var(--h-primary-50)'
						/>
					)
				}
			/>
		);
	};

	const getPendingMonthlyDeclarationsOfPendingYears = async () => {
		try {
			setIsLoading((prev) => ({ ...prev, monthlyPendingByYear: true }));
			const pendingYearsDeclarations = declarations?.filter(
				(declaration) =>
					(declaration.status === TaxboardDeclarationStatus.PENDING ||
						declaration.status === TaxboardDeclarationStatus.IN_PROCESS) &&
					checkIsAcquired(declaration),
			);

			const promises = pendingYearsDeclarations?.map(async (declaration) => {
				const pendingDeclarations = await getMonthlyPendingDeclarations(
					declaration?.year,
				);

				return { year: declaration?.year, declarations: pendingDeclarations };
			});

			if (promises) {
				const resolvedPromises = await Promise.all(promises);

				resolvedPromises.forEach((resolvedPromise) => {
					//@ts-ignore
					setPendingDeclarationsByYear((prev: PeriodMonth) => ({
						...prev,
						[resolvedPromise.year]: resolvedPromise.declarations,
					}));
				});
			}
		} catch (error) {
		} finally {
			setIsLoading((prev) => ({ ...prev, monthlyPendingByYear: false }));
		}
	};

	const getTagStatus = (declaration: DeclarationYear) => {
		if (isRecentlyLinked) {
			return (
				<Tag
					label={'Descargando información'}
					type='filled'
					caseVariant={'loading'}
					iconRight={
						<TagIconCalendar status={TaxboardDeclarationStatus.DOWNLOADING} />
					}
				/>
			);
		}

		if (
			yearAcquiredDontHavePendingDeclarations(declaration) &&
			declaration?.status === TaxboardDeclarationStatus.PENDING
		) {
			return (
				<Tag
					label={'En proceso'}
					type='filled'
					caseVariant={'inactive'}
					iconRight={
						<TagIconCalendar status={TaxboardDeclarationStatus.IN_PROCESS} />
					}
				/>
			);
		}

		return (
			<Tag
				label={CALENDAR.taxboardDeclarationStatusLabel[declaration?.status]}
				type='filled'
				caseVariant={CALENDAR.labelColor[declaration?.status] ?? 'filter'}
				iconRight={<TagIconCalendar status={declaration?.status} />}
			/>
		);
	};

	const getDeclarationDescription = (declaration: DeclarationYear) => {
		if (isRecentlyLinked) {
			return (
				<Text
					size='mini-1'
					weight='light'
				>
					Con las credenciales que nos has dado estamos descargando tu
					información. Estamos haciéndolo lo más rápido para ti.
				</Text>
			);
		}

		const getLabelAndContent = () => {
			if (declaration?.status === TaxboardDeclarationStatus.IN_PROCESS) {
				return;
			}

			if (declaration?.status === TaxboardDeclarationStatus.PENDING) {
				if (yearAcquiredDontHavePendingDeclarations(declaration)) {
					return {
						label: 'Fecha estimada de presentación',
						content: `Abril ${declaration?.year + 1}`,
					};
				}

				if (
					declaration?.year === DATES.currentYear - 1 &&
					DATES.currentMonth === 4 &&
					DATES.currentDay < 17
				) {
					return {
						label: 'Fecha límite de presentación',
						content: `Abril ${declaration?.year + 1}`,
					};
				}

				return {
					label: 'Días de atraso',
					content: `${Math.round(declaration?.days_late || 0)} días`,
				};
			}

			if (declaration?.presentationDate) {
				return {
					label: 'Fecha de presentación',
					content: getFormatDate(
						declaration?.presentationDate,
						'DD MMM YYYY',
						false,
						true,
					),
				};
			}

			return {
				label: 'Fecha de presentación',
				content: `Abril ${declaration?.year + 1}`,
			};
		};

		return (
			<div>
				<Text
					size='mini-1'
					weight='regular'
					sx='mt-2 whitespace-nowrap'
				>
					{getLabelAndContent()?.label}
				</Text>
				<Text
					size='mini-1'
					weight='light'
				>
					{getLabelAndContent()?.content}
				</Text>
			</div>
		);
	};

	useEffect(() => {
		if (declarations.length > 0) {
			getPendingMonthlyDeclarationsOfPendingYears();
		}
	}, [declarations]);

	useEffect(() => {
		getAllDeclarations();
	}, []);

	if (
		isLoading.declarations ||
		isLoading.monthly ||
		isLoading.monthlyPendingByYear ||
		declarations.length === 0
	) {
		return (
			<div className='flex flex-col gap-2'>
				{[...Array(4)].map((_, index) => (
					<div
						className='bg-h-neutral-95 border-h-neutral-90 border p-3 rounded-lg h-32 flex justify-between'
						key={`declaration-index-${index}`}
					>
						<div>
							<Skeleton
								width={200}
								height={32}
							/>
							<Skeleton
								width={100}
								height={32}
							/>
						</div>
						<Skeleton
							width={80}
							height={32}
						/>
					</div>
				))}
			</div>
		);
	}

	return (
		<div className='flex flex-col gap-2 pb'>
			<ModalAnnualPendingMonths />
			{declarations
				?.sort((a, b) => b?.year - a?.year)
				?.map((declaration, index) => (
					<div
						className='bg-h-neutral-95 border-h-neutral-90 border p-3 rounded-lg min-h-[150px] flex flex-col justify-between'
						key={`declaration-index-${index} `}
					>
						<div className='flex justify-between'>
							<Text
								size={isMobile ? 'body-3' : 'body-1'}
								weight='medium'
							>
								{declaration?.year}
							</Text>
							{getTagStatus(declaration)}
						</div>
						<div>
							<Text
								size='mini-1'
								weight='light'
								sx='!text-end mt-1 justify-end'
							>
								{textPendingDeclarations(declaration)}
							</Text>
						</div>
						<div className='flex justify-between items-end'>
							{getDeclarationDescription(declaration)}
							{getCTAButton(declaration)}
						</div>
					</div>
				))}
		</div>
	);
};

export default AnnualTemporalyDetail;
