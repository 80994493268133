import { useState } from 'react';

import { CaretDown, CaretRight, CaretUp } from '@phosphor-icons/react';

import Text from '../../../../components/atoms/Text';
import Divider from '../../../../components/atoms/Divider';
import {
	getRecurrency,
	isSessionStorageEnabled,
} from '../../../../shared/utils/general.util';
import { SubscriptionType } from '../../../../@types/Subscription';
import { ServiceStatusElement } from '../../../../@types/ServiceStatus.type';
import Tag from '../../../../components/molecules/Tag';
import ROUTES from '../../../../shared/constants/routes';
import { useNavigate } from 'react-router-dom';
import Button from '../../../../components/atoms/Button';
import EmptyServiceIcon from '../../../../assets/img/svg/empty_service.svg';
import LOCAL_STORAGE_KEY from '../../../../shared/constants/localStorageKey';
import { PLANS_UTM_VALUES } from '../../../../shared/constants/utms';
import { useDispatch, useSelector } from 'react-redux';
import { setOverwriteUtm, updateUTMs } from '../../../../redux/slices/cart.slice';
import { RootState } from '../../../../redux/store';

interface ClosedServicesProps {
	subscriptions: SubscriptionType[];
	serviceStatus: ServiceStatusElement[];
}

const headersTable = [
	{
		id: 1,
		name: 'Servicio',
	},
	{
		id: 2,
		name: 'Declaraciones presentadas',
	},
];

const ClosedServices: React.FC<ClosedServicesProps> = ({
	subscriptions,
	serviceStatus,
}) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [open, setOpen] = useState<number | null>(null);

	const handleOpen = (itemId: number) => {
		setOpen((prev) => (prev === itemId ? null : itemId));
	};

	const goToPlans = () => {
		const { utm_campaign, utm_medium, utm_source } =
			PLANS_UTM_VALUES.SERVICES_STATUS;
		if (isSessionStorageEnabled()) {
			const overwriteUtmKey = LOCAL_STORAGE_KEY.OVERWRITE_UTM;
			const campaignKey = LOCAL_STORAGE_KEY.CAMPAIGN;
			const mediumKey = LOCAL_STORAGE_KEY.MEDIUM;
			const sourceKey = LOCAL_STORAGE_KEY.SOURCE;

			let overwriteUtm = sessionStorage.getItem(overwriteUtmKey);

			if (overwriteUtm === null) {
				sessionStorage.setItem(overwriteUtmKey, 'true');
				overwriteUtm = 'true';
			}

			if (overwriteUtm === 'true') {
				sessionStorage.setItem(campaignKey, utm_campaign);
				sessionStorage.setItem(mediumKey, utm_medium);
				sessionStorage.setItem(sourceKey, utm_source);
			} else {
				if (!sessionStorage.getItem(campaignKey)) {
					sessionStorage.setItem(campaignKey, utm_campaign);
				}
				if (!sessionStorage.getItem(mediumKey)) {
					sessionStorage.setItem(mediumKey, utm_medium);
				}
				if (!sessionStorage.getItem(sourceKey)) {
					sessionStorage.setItem(sourceKey, utm_source);
				}
			}
		} else {

			const currentOverwriteUtm = useSelector(
				(state: RootState) => state.cart.overwriteUtm,
			);

			if (currentOverwriteUtm === undefined || currentOverwriteUtm === null) {
				dispatch(setOverwriteUtm(true));
			}

			if (currentOverwriteUtm === true) {
				dispatch(updateUTMs(PLANS_UTM_VALUES.SERVICES_STATUS));
			} else {
				const currentUTMs = useSelector(
					(state: RootState) => state.cart.utmInfo,
				);
				const updatedUTMs = {
					...currentUTMs,
					utm_campaign: currentUTMs?.utm_campaign || utm_campaign,
					utm_medium: currentUTMs?.utm_medium || utm_medium,
					utm_source: currentUTMs?.utm_source || utm_source,
				};
				dispatch(updateUTMs(updatedUTMs));
			}

		}
		navigate(ROUTES.PLANS_AND_PRICES);
	};

	const closedServices = serviceStatus.filter(
		(it) =>
			it?.status === 'closed'
	);

	return (
		<>
			{subscriptions.length === 0 && closedServices.length === 0 ? (
				<div className='bg-white rounded-lg shadow-md p-6'>
					<div className='bg-h-neutral-94 rounded-lg flex flex-col items-center py-10 lg:py-20 px-4 lg:px-0'>
						<img
							src={EmptyServiceIcon}
							alt='empty-cart'
							className='mb-5'
						/>
						<Text
							size='subtitle'
							weight='bold'
							color='medium'
							sx='mb-2 text-center'
						>
							No tienes ninguna compra cerrada
						</Text>
						<Text
							size='caption'
							color='medium'
							weight='light'
							sx='text-center'
						>
							Mantente al día con tus obligaciones, adquiriendo el plan perfecto
							para tus declaraciones.
						</Text>

						<div className='mt-10 w-full lg:w-60 px-2'>
							<Button
								label='Ver planes y precios'
								onClick={goToPlans}
							/>
						</div>
					</div>
				</div>
			) : (
				<>
					{subscriptions.length === 0 ? (
						<div className='flex flex-col bg-white p-6 w-full rounded-lg mt-2 shadow-md'>
							<Text
								size='body-2'
								color='medium'
								weight='bold'
								sx='mb-6'
							>
								Suscripciones
							</Text>
							<Divider />

							<div className='bg-h-neutral-94 rounded-lg flex flex-col items-center py-10 mt-2'>
								<Text
									size='subtitle'
									weight='bold'
									color='medium'
									sx='mb-2 text-center'
								>
									No tienes suscripciones cerradas
								</Text>
								<Text
									size='caption'
									color='medium'
									weight='light'
									sx='text-center'
								>
									Mantente al día con tus obligaciones, adquiriendo una
									suscripción para tus declaraciones.
								</Text>

								<div className='mt-10 w-full lg:w-60 px-2'>
									<Button
										label='Ver planes y precios'
										onClick={goToPlans}
									/>
								</div>
							</div>
						</div>
					) : (
						<>
							<div className='hidden lg:flex flex-col bg-white p-6 w-full rounded-lg shadow-md'>
								<Text
									size='body-2'
									color='medium'
									weight='bold'
									sx='mb-6'
								>
									Suscripciones
								</Text>
								<Divider />

								<div className='flex items-center border-b border-h-blue-40 justify-between px-2 pb-2'>
									{headersTable.map((item) => (
										<Text
											size='m'
											color='medium'
											weight='bold'
											key={item.id}
										>
											{item.name}
										</Text>
									))}
								</div>

								<div className='mt-2'>
									{subscriptions.map((activeSub, index) => {
										const isOpen = open === activeSub?.id;
										return (
											<>
												<div
													className={`flex items-center py-3 px-2 ${
														index % 2 === 0 ? 'bg-white' : 'bg-h-gray-10'
													}`}
												>
													<div className='flex flex-col w-1/2'>
														<Text
															size='caption'
															weight='bold'
															color='medium'
														>
															{activeSub?.product_version?.product?.name}
														</Text>
														<Text
															size='caption'
															weight='light'
															color='medium'
														>
															Suscripción{' '}
															{getRecurrency(
																activeSub?.product_version?.periodicity,
															)}
														</Text>
													</div>

													<div className='flex items-center justify-end pr-4 w-1/2'>
														<div className='flex flex-col'>
															<Text
																size='caption'
																weight='regular'
																color='light'
																sx='mr-4'
															>
																{
																	serviceStatus.filter(
																		(it) =>
																			it?.product?.subscription_id ===
																				activeSub?.id &&
																			it?.status !== 'closed',
																	).length
																}{' '}
																{''} Activas
															</Text>
															<Text
																size='caption'
																weight='medium'
																color='medium'
																sx='mr-4'
															>
																{
																	serviceStatus.filter(
																		(it) =>
																			it.product?.subscription_id ===
																				activeSub?.id &&
																			it?.status === 'closed',
																	).length
																}{' '}
																{''} finalizadas
															</Text>
														</div>

														{serviceStatus.filter(
															(it) =>
																it.product?.subscription_id === activeSub?.id &&
																it?.status === 'closed',
														).length > 0 ? (
															<>
																<button
																	className='mr-2'
																	onClick={() => handleOpen(activeSub?.id)}
																>
																	{isOpen ? (
																		<CaretUp
																			size={18}
																			weight='light'
																		/>
																	) : (
																		<CaretDown
																			size={18}
																			weight='light'
																		/>
																	)}
																</button>
															</>
														) : (
															<div className='ml-8' />
														)}
													</div>
												</div>

												{isOpen && (
													<div className='px-2'>
														<div className='border border-gray-300 rounded-lg flex flex-col mb-4 mt-2'>
															<div className='flex items-center justify-between px-2 py-2 bg-h-neutral-90'>
																{headersTable.map((item) => (
																	<Text
																		size='caption'
																		color='medium'
																		weight='bold'
																	>
																		{item?.name}
																	</Text>
																))}
															</div>

															{serviceStatus
																.filter(
																	(it) =>
																		it.product.subscription_id ===
																			activeSub.id && it.status === 'closed',
																)
																.map((item) => (
																	<div className='flex items-center bg-h-neutral-98 px-2 border-b border-gray-200 py-2'>
																		<div className='flex flex-col w-1/2 py-1'>
																			<Text
																				size='caption'
																				weight='bold'
																				color='medium'
																			>
																				{item.product.name}
																			</Text>
																			{item?.product?.regime && (
																				<Text
																					size='caption'
																					weight='light'
																					color='medium'
																					sx='mt-2'
																				>
																					{item.product.regime.map(
																						(regime, index) =>
																							index === 0
																								? regime
																								: `, ${regime}`,
																					)}
																				</Text>
																			)}
																		</div>

																		<div className='flex w-1/2 items-center justify-end mr-2'>
																			<div
																				onClick={() =>
																					navigate(
																						`/service-status/${item?.id}`,
																					)
																				}
																				className='flex flex-col'
																			>
																				<Tag
																					label={'Finalizado'}
																					type='filled'
																					caseVariant='inactive'
																				/>
																			</div>

																			<button
																				onClick={() =>
																					navigate(
																						`/service-status/${item?.id}`,
																					)
																				}
																			>
																				<CaretRight
																					size={16}
																					weight='light'
																					className='ml-4'
																				/>
																			</button>
																		</div>
																	</div>
																))}
														</div>
													</div>
												)}
											</>
										);
									})}
								</div>
							</div>

							<div className='flex lg:hidden flex-col bg-white p-4 w-full rounded-lg shadow-md'>
								<Text
									size='body-2'
									color='medium'
									weight='medium'
									sx='mb-4'
								>
									Suscripciones
								</Text>
								<Divider />

								<div>
									{subscriptions.map((activeSub, index) => {
										const isOpen = open === activeSub.id;
										return (
											<>
												<div
													className={`flex items-center py-3 px-2 ${
														index % 2 === 0 ? 'bg-white' : 'bg-h-gray-10'
													}`}
												>
													<div className='flex flex-col w-3/4'>
														<Text
															size='caption'
															weight='bold'
															color='medium'
															sx='mb-1'
														>
															{activeSub.product_version.product?.name}
														</Text>
														<Text
															size='caption'
															weight='light'
															color='medium'
															sx='mb-1'
														>
															Suscripción{' '}
															{getRecurrency(
																activeSub.product_version.periodicity,
															)}
														</Text>
														<div className='flex items-center'>
															<Text
																size='caption'
																weight='regular'
																color='light'
																sx='mr-2'
															>
																{
																	serviceStatus.filter(
																		(it) =>
																			it.product.subscription_id ===
																				activeSub.id && it.status !== 'closed',
																	).length
																}{' '}
																{''} Activas
															</Text>
															<Text
																size='caption'
																weight='medium'
																color='medium'
															>
																/{' '}
																{
																	serviceStatus.filter(
																		(it) =>
																			it.product.subscription_id ===
																				activeSub.id && it.status === 'closed',
																	).length
																}
																{''} finalizadas
															</Text>
														</div>
													</div>

													<div className='flex items-center justify-end pr-4 w-1/4'>
														{serviceStatus.filter(
															(it) =>
																it.product.subscription_id === activeSub.id &&
																it.status === 'closed',
														).length > 0 && (
															<button
																className='mr-2'
																onClick={() => handleOpen(activeSub.id)}
															>
																{isOpen ? (
																	<CaretUp
																		size={18}
																		weight='light'
																		color={'var(--h-primary)'}
																	/>
																) : (
																	<CaretDown
																		size={18}
																		weight='light'
																		color={'var(--h-primary)'}
																	/>
																)}
															</button>
														)}
													</div>
												</div>

												{isOpen && (
													<div className='border border-gray-300 rounded-lg flex flex-col mb-4'>
														{serviceStatus
															.filter(
																(it) =>
																	it.product.subscription_id === activeSub.id &&
																	it.status === 'closed',
															)
															.map((item, idx) => (
																<div
																	className='flex flex-col px-4 bg-h-neutral-98 justify-center'
																	key={idx}
																>
																	<div className='flex items-center justify-between py-4'>
																		<div className='flex items-center'>
																			<Text
																				size='caption'
																				weight='regular'
																				color='medium'
																			>
																				{item.product.name}
																			</Text>
																		</div>
																		<button
																			onClick={() =>
																				navigate(`/service-status/${item?.id}`)
																			}
																			className='flex items-center'
																		>
																			<Text
																				size='mini-2'
																				weight='regular'
																				color='primary'
																				sx='mr-2'
																			>
																				Ver detalle
																			</Text>
																			<CaretRight
																				size={16}
																				weight='light'
																				color={'var(--h-primary)'}
																			/>
																		</button>
																	</div>
																	{idx <
																		serviceStatus.filter(
																			(it) =>
																				it.product.subscription_id ===
																					activeSub.id &&
																				it.status === 'closed',
																		).length -
																			1 && (
																		<div className='h-[1px] w-full bg-h-gray-20' />
																	)}
																</div>
															))}
													</div>
												)}
											</>
										);
									})}
								</div>
							</div>
						</>
					)}

					{closedServices.filter(
						(item) =>
							item.service_type_has_product.product_type === 'acquisition',
					).length === 0 ? (
						<div className='flex flex-col bg-white p-6 w-full rounded-lg mt-6 shadow-md'>
							<Text
								size='body-2'
								color='medium'
								weight='bold'
								sx='mb-6'
							>
								Compras únicas
							</Text>
							<Divider />

							<div className='bg-h-neutral-94 rounded-lg flex flex-col items-center py-10 mt-2 px-4 lg:px-0'>
								<Text
									size='subtitle'
									weight='bold'
									color='medium'
									sx='mb-2 text-center'
								>
									No tienes compras cerradas
								</Text>
								<Text
									size='caption'
									color='medium'
									weight='light'
									sx='text-center mt-2'
								>
									Mantente al día con tus obligaciones. Adquiérelas en la
									sección de planes y precios.
								</Text>

								<div className='mt-8 w-full lg:w-60 px-2'>
									<Button
										label='Ver planes y precios'
										onClick={goToPlans}
									/>
								</div>
							</div>
						</div>
					) : (
						<>
							<div className='hidden lg:flex flex-col bg-white p-6 w-full rounded-lg mt-6 shadow-md'>
								<Text
									size='body-2'
									color='medium'
									weight='bold'
									sx='mb-6'
								>
									Compras únicas
								</Text>
								<Divider />

								<div className='flex items-center border-b border-h-blue-40 justify-between px-2 pb-2'>
									{headersTable.map((item) => (
										<Text
											size='m'
											color='medium'
											weight='bold'
										>
											{item.name}
										</Text>
									))}
								</div>

								<div className='mt-2'>
									{serviceStatus
										?.filter(
											(item) =>
												item?.service_type_has_product?.product_type ===
													'acquisition' && item?.status === 'closed',
										)
										.map((item, index) => {
											return (
												<div
													className={`flex items-center py-3 px-2 ${
														index % 2 === 0 ? 'bg-white' : 'bg-h-gray-10'
													}`}
												>
													<div className='flex flex-col w-1/2'>
														<Text
															size='caption'
															weight='bold'
															color='medium'
														>
															{item?.product?.name}
														</Text>
														{item?.product?.regime &&
															item?.product?.regime?.map((regime, index) => (
																<Text
																	key={index}
																	size='caption'
																	weight='light'
																	color='medium'
																	sx='mt-2'
																>
																	{regime}
																</Text>
															))}
													</div>

													{/* <div className='flex w-1/3 justify-center'>
												<Text
													size='caption'
													weight='regular'
													color='medium'
												>
													{getFormatDate(item.created_at)}
												</Text>
											</div> */}

													<div className='flex items-center w-1/2 justify-end mr-2'>
														<div
															onClick={() =>
																navigate(`/service-status/${item?.id}`)
															}
															className='flex flex-col'
														>
															<Tag
																label={'Finalizado'}
																type='filled'
																caseVariant='inactive'
															/>
														</div>

														<button
															onClick={() =>
																navigate(`/service-status/${item?.id}`)
															}
														>
															<CaretRight
																size={16}
																weight='light'
																className='ml-4'
															/>
														</button>
													</div>
												</div>
											);
										})}
								</div>
							</div>

							<div className='flex lg:hidden flex-col bg-white p-4 w-full rounded-lg mt-6 shadow-md'>
								<Text
									size='body-2'
									color='medium'
									weight='bold'
									sx='mb-4'
								>
									Compras únicas
								</Text>
								<Divider />

								<div>
									{serviceStatus
										?.filter(
											(item) =>
												item.service_type_has_product.product_type ===
													'acquisition' && item.status === 'closed',
										)
										.map((service, index) => {
											const isOpen = open === service.id;
											return (
												<>
													<div
														className={`flex items-center py-3 px-2 ${
															index % 2 === 0 ? 'bg-white' : 'bg-h-gray-10'
														}`}
													>
														<div className='flex flex-col w-3/4'>
															<Text
																size='caption'
																weight='bold'
																color='medium'
															>
																{service.product.name}
															</Text>
															{service?.product?.regime &&
																service?.product?.regime?.map(
																	(regime, index) => (
																		<Text
																			key={index}
																			size='caption'
																			weight='light'
																			color='medium'
																			sx='mt-2'
																		>
																			{regime}
																		</Text>
																	),
																)}
														</div>

														<div className='flex items-center justify-end pr-1 w-1/4'>
															<button
																onClick={() => {
																	handleOpen(service.id);
																}}
															>
																{isOpen ? (
																	<CaretUp
																		size={18}
																		weight='light'
																		color={'var(--h-primary)'}
																	/>
																) : (
																	<CaretDown
																		size={18}
																		weight='light'
																		color={'var(--h-primary)'}
																	/>
																)}
															</button>
														</div>
													</div>

													{isOpen && (
														<div
															className={` flex flex-col mb-1 px-2 ${
																index % 2 === 0 ? 'bg-white' : 'bg-h-gray-10'
															}`}
														>
															{/* <div className='flex items-center justify-between'>
														<Text
															size='caption'
															weight='light'
															color='medium'
														>
															Fecha de entrega
														</Text>
														<Text
															size='caption'
															weight='medium'
															color='medium'
														>
															{getFormatDate(service.created_at)}
														</Text>
													</div> */}
															<div
																onClick={() =>
																	navigate(`/service-status/${service?.id}`)
																}
																className='mt-2.5'
															>
																<Tag
																	label={'Finalizado'}
																	type='filled'
																	caseVariant='inactive'
																/>
															</div>

															<button
																onClick={() =>
																	navigate(`/service-status/${service?.id}`)
																}
																className='flex items-center justify-end mt-2.5 mb-2'
															>
																<Text
																	key={index}
																	size='mini-2'
																	weight='regular'
																	color='primary'
																	sx='mr-2'
																>
																	Ver detalle
																</Text>
																<CaretRight
																	size={16}
																	weight='light'
																	color={'var(--h-primary)'}
																/>
															</button>
														</div>
													)}
												</>
											);
										})}
								</div>
							</div>
						</>
					)}
				</>
			)}
		</>
	);
};

export default ClosedServices;
