import HERU_API from '../shared/constants/heruApi';
import { get, patch, post } from '../shared/utils/FetchSSL.util';

const getUserProfile = async (tokenSession?: string) => {
	try {
		const response = await get(HERU_API.USER_PROFILE, {
			token: true,
			isNewGateway: true,
			tokenSession,
		});
		return response;
	} catch (error) {
		throw error;
	}
};

const patchUserProfile = async (body: object, tokenSession?: string) => {
	try {
		const response = await patch(HERU_API.UPDATE_USER_PROFILE, body, {
			token: true,
			isNewGateway: true,
			tokenSession,
		});
		return response;
	} catch (error) {
		throw error;
	}
};

const fetchTermsAndConditions = async () => {
	try {
		const response = await get(HERU_API.COMPLIANCE_DOCUMENT_TC, {
			isNewGateway: true,
		});
		return response;
	} catch (error) {
		throw error;
	}
};

const acceptCompliance = async (doc_type: 'T&Cs', tokenSession?: string) => {
	try {
		const response = await post(
			HERU_API.COMPLIANCE_LOG,
			{ doc_type },
			{
				isNewGateway: true,
				tokenSession,
				token: true,
			},
		);
		return response;
	} catch (error) {
		throw error;
	}
};

const fetchProfileFiscalRetentions = async (userId: number) => {
	try {
		const response = await get(
			`${HERU_API.PROFILE_FISCAL_RETENTIONS}/${userId}`,
			{
				token: true,
				isNewGateway: true,
			},
		);
		return response;
	} catch (error) {
		throw error;
	}
};

export {
	getUserProfile,
	patchUserProfile,
	fetchTermsAndConditions,
	acceptCompliance,
	fetchProfileFiscalRetentions,
};
